<template>
<div :class="`streamify-player ${!actualPlayed ? '' : 'streamify-player--playing'}`" :style="`${poster}`" v-if="!item.loading && !item.error">
  <template v-if="!playable">
    <div class="streamify-player__countdown">
      <Countdown :background="true" :to="item.data.scheduled_start_time"/>
    </div>
  </template>
  <template v-else>
    <video ref="videoplayer" disableRemotePlayback playsinline :class="`video-js streamify-player__player`"></video>
    <div class="product-previews" :data-no-products="featuredProducts.length === 0" :data-hide="showProductsList || showCart || selectedProduct !== null">
      <transition-group name="fade" mode="out-in" tag="div">
        <PreviewItem v-for="product in featuredProducts" :key="product.id" :id="product.id" :fqdn="item.data.fqdn" />
      </transition-group>
    </div>
  </template>
  <div :class="`buttons ${chatAvailable && !isReplay ? '' : 'buttons--not-live'}`">
    <div class="icon-group chat-group">
      <IconButton icon="edit-pen2" :class="`fixed-large ${!showChatInput && showChat ? 'half-border-left' : 'hide'}`" v-if="!showChatInput" @click.native="toggleChatInput" />
      <IconButton icon="chat2" :class="`fixed-large chat ${!showChatInput && showChat ? 'half-border-right' : ''} ${showChat ? 'active' : ''}`" @click.native="toggleChat" />
    </div>
    <Hadiraja :id="id" v-if="chatAvailable && !isReplay" />
    <IconButton :icon="showCart ? 'close-x' : 'shopping-bag'" v-if="handleProduct === 'internal' && cartItems > 0" :text="cartItems > 9 ? '9+' : cartItems" @click.native="toggleCart" :class="`fixed-large cart ${showCart ? 'active' : 'large-content'}`" />
    <IconButton :icon="showProductsList ? 'close-x' : 'list'" :class="`streamify-player__${showProductsList ? 'close-products active' : 'list-products'} product-list fixed-large`" @click.native="toggleProducts" :data-hide="options.orientation === 'portrait' && sides" />
  </div>
  <Hadirajan :id="id" />
  <div class="streamify-player__logo" v-if="logo">
    <img :src="logo" />
  </div>
</div>
</template>

<script>

import Countdown from '@/components/Countdown.vue';
import PreviewItem from '@/components/Products/PreviewItem.vue';
import IconButton from '@/components/Buttons/Icon.vue';
import Hadirajan from '@/components/Hadiraja/Hadirajan.vue';
import Hadiraja from '@/components/Hadiraja/Hadiraja.vue';

// eslint-disable-next-line
import { ClassWatcher, getQueryParameterValue, randomNumberInRange } from '../../utils';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({
        orientation: 'portrait',
      }),
    },
    embed: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Number,
      default: null,
    },
    productModalOpen: {
      type: Boolean,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    sides: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Countdown,
    PreviewItem,
    // eslint-disable-next-line
    IconButton,
    Hadirajan,
    Hadiraja,
  },
  computed: {
    /**
     * Get the selected product fomr the store
     * @returns {?String}
     */
    selectedProduct() {
      return this.$store.getters['Products/selectedProduct'];
    },
    /**
     * Return a boolean if the chat is a replay
     * @returns {Boolean}
     */
    isReplay() {
      const {
        item: {
          data: {
            life_cycle_status: status,
          },
        },
      } = this;
      return status === 'complete';
    },
    /**
     * Return a boolean if the chat is available
     * @returns {Boolean}
     */
    chatAvailable() {
      const {
        item: {
          data: {
            scheduled_start_time,
            life_cycle_status: status,
          },
        },
        minTimeBeforeStart,
      } = this;

      const now = new Date();
      const future = new Date(scheduled_start_time);

      return (status === 'live' || status === 'liveStarting' || status === 'liveStopping' || status === 'ready' || status === 'created' || status === 'complete') && ((future - now) / 1000 <= minTimeBeforeStart);
    },
    /**
     * Return the config for liveshopping
     * @returns {Object}
     */
    config() {
      return this.$store.getters['LiveShopping/config'];
    },
    /**
     * Return a string if the product should open in a new window, iframe or handle it internally
     * @returns {String}
     */
    handleProduct() {
      const {
        config: {
          events,
          urls: {
            product_add_to_cart,
          },
        },
        persistent,
      } = this;

      if (!!product_add_to_cart === false && events.indexOf('cart.add') === -1 && !!window.Shopify === false && persistent) {
        return 'iframe';
      }

      if (!!product_add_to_cart === false && events.indexOf('cart.add') === -1 && !!window.Shopify === false) {
        return 'external';
      }

      return 'internal';
    },
    showProductsList() {
      return this.$store.getters['Products/showProductsList'];
    },
    isStreamLive() {
      return this.$store.getters['Player/videoIsLive'](this.id);
    },
    logo() {
      const {
        item: {
          data: {
            fqdn,
          },
        },
      } = this;

      const config = this.$store.getters['LiveShopping/config'];
      if (!config?.images?.logo) {
        return null;
      }

      return `https://${fqdn}/images/h/50/${config.images.logo}`;
    },
    /**
     * Return a num of cartitems
     * @returns {Number}
     */
    cartItems() {
      return this.$store.getters['Cart/numItems'];
    },
    /**
     * Return broadcast or video item with supplied ID
     * @returns {Object}
     */
    item() {
      return this.$store.getters['Broadcast/get'](this.id);
    },
    /**
     * Return if the video is playable or not
     * @returns {Boolean}
     */
    playable() {
      const status = this.item.data?.life_cycle_status ?? '';
      return (status === 'complete' || status === 'live' || status === 'liveStopping');
    },
    isLive() {
      const status = this.item.data?.life_cycle_status ?? '';
      return (status === 'live' || status === 'liveStopping');
    },
    /**
     * Return the poster for the item
     * @return {String}
     */
    poster() {
      const {
        item: {
          data: {
            resources,
            fqdn,
            images = null,
          },
        },
      } = this;

      if (images && images.length > 0) {
        return `backgroundImage: url('https://${fqdn}/images/h/800/${images[0]}')`;
      }

      const [poster] = resources.filter((resource) => (typeof resource.poster !== 'undefined' ? resource : null));
      if (poster) return poster.poster.substring(0, 4) === 'http' ? `backgroundImage: url('${poster.poster}')` : `backgroundImage: url('https://${fqdn}/images/h/800/${poster.poster}')`;
      return null;
    },
    /**
     * Return the currenttime of the video
     * @returns {Number}
     */
    currentVideoTime() {
      return this.$store.getters['Player/videoCurrentTime'](this.id);
    },
    /**
     * Return the list of the current featured products
     * @returns {Array}
     */
    featuredProducts() {
      const streamProducts = this.$store.getters['Products/streamProducts'](this.id);
      const { currentVideoTime, item: { data: { actual_start_time } } } = this;
      const curr = new Date(actual_start_time);
      curr.setSeconds(curr.getSeconds() + currentVideoTime);
      return streamProducts.filter((p) => (curr >= new Date(p.displayAt) && typeof p.hideAt === 'undefined') || (curr >= new Date(p.displayAt) && new Date(p.hideAt) >= curr));
    },
    showCart() {
      return this.$store.getters['Cart/show'];
    },
    showChat() {
      return this.$store.getters['Chat/showChat'];
    },
    showChatInput() {
      return this.$store.getters['Chat/showChatInput'];
    },
  },
  methods: {
    toggleChat() {
      const { showChat } = this;
      this.$store.dispatch('Chat/toggleChat', !showChat);
    },
    toggleChatInput() {
      const { showChatInput } = this;
      this.$store.dispatch('Chat/toggleChatInput', !showChatInput);
    },
    toggleCart() {
      const { showCart } = this;
      this.$store.dispatch('Cart/toggleCart', !showCart);
      this.$store.dispatch('Products/toggleProductsList', false);
      this.$store.dispatch('Products/selectProduct', { product: null, fqdn: this.item.data.fqdn, type: 'good' });
    },
    toggleProducts() {
      const { showProductsList } = this;
      this.$store.dispatch('Products/toggleProductsList', !showProductsList);
      this.$store.dispatch('Cart/toggleCart', false);
    },
    /**
     * Playersetup
     */
    setup() {
      const {
        $refs: {
          videoplayer,
        },
        options,
        isLive,
        item: {
          data: {
            life_cycle_status,
          },
        },
      } = this;

      this.player = videojs(videoplayer, options);

      // eslint-disable-next-line
      new ClassWatcher(this.player.el(), 'vjs-user-active', this.showControls, this.hideControls);
      this.$store.dispatch('Player/setVideoObject', {
        id: this.id,
        video: this.player,
        live: ['ready', 'livestarting', 'live', 'livestopping'].indexOf(life_cycle_status) >= 0,
      });

      this.player.on('play', () => this.startTimeUpdate());
      this.player.on('pause', () => this.stopTimeUpdate());
      this.player.on('ended', () => this.stopTimeUpdate());
      this.player.on('seeked', () => this.timeUpdate());

      this.player.ready(() => {
        if (options.controlBar.fullscreenToggle === false) {
          // eslint-disable-next-line
          this.player.tech_.off('dblclick');
        }

        if (this.startFrom && !isLive) {
          this.player.currentTime(this.startFrom);
        }

        this.$emit('ready', true);
        this.play();

        const video = this.$el.querySelector('video');
        try {
          video.volume = this.$store.getters['Player/volumeSetting'];
        } catch (e) { /* Do nothing */ }

        video.addEventListener('pause', () => {
          if (video.seeking) {
            return;
          }
          this.paused = true;
          this.sendAnalyticsEvent('pause');
        });
        video.addEventListener('play', () => {
          if (this.firstPlay) {
            this.firstPlay = false;
            this.sendAnalyticsEvent('play');
          }

          if (this.paused) {
            this.paused = false;
            this.sendAnalyticsEvent('resume');
          }
        });
        video.addEventListener('seeking', () => {
          clearTimeout(this.seekTimeout);
          this.seekTimeout = setTimeout(() => {
            this.sendAnalyticsEvent('seek');
          }, 1000);
        });
        video.addEventListener('volumechange', () => {
          const oldVolume = this.$store.getters['Player/volumeSetting'];
          if (video.muted) {
            this.$store.dispatch('Player/updateVolumeSetting', 0);
            this.sendAnalyticsEvent('muted');
          } else {
            this.$store.dispatch('Player/updateVolumeSetting', video.volume);
            // eslint-disable-next-line
            if (oldVolume === 0) {
              this.sendAnalyticsEvent('unmuted');
            }
          }
        });

        this.analyticsInterval = setInterval(() => {
          if (!video.paused && !video.ended) {
            this.sendAnalyticsEvent('ping');
          }
        }, 60000);
      });
    },
    sendAnalyticsEvent(event) {
      const { data: { id: broadcastId, scheduled_start_time, fqdn } } = this.item;
      this.$store.dispatch('Analytics/event', {
        id: scheduled_start_time ? '0' : broadcastId,
        broadcast_id: scheduled_start_time ? broadcastId : '',
        account: fqdn.split('.')[0],
        event,
      });
    },
    /**
     * Play video
     * If the video does not start, lets mute it and try again. If that fails, though luck.
     */
    async play() {
      try {
        await this.player.play();
      } catch (e) {
        if (!this.player.muted()) {
          this.player.muted(true);
          this.play();
          return;
        }
      }

      const video = this.$el.querySelector('video');
      if (video.muted || video.volume === 0) {
        this.sendAnalyticsEvent('muted');
      }
    },
    /**
     * Dispatch event to player store with the current time on video
     */
    timeUpdate() {
      this.$store.dispatch('Player/updateVideoCurrentTime', { id: this.id, time: this.player.currentTime() });
      this.actualPlayed = true;
    },
    /**
     * Start interval that runs timeUpdate each second
     */
    startTimeUpdate() {
      this.timeUpdateInterval = setInterval(() => {
        this.timeUpdate();
      }, 1000);
    },
    /**
     * Removes interval for timeUpdate
     */
    stopTimeUpdate() {
      clearInterval(this.timeUpdateInterval);
    },
    /**
     * Eventhandler when controls are dissapearing
     */
    hideControls() {
      if (!this.player.paused()) {
        this.$store.dispatch('Player/controlsToggle', {
          id: this.id,
          bool: false,
        });
      }
    },
    /**
     * Eventhandler when controls reappear
     */
    showControls() {
      this.$store.dispatch('Player/controlsToggle', {
        id: this.id,
        bool: true,
      });
    },
  },
  watch: {
    /**
     * Watch computed value playable
     * and setup with either a timeout or directly
     */
    playable: {
      immediate: true,
      /**
       * @param {Boolean} now
       * @param {?Boolean} before
       */
      handler(now, before) {
        if ((typeof before === 'boolean' && !before) && now) {
          const wait = parseFloat(randomNumberInRange(8, 10).toFixed(3));
          setTimeout(() => {
            this.setup();
          }, wait * 1000);
          return;
        }

        if (now === true && typeof before === 'undefined') {
          this.$nextTick(() => {
            this.setup();
          });
        }
      },
    },
  },
  data() {
    return {
      actualPlayed: false,
      loading: true,
      player: null,
      timeUpdateInterval: null,
      analyticsInterval: null,
      paused: false,
      firstPlay: true,
      seekTimeout: null,
      startFrom: null,
      minTimeBeforeStart: process.env.VUE_APP_CHAT_TIME_BEFORE_START,
    };
  },
  mounted() {
    const t = this.time || getQueryParameterValue('t');
    if (t) {
      this.startFrom = parseInt(t, 10);
    }
  },
  beforeDestroy() {
    clearInterval(this.timeUpdateInterval);
    clearInterval(this.analyticsInterval);
    if (this.player) {
      this.player.dispose();
      this.sendAnalyticsEvent('close');
    }
  },
};
</script>

<style lang="scss">
.streamify-player {
  .buttons {
    .cart {
      position: relative;

      &.active {
        span {
          display: none;
        }
      }

      span {
        position: absolute;
        color: white;
        font-size: 12px;
        top: 50%;
        transform: translateY(-5px);
        font-weight: 700;
      }
    }
  }
}
</style>

<style lang="scss" scoped>

@import '@/assets/styles/Mixins';

.streamify-player {
  background-color: white;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  overflow: hidden;

  @include e('logo') {
    position: absolute;
    top: 32px;
    right: 32px;
    pointer-events: none;

    img {
      max-height: 60px;
      max-width: 120px;
    }
  }

  .buttons {
    display: flex;
    position: absolute;
    right: 32px;
    bottom: 32px;
    z-index: 10;
    transition: right .5s, bottom .5s;

    & > * {
      &[data-hide="true"] {
        display: none;
      }
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }

  .product-previews {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  @include e('countdown') {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  @include e('player') {
    &.vjs-fluid {
      height: 100%;
      line-height: 0;
      padding-top: 0;
      width: 100%;

      video {
        display: block;
        margin: 0 auto;
        max-height: 100vh;
        max-height: 100vh;
        max-width: 100vw;
        position: relative;
      }
    }

    @include m('landscape') {
      video {
        height: auto;
        width: 100%;
      }
    }

    @include m('portrait') {
      video {
        height: 100%;
        width: auto;
        @media (min-width: 768px) {
          max-width: calc(90vh * 0.57) !important;
          // width: 100%;
          height: 90vh;
        }
      }
    }
  }
}
</style>
<style lang="scss">
/** Fix */
.streamify-player {
  overflow: hidden;

  &.streamify-player--playing {
    background-image: none !important;
    background-color: transparent;

    .video-js {
      background-color: transparent;

      video {
        background-color: black;
      }
    }
  }

  & > video {
    height: 100%;
    width: 100%;
  }

  .video-js {
    &:hover {
      .vjs-big-play-button {
        border-color: rgba(255, 255, 255, 1);
        background-color: transparent;
        span.vjs-icon-placeholder::before {
          color: white;
        }
      }
    }

    video {
      &:hover {
        cursor: pointer;
      }
    }

    .vjs-error-display, .vjs-modal-dialog {
      z-index: 0;
    }

    .vjs-big-play-button {
      pointer-events: all;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      left: 50%;
      background-color: transparent;
      border-radius: 100%;
      height: 100px;
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      border: 2px solid rgba(255, 255, 255, .5);
      // transition: all .3s !important;

      &:focus {
        background-color: transparent !important;
      }

      span.vjs-icon-placeholder::before {
        color: rgba(255, 255, 255, .5);
        content: "\e909";
        font-family: 'streamify-play-icons';
        font-size: 64px;
        position: relative;
        // transition: color .3s;
      }
    }

    &.vjs-has-started:not(.vjs-ended) {
      .vjs-big-play-button {
        display: none;
      }
    }

    .vjs-control-bar {
      background-color: transparent;
      height: 48px;

      &:before {
        background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.80) 100%);
        content: "";
        height: 128px;
        width: 100%;
        left: 0;
        bottom: 0;
        position: absolute;
        pointer-events: none;
      }

      button {
        align-items: center;
        justify-content: center;

        &:focus {
          text-shadow: none;
        }

        & > .vjs-icon-placeholder:before {
          position: relative;
        }
      }

      .vjs-slider {
        &:focus {
          text-shadow: none;
          box-shadow: none;
        }
      }

      .vjs-progress-control {
        order: 4;
      }

      .vjs-live-control, .vjs-seek-to-live-control {
        align-items: center;
        flex: none;
        order: 3;
      }

      .vjs-progress-control {
        padding-right: 10px;
        .vjs-progress-holder {
          font-size: 16px;
        }
        &:hover .vjs-progress-holder {
          font-size: 16px;
        }

        .vjs-play-progress {
          &:before {
            top: 2px;
          }
        }
      }

      .vjs-subs-caps-button {
        display: none;
      }

      .vjs-time-control.vjs-current-time {
        align-items: center;
        order: 2;
        display: flex;
      }

      .vjs-remaining-time.vjs-time-control.vjs-control {
        display: none;
        order: 2;
        align-items: center;
      }

      .vjs-picture-in-picture-control {
        display: none;
      }

      .vjs-play-control {
        order: 0;

        span::before {
          font-family: 'streamify-play-icons';
          font-size: 24px;
        }
        &.vjs-playing {
          span::before {
            content: "\e908";
          }
        }
        &.vjs-paused {
          span::before {
            content: "\e909";
          }
        }
      }

      .vjs-volume-panel {
        order: 1;
      }

      .vjs-mute-control {
        span.vjs-icon-placeholder::before {
          font-family: 'streamify-play-icons';
          font-size: 24px;
        }

        &.vjs-vol-0 span.vjs-icon-placeholder::before {
          content: "\e90b";
        }

        &.vjs-vol-1 span.vjs-icon-placeholder::before {
          content: "\e90c";
        }
        &.vjs-vol-2 span.vjs-icon-placeholder::before {
          content: "\e90d";
        }
        &.vjs-vol-3 span.vjs-icon-placeholder::before {
          content: "\e90e";
        }
      }

      .vjs-volume-panel {
        display: flex;
        align-items: center;
      }

      .vjs-volume-control {
        .vjs-volume-bar {
          .vjs-volume-level::before {
            top: 2px;
          }
        }
      }
    }

    &.vjs-error {

      .vjs-loading-spinner {
        display: none;
      }

      .vjs-modal-dialog {
        background: rgba(0, 0, 0, 1);
        width: 100%;
        max-width: 450px;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &:before {
          content: "";
        }

        .vjs-modal-dialog-content {
          position: relative;
          height: auto;
          width: auto;
          font-family: 'Inter';
        }
      }
    }
  }
}
</style>
