<template>
  <div :class="`streamify-chat ${!chatAvailable ? 'streamify-chat--not-ready' : ''}`" :data-show="showChat">
    <template v-if="chatAvailable || isReplay">
      <div class="streamify-chat-pinned-message" v-if="pinnedMessage" key="pinned-message">
        <i class="sfplayicon-pin"></i>
        <p v-html="parseMarkdown(pinnedMessage)"></p>
      </div>
      <Messages :id="id" :chattable="chattable" />
      <div :data-show="showChatInput" :class="`streamify-chat-bottom-outer ${extraBottomPadding && hasInputFocus ? 'streamify-chat-bottom--extra-padding' : ''} ${changeNickname ? 'streamify-chat-bottom-outer--changingNickname' : ''}`" v-if="chattable">
        <div class="streamify-chat-bottom">
          <Nickname :id="id" @focus="bajs" v-if="changeNickname" @nicknameState="changeNicknameState = $event" @toggleChat="toggleChat" @nicknameChange="toggleNicknameChange($event)" />
          <template v-else>
            <SendMessage :id="id" @focus="bajs" @close="toggleChatInput" @changeNickname="toggleNicknameChange(true)" ref="sendmessage" />
          </template>
        </div>
        <template v-if="config.emojis.length > 0">
          <Hadiraja :id="id" v-if="!changeNickname" />
        </template>
      </div>
    </template>
    <template v-else>
      <div class="streamify-chat__chat-not-ready">
        <i class="sfplayicon-chat2"></i>
        <p>The chat will be available {{ minTimeBeforeStart / 60 }} min before broadcast begins.</p>
      </div>
    </template>
  </div>
</template>

<script>

import {
  parseMarkdown,
} from '@/utils';

import Hadiraja from '@/components/Hadiraja/Hadiraja.vue';
import Messages from './Messages.vue';
import Nickname from './Nickname.vue';
import SendMessage from './SendMessage.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    Hadiraja,
    Messages,
    Nickname,
    SendMessage,
  },
  computed: {
    /**
     * Return the config for the liveshopping player
     * @returns {Object}
     */
    config() {
      return this.$store.getters['LiveShopping/config'];
    },
    /**
     * Return the current pinned message
     * @returns {String}
     */
    pinnedMessage() {
      // return this.$store.getters['Chat/pinnedMessage'](this.id) || this.item?.data?.event?.chat?.pinned_messages?.pinned;
      // return this.$store.getters['Chat/pinnedMessage'](this.id);
      return this.item?.data?.event?.chat?.pinned_messages?.pinned;
    },
    hasMessages() {
      return this.$store.getters['Chat/hasMessages'](this.id);
    },
    /**
     * Return the current chatname that has been sen
     * @returns {String}
     */
    nickname() {
      return this.$store.getters['Chat/nickname'];
    },
    showChat() {
      return this.$store.getters['Chat/showChat'];
    },
    showChatInput() {
      return this.$store.getters['Chat/showChatInput'];
    },
    /**
     * Return broadcast or video item with supplied ID
     * @returns {Object}
     */
    item() {
      return this.$store.getters['Broadcast/get'](this.id);
    },
    /**
     * Return a boolean if the chat is a replay
     * @returns {Boolean}
     */
    isReplay() {
      const {
        item: {
          data: {
            life_cycle_status: status,
          },
        },
      } = this;
      return status === 'complete';
    },
    lifeCycleStatus() {
      return this.item?.data?.life_cycle_status;
    },
    /**
     * Return a boolean if the chat is available
     * @returns {Boolean}
     */
    chatAvailable() {
      const {
        item: {
          data: {
            scheduled_start_time,
            life_cycle_status: status,
          },
        },
        // eslint-disable-next-line
        ticktock,
        minTimeBeforeStart,
      } = this;

      const now = new Date();
      const future = new Date(scheduled_start_time);

      return (status === 'live' || status === 'liveStarting' || status === 'liveStopping' || status === 'ready' || status === 'created' || status === 'complete') && ((future - now) / 1000 <= minTimeBeforeStart);
    },
  },
  methods: {
    bajs(event) {
      this.hasInputFocus = event;
    },
    /**
     * Parse and sanitize makrdown
     * @param {String} text
     * @param {Number} max
     * @returns {String}
     */
    parseMarkdown(text, max) {
      return parseMarkdown(text, max);
    },
    toggleChatInput() {
      this.$store.dispatch('Chat/toggleChatInput', false);
    },
    toggleChat() {
      const {
        showChat,
      } = this;
      this.$store.dispatch('Chat/toggleChat', !showChat);

      this.$nextTick(() => {
        if (this.showChat) {
          if (!this.$refs && !this.$refs.sendmessage.$refs) {
            return;
          }

          try {
            this.$refs.sendmessage.$refs.chatInput.focus();
          } catch (e) { /* Do nothing */ }
        }
      });
    },
    /**
     * Toggle nickname change
     * @param {Boolean} bool
     */
    toggleNicknameChange(bool) {
      this.changeNickname = bool;

      this.$nextTick(() => {
        if (!this.$refs?.sendmessage?.$refs?.chatInput) {
          return;
        }

        try {
          this.$refs.sendmessage.$refs.chatInput.focus();
        } catch (e) { /* Do nothing */ }
      });
    },
    /**
     * Send nickname to eventsocket
     */
    async sendNickname() {
      const {
        nickname,
        id,
      } = this;

      if (!nickname || nickname === '') {
        return;
      }

      const result = await this.$store.dispatch('Events/sendEvent', {
        id,
        payload: {
          event: 'chat.user',
          payload: {
            name: nickname,
          },
        },
      });

      if (!result) {
        setTimeout(() => {
          this.sendNickname();
        }, 500);
      }
    },
  },
  watch: {
    nickname: {
      immediate: true,
      handler(value) {
        if (value !== '') {
          this.sendNickname();
        }
      },
    },
    lifeCycleStatus: {
      immediate: true,
      handler(is, was) {
        if (is === 'complete' && !was) {
          this.chattable = false;
        }

        if (is === 'ready' && !was) {
          this.secondInterval = setInterval(() => {
            this.ticktock = !this.ticktock;
          }, 1000);
        }
      },
    },
  },
  data() {
    return {
      minTimeBeforeStart: process.env.VUE_APP_CHAT_TIME_BEFORE_START,
      inVideoToggle: false,
      changeNickname: false,
      changeNicknameState: '',
      extraBottomPadding: false,
      hasInputFocus: false,
      chattable: true,
      secondInterval: null,
      ticktock: false,
    };
  },
  mounted() {
    const { nickname } = this;

    if (nickname === '') {
      this.toggleNicknameChange(true);
    }

    if (navigator.userAgent.indexOf('iPhone OS 15') !== -1) {
      this.extraBottomPadding = true;
    }
  },
  beforeDestroy() {
    clearInterval(this.secondInterval);
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/styles/Mixins';

.streamify-chat-pinned-message {
  background: var(--sf-chat-pinned-background);
  margin: 16px 16px 0;
  padding: 5px 25px;
  font-size: 13px;
  color: var(--sf-chat-pinned-color);
  text-align: center;
  line-height: 25px;
  position: relative;

  i {
    font-size: 20px;
    position: absolute;
    left: 12px;
    top: 60%;
    transform: translateY(-50%);
  }
}

.streamify-chat {
  display: flex;
  flex-direction: column;

  .hadiraja {
    display: none;
  }

  @include e('chat-not-ready') {
    align-items: center;
    color: var(--sf-title-color);
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0 16px;

    i {
      font-size: 48px;
      margin-bottom: 16px;
    }

    p {
      font-size: 12.8px;
    }
  }
}

.streamify-chat-nickname-change {
  display: flex;
  font-size: 11px;
  padding: 5px 8px;

  @include e('current') {

  }

  @include e('change') {
    cursor: pointer;
    margin-left: auto;
    font-weight: 500;
  }
}

.streamify-chat-bottom-outer {
  display: flex;
}

.streamify-chat-bottom--extra-padding {
  // padding-bottom: 32px;
}

.streamify-chat-bottom {
  // overflow: hidden;
  transition: all .5s;
  width: 100%;
  align-self: flex-start;
  height: auto;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20);
  -webkit-tap-highlight-color: transparent;
  // background-color: white;

  max-width: 100% !important;
  max-height: 100%;
  border-radius: 12px;
  white-space: nowrap;

  .streamify-chat-bottom__toggle {
    display: none;
  }

  & > *:not(.streamify-chat-bottom__toggle) {
    display: flex;
  }

  .streamify-chat-bottom__toggle {
    border-radius: 100%;
    border: 0;
    color: #000000;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    font-size: 20px;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
