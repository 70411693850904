import Vue from 'vue';

const playerState = {
  player: {},
  volume: 1,
};

const playerMutations = {
  /**
   * Creates video parent object in state
   * @param {Object} state
   * @param {String} id
   */
  create(state, id) {
    Vue.set(state.player, id, { video: {}, radio: {} });
  },
  /**
   * Sets current video time in state
   * @param {Object} state
   * @param {Object} param1
   * @param {String} param1.id
   * @param {Number} param1.time
   */
  setVideoCurrentTime(state, { id, time }) {
    Vue.set(state.player[id].video, 'currenttime', time);
  },
  /**
   * Sets current radio time in state
   * @param {Object} state
   * @param {Object} param1
   * @param {String} param1.id
   * @param {Number} param1.time
   */
  setRadioCurrentTime(state, { id, time }) {
    state.player[id].radio.currenttime = time;
  },
  /**
   * Sets current video in state
   * @param {Object} state
   * @param {Object} param1
   * @param {String} param1.id
   * @param {Object} param1.video
   */
  setVideoObject(state, { id, video, live }) {
    Vue.set(state.player[id], 'video', {
      obj: video,
      live,
    });
  },
  /**
   * Sets boolean for if controls is visible for video
   * @param {Object} state
   * @param {Object} param1
   * @param {String} param1.id
   * @param {Boolean} param1.bool
   */
  setControlsVisible(state, { id, bool }) {
    Vue.set(state.player[id].video, 'controls', bool);
  },
  setVolume(state, volume) {
    Vue.set(state, 'volume', volume);
  },
};

const playerGetters = {
  /**
   * Returns current radio time
   * @param {Object} state
   * @param {String} id
   * @returns {Number}
   */
  radioCurrentTime: (state) => (id) => state.player[id].radio.currenttime,
  /**
   * Returns current video time
   * @param {Object} state
   * @param {String} id
   * @returns {Number}
   */
  videoCurrentTime: (state) => (id) => (state.player[id] && state.player[id].video && state.player[id].video.currenttime ? state.player[id].video.currenttime : 0),
  /**
   * Returns if controls are active on video
   * @param {Object} state
   * @param {String} id
   * @returns {Boolean}
   */
  videoControlsActive: (state) => (id) => {
    if (state.player[id]) {
      if (typeof state.player[id].video.controls === 'undefined') {
        return true;
      }
      return state.player[id].video.controls;
    }
    return false;
  },
  volumeSetting: (state) => state.volume,
  videoIsLive: (state) => (id) => (state.player[id] && state.player[id].video && state.player[id].video.live ? state.player[id].video.live : false),
};

const playerActions = {
  /**
   * Set video currenttime
   * @param {Object} param0
   * @param {Function} param0.commit
   * @param {Object} param0.state
   * @param {Object} param1
   * @param {String} param1.id
   * @param {Number} param1.time
   */
  goTo: ({ commit, state }, { id, time }) => {
    if (!state.player[id] || !state.player[id].video || !state.player[id].video.obj) {
      return;
    }

    state.player[id].video.obj.currentTime(time);
    state.player[id].video.obj.play();
    commit('setVideoCurrentTime', { id, time });
  },
  /**
   * Create, if not exist, and set video in state
   * @param {Object} param0
   * @param {Function} param0.commit
   * @param {Object} param0.state
   * @param {Object} param1
   * @param {String} param1.id
   * @param {Object} param1.video
   */
  setVideoObject: ({ commit, state }, { id, video, live }) => {
    if (!state.player[id]) {
      commit('create', id);
    }

    commit('setVideoObject', { id, video, live });
  },
  /**
   * Update where user is in video in state
   * @param {Object} param0
   * @param {Function} param0.commit
   * @param {Object} param0.state
   * @param {Object} param1
   * @param {String} param1.id
   * @param {Number} param1.time
   */
  updateVideoCurrentTime: ({ commit, state }, { id, time }) => {
    if (!state.player[id]) {
      commit('create', id);
    }

    commit('setVideoCurrentTime', { id, time });
  },
  /**
   * Set radio currenttime
   * @param {Object} param0
   * @param {Function} param0.commit
   * @param {Object} param0.state
   * @param {Object} param1
   * @param {String} param1.id
   * @param {Number} param1.time
   */
  updateRadioCurrentTime: ({ commit, state }, { id, time }) => {
    if (!state.player[id]) {
      commit('create', id);
    }

    commit('setRadioCurrentTime', { id, time });
  },
  updateVolumeSetting: ({ commit }, volume) => {
    commit('setVolume', volume);
  },
  /**
   * Set visible state of video controls
   * @param {Object} param0
   * @param {Function} param0.commit
   * @param {Object} param1
   * @param {String} param1.id
   * @param {Boolean} param1.bool
   */
  controlsToggle: ({ commit }, { id, bool }) => {
    commit('setControlsVisible', { id, bool });
  },
};

export default {
  namespaced: true,
  state: playerState,
  mutations: playerMutations,
  getters: playerGetters,
  actions: playerActions,
};
