import { Broadcast } from '@/api/broadcast';
import Vue from 'vue';

const broadcastState = {
  broadcast: {},
};

const broadcastMutations = {
  /**
   * Set broadcast and broadcaststate
   * @param {Object} state
   * @param {Object} param1
   * @param {String} param1.id
   * @param {Boolean} param1.loading
   * @param {Boolean} param1.error
   * @param {Object} param1.data
   */
  set(state, {
    id,
    loading = true,
    error = false,
    data,
  }) {
    if (state.broadcast[id]) {
      Vue.set(state.broadcast, id, { loading, error, data: { ...state.broadcast[id].data, ...data } });
    } else {
      Vue.set(state.broadcast, id, { loading, error, data });
    }
  },
  /**
   * Update products in broadcast state with {ID}
   * @param {Object} state
   * @param {Object} param1
   * @param {String} param1.id
   * @param {Array} param1.products
   */
  // eslint-disable-next-line
  updateProductsList(state, { id, products }) {
    const {
      event: {
        shopping: {
          products: currentProducts,
        },
      },
    } = state.broadcast[id].data;

    products.forEach((product) => {
      const index = currentProducts.indexOf(product);
      if (index === -1) {
        state.broadcast[id].data.event.shopping.products.push(product);
      }
    });
  },
  /**
   * Update broadcastobject with pinned message
   * @param {Object} state
   * @param {Object} param1
   * @param {String} param1.id
   * @param {String} param1.message
   */
  setPinnedMessage(state, { id, message }) {
    if (state.broadcast[id]) {
      if (state.broadcast[id].data.event.chat.pinned_messages === null) {
        Vue.set(state.broadcast[id].data.event.chat, 'pinned_messages', {
          pinned: message,
        });
      } else {
        Vue.set(state.broadcast[id].data.event.chat.pinned_messages, 'pinned', message);
      }
    }
  },
  /**
   * Update broadcastobject with welcome message
   * @param {Object} state
   * @param {Object} param1
   * @param {String} param1.id
   * @param {String} param1.message
   */
  setWelcomeMessage(state, { id, message }) {
    if (state.broadcast[id]) {
      if (state.broadcast[id].data.event.chat.pinned_messages === null) {
        Vue.set(state.broadcast[id].data.event.chat, 'pinned_messages', {
          welcome: message,
        });
      } else {
        Vue.set(state.broadcast[id].data.event.chat.pinned_messages, 'welcome', message);
      }
    }
  },
};

const broadcastGetters = {
  /**
   * Get broadcast with {ID} from state
   * @param {Object} state
   * @param {String} id
   * @returns {Object?}
   */
  get: (state) => (id) => state.broadcast[id],
};

const broadcastActions = {
  /**
   * Fetch a broadcast
   * @param {Object} param0
   * @param {Function} param0.commit
   * @param {Object} param0.state
   * @param {Object} param1
   * @param {String} param1.id
   * @param {Boolean} param1.force
   * @returns {Object|Boolean}
   */
  get: async ({
    commit,
    dispatch,
    state,
    rootGetters,
  // eslint-disable-next-line
  }, { id, force = false }) => {
    const {
      fqdn,
    } = rootGetters['LiveShopping/config'];

    // let productsListLoaded = null;
    // let checkInterval = null;
    // let lolInterval = null;
    // let productsList;

    if (!state.broadcast[id] || force) {
      commit('set', { id, loading: true, error: false });
      try {
        const broadcast = await Broadcast.getOne(id, fqdn);

        if (broadcast.event.shopping.enable) {
          await dispatch('Products/getList', { fqdn: broadcast.fqdn, type: 'good' }, { root: true });
        }

        await new Promise((resolve, reject) => {
          let checkInterval = null;

          const checkProduct = () => {
            const productsList = rootGetters['Products/productList'](broadcast.fqdn, 'good');
            if (productsList.loading === false && productsList.error === false) {
              clearInterval(checkInterval);
              resolve();
            }

            if (productsList.error === true) {
              reject();
            }
            return null;
          };

          checkInterval = setInterval(checkProduct, 100);
        });

        if (broadcast.life_cycle_status === 'complete') {
          await dispatch('Events/replay', { id, fqdn: broadcast.fqdn }, { root: true });
        } else {
          await dispatch('Events/connect', { id, fqdn: broadcast.fqdn }, { root: true });
        }

        commit('set', {
          id,
          data: broadcast,
          loading: false,
          error: false,
        });
        return true;
      } catch (e) {
        commit('set', { id, loading: false, error: true });
        return false;
      }
    }
  },
  /**
   * Update list of products for broadcast
   * @param {Object} param0
   * @param {Function} param0.commit
   * @param {Object} payload
   */
  updateProductsList: async ({ commit }, payload) => {
    commit('updateProductsList', payload);
  },
  /**
   * Update broadcast with data from event
   * @param {Object} param0
   * @param {Function} param0.commit
   * @param {Object} param1
   * @param {String} param1.id
   * @param {Object} param1.message
   */
  handleMessage: ({ commit }, { id, message }) => {
    commit('set', { id, loading: false, data: message.payload });
  },
  /**
   * Handle events from eventstream and commit them
   * @param {Object} obj1
   * @param {Function} commit
   * @param {Object} obj2
   * @param {String} id
   * @param {Array} events
   */
  handleEvents: ({ commit }, { id, events }) => {
    for (let i = 0; i < events.length; i += 1) {
      commit('set', {
        id,
        loading: false,
        data: events[i].payload,
      });
    }
  },
};

export default {
  namespaced: true,
  state: broadcastState,
  mutations: broadcastMutations,
  getters: broadcastGetters,
  actions: broadcastActions,
};
