import { get } from '../utils';

const Broadcast = {
  getOne: async (id, fqdn) => {
    const response = await get(`${fqdn ? `https://${fqdn}` : process.env.VUE_APP_API_URL}/play/content/${id}`).catch(() => false);
    if (!response) {
      throw new Error(`Could not fetch broadcast with id: ${id}`);
    }

    return response.data;
  },
};

// eslint-disable-next-line
export { Broadcast };
