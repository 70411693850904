import { Events } from '@/api/events';
import Vue from 'vue';

export default {
  namespaced: true,

  // Our events state
  state: {
    socket: {},
  },

  // Getters for events state
  getters: {},

  // Mutations for events
  mutations: {
    /**
     * Set socket in state
     * @param {Object} state
     * @param {Object} obj
     * @param {String} obj.id
     * @param {Object} obj.socket
     */
    setSocket(state, { id, socket }) {
      Vue.set(state.socket, id, { state: 'connected', socket });
    },
    /**
     * Close socket with {id}
     * @param {Object} state
     * @param {String} id
     */
    closeSocket(state, id) {
      if (state.socket[id] && state.socket[id].socket) {
        state.socket[id].socket.close();
        state.socket[id].state = 'disconnected';
      }
    },
  },

  // Actions for events
  actions: {
    /**
     * Connect to socket
     * @param {Object} obj1
     * @param {Function} obj.commit
     * @param {Function} obj.dispatch
     * @param {Function} obj.rootGetters
     * @param {Object} obj2
     * @param {String} obj2.id
     * @param {String} obj2.fqdn
     */
    connect: async ({ commit, dispatch, rootGetters }, { id, fqdn }) => {
      // Get our sessionID
      const sessionId = rootGetters['Chat/sessionId'](id);
      // Start socket
      const socket = await new WebSocket(`wss://wss.streamify.io/chat/${id}${sessionId ? `/${sessionId}` : ''}?streams=viewer,broadcast,system`);
      // Commit our socket to store
      commit('setSocket', { id, socket });

      // Listen for messages on socket and dispatch them to handleEvents
      socket.addEventListener('message', ((socketId, fqdnString, { data }) => dispatch('handleEvents', {
        id: socketId,
        fqdn: fqdnString,
        events: data,
      })).bind(socket, id, fqdn));

      // Listen for close event on socket and set a timeout to reconnect
      socket.addEventListener('close', ((idString, fqdnString) => {
        setTimeout(() => {
          dispatch('connect', {
            id: idString,
            fqdn: fqdnString,
          });
        }, 1000);
      }).bind(null, id, fqdn));
    },
    /**
     * Close socket
     * @param {Object} obj
     * @param {Function} obj.commit
     * @param {String} id
     */
    close: ({ commit }, id) => {
      commit('closeSocket', id);
    },
    /**
     * Handle events from socket and dispatch to the right store
     * @param {Object} obj1
     * @param {Function} obj1.dispatch
     * @param {Object} obj2
     * @param {Array} obj2.events
     * @param {String} obj2.id
     * @param {String} obj2.fqdn
     * @param {Boolean} obj2.replay
     */
    handleEvents: ({ dispatch }, {
      events,
      id,
      fqdn,
      replay = false,
    }) => {
      if (!replay) {
        try {
          // eslint-disable-next-line
          events = JSON.parse(events);
        } catch (e) { /* Darn */ }
      }

      const chatEvents = events.filter((ev) => ev.event.includes('chat.'));
      if (chatEvents.length > 0) {
        dispatch('Chat/handleEvents', {
          id,
          events: chatEvents,
          replay,
        }, {
          root: true,
        });
      }
      const qaEvents = events.filter((ev) => ev.event.includes('qa.'));
      if (qaEvents.length > 0) {
        dispatch('QA/handleEvents', {
          id,
          fqdn,
          events: qaEvents,
        }, {
          root: true,
        });
      }
      const productEvents = events.filter((ev) => ev.event.includes('product.'));
      if (productEvents.length > 0) {
        dispatch('Products/handleEvents', {
          id,
          fqdn,
          events: productEvents,
        }, {
          root: true,
        });
      }
      const broadcastEvents = events.filter((ev) => ev.event.includes('broadcast.'));
      if (broadcastEvents.length > 0) {
        dispatch('Broadcast/handleEvents', {
          id,
          events: broadcastEvents,
        }, {
          root: true,
        });
      }
    },
    /**
     * Send event
     * @param {Object} obj1
     * @param {Object} obj1.state
     * @param {Object} obj1.state.socket
     * @param {Object} obj2
     * @param {Object} obj2.id
     * @param {Object} obj2.payload
     * @returns {Boolean}
     */
    sendEvent: async ({ state: { socket } }, { id, payload }) => {
      if (!socket[id] || !socket[id].socket || socket[id].socket.readyState !== 1) {
        return false;
      }
      await socket[id].socket.send(JSON.stringify(payload));
      return true;
    },
    /**
     * Replay of backlock
     * @param {Object} obj1
     * @param {Function} obj1.dispatch
     * @param {Object} obj2
     * @param {String} obj2.fqdn
     * @param {String} obj2.id
     * @returns {Boolean}
     */
    replay: async ({ dispatch }, { fqdn, id }) => {
      try {
        const events = await Events.getBacklog(fqdn, id);
        dispatch('handleEvents', {
          events,
          fqdn,
          id,
          replay: true,
        });
      } catch (e) {
        return false;
      }

      return true;
    },
  },
};
