import { formatDistanceToNowStrict, format } from 'date-fns';
import marked from 'marked';
import DOMPurify from 'dompurify';

const ourFont = '"Inter",sans-serif';

/**
 * Creates a HTTP request for URL with options and a optional callback
 * @param {String} url
 * @param {Object} defaultOptions
 * @param {Function} callback
 * @returns {Promise}
 */
function get(url, defaultOptions, callback) {
  function getHeaders(rawHeaders) {
    const headers = {};
    rawHeaders.trim().split(/[\r\n]+/).forEach((line) => {
      const parts = line.split(': ');
      const header = parts.shift();
      const value = parts.join(': ');
      headers[header.toLowerCase()] = value;
    });
    return headers;
  }

  const options = (typeof defaultOptions === 'undefined') ? {} : defaultOptions;

  return new Promise((resolve, reject) => {
    if (!url) {
      reject(new Error('No url'));
    }

    const xhr = new XMLHttpRequest();

    if (callback) {
      callback({ xhr });
    }

    xhr.open((options.method) ? options.method : 'GET', url);
    if (options.timeout) {
      xhr.timeout = options.timeout;
    }
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        const headers = getHeaders(xhr.getAllResponseHeaders());
        if (options.iLikeItRaw) {
          resolve({ data: xhr.response, headers });
        } else {
          try {
            const response = JSON.parse(xhr.response);
            if (typeof response === 'object') {
              resolve({ data: JSON.parse(xhr.response), headers, status: xhr.status });
            } else {
              reject(new Error('No an object'));
            }
          } catch (e) {
            reject(new Error(e));
          }
        }
      } else {
        try {
          // eslint-disable-next-line
          reject({ data: JSON.parse(xhr.response), status: xhr.status });
        } catch (e) {
          reject(new Error(xhr.statusText));
        }
      }
    };

    xhr.onerror = () => {
      reject(new Error(xhr.statusText));
    };

    xhr.ontimeout = () => {
      // eslint-disable-next-line
      reject({ error: true, data: { message: 'timeout' } });
    };

    let acceptHeader = 'application/vnd.streamingbolaget+json';
    let contentTypeHeader = 'application/json; charset=utf-8';

    if (options.headers) {
      Object.keys(options.headers).forEach((key) => {
        if (key === 'Accept') {
          acceptHeader = options.headers[key];
        } else if (key === 'Content-Type') {
          contentTypeHeader = options.headers[key];
        } else {
          xhr.setRequestHeader(key, options.headers[key]);
        }
      });
    }

    if (options.creds) {
      xhr.withCredentials = true;
    }

    if (!options.noExtraHeaders) {
      xhr.setRequestHeader('Accept', acceptHeader);
      if (typeof options.defaultContentType === 'undefined' || !options.defaultContentType) {
        xhr.setRequestHeader('Content-Type', contentTypeHeader);
      }
    }

    if (window.auth) {
      xhr.setRequestHeader('Authorization', `token ${window.auth.token}`);
    }

    if (options.body) {
      xhr.send(options.body);
    } else {
      xhr.send();
    }
  });
}

/**
 * Return a boolean if device is Android
 * @returns {Boolean}
 */
function isAndroid() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('android') > -1;
}

/**
 * Return a boolean if device is mobile
 * @returns {Boolean}
 */
function isMobile() {
  return /Mobi|Android/i.test(navigator.userAgent);
}

/**
 * Return a boolean if device is iOS
 * @returns {Boolean}
 */
function iOS() {
  return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
}

/**
 * Return a boolean if browser is safari
 * @returns {Boolean}
 */
function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}
/**
 * Return the hostname of URL
 * @param {String} url
 * @returns {String}
 */
function getHostname(url) {
  if (url === '') {
    return '';
  }
  return new URL(url).hostname;
}

/**
 * Add Video.js script to headers if not present already.
 */
function addVideoJS() {
  const hasVideoJS = !!document.querySelector(`script[src="${process.env.VUE_APP_CDN_URL}video.min.js"]`);
  if (!hasVideoJS) {
    const videoJSScript = document.createElement('script');
    videoJSScript.setAttribute('src', `${process.env.VUE_APP_CDN_URL}video.min.js`);
    document.head.appendChild(videoJSScript);
  }
}

function emitEvent(that, { event, data }) {
  const {
    id: productId,
    skuId: id,
    selectedVariant,
    // eslint-disable-next-line
    lineId,
    quantity,
  } = data;

  switch (event) {
    case 'cart.add':
      that.$emit('cart.add', {
        parentId: id || productId,
        sku: selectedVariant,
        quantity,
      }, ({
        success,
        fake = false,
        reloadIframe = false,
        minimizePlayer = false,
        reason = '',
        lineId: lineID,
      }) => {
        that.$store.dispatch('Cart/addProductToCart', {
          data,
          success,
          fake,
          reason,
          lineId: lineID,
        });

        if (reloadIframe) {
          that.$store.dispatch('LiveShopping/playerEvent', { event: 'iframe.reload' });
        }

        // Otherwise the event will be overwritten by the new one
        that.$nextTick(() => {
          if (minimizePlayer) {
            that.$store.dispatch('LiveShopping/playerEvent', { event: 'player.minimize' });
          }
        });
      });
      break;
    case 'cart.remove':
      that.$emit('cart.remove', {
        parentId: id || productId,
        sku: selectedVariant,
        lineId,
      }, ({
        success,
        reason,
      }) => {
        that.$store.dispatch('Cart/removeProductFromCart', {
          data,
          success,
          reason,
        });
      });
      break;
    case 'cart.update':
      that.$emit('cart.update', {
        parentId: id || productId,
        sku: selectedVariant,
        quantity,
        lineId,
      }, ({ success, reason }) => {
        this.$store.dispatch('Cart/updateProductInCart', {
          data,
          success,
          reason,
        });
      });
      break;
    case 'cart.checkout':
      that.$emit('cart.checkout');
      break;
    case 'product.update':
      for (let i = 0; i < data.products.length; i += 1) {
        const { sku, metadata: { external_product_id } } = data.products[i];

        that.$emit('productupdate', {
          id: external_product_id,
          sku: sku ? sku.map((s) => ({
            id: s.metadata.external_variant_id,
            price: s.metadata.price,
          })) : null,
        }, (updates) => {
          that.$store.dispatch('Products/updateProductPrice', {
            fqdn: data.fqdn,
            product: data.products[i].id,
            updates,
          });
        });
      }
      break;
    case 'player.open':
      that.$emit('player.open');
      break;
    case 'player.close':
      that.$emit('player.close');
      break;
    case 'player.minimize':
      that.$emit('player.minimize');
      break;
    default:
      break;
  }
}

/**
 * Add font links to header if not present already
 */
function addFont() {
  const hasAnotherFontFamily = document.querySelector('streamify-liveshopping') && getComputedStyle(document.querySelector('streamify-liveshopping')).getPropertyValue('--sf-main-font-family') !== ourFont;
  if (!hasAnotherFontFamily) {
    const hasPreconnect = !!document.querySelector('link[href="https://fonts.gstatic.com"]');
    const hasFont = !!document.querySelector('link[href="https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500&display=swap"]');
    if (!hasPreconnect) {
      const preconnectLink = document.createElement('link');
      preconnectLink.setAttribute('href', 'https://fonts.gstatic.com');
      preconnectLink.setAttribute('rel', 'preconnect');
      document.head.appendChild(preconnectLink);
    }
    if (!hasFont) {
      const fontLink = document.createElement('link');
      fontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500&display=swap');
      fontLink.setAttribute('rel', 'stylesheet');
      document.head.appendChild(fontLink);
    }
  }
  const hasFontCSS = !!document.querySelector(`link[href="${process.env.VUE_APP_CDN_URL}${process.env.VUE_APP_STAGING === 'true' ? 'staging-fonts.css' : 'fonts.css'}"]`);
  if (!hasFontCSS) {
    const fontCSS = document.createElement('link');
    fontCSS.setAttribute('href', `${process.env.VUE_APP_CDN_URL}${process.env.VUE_APP_STAGING === 'true' ? 'staging-fonts.css' : 'fonts.css'}`);
    fontCSS.setAttribute('rel', 'stylesheet');
    document.head.appendChild(fontCSS);
  }
}

/**
 * Classwatcher
 */
class ClassWatcher {
  constructor(targetNode, classToWatch, classAddedCallback, classRemovedCallback) {
    this.targetNode = targetNode;
    this.classToWatch = classToWatch;
    this.classAddedCallback = classAddedCallback;
    this.classRemovedCallback = classRemovedCallback;
    this.observer = null;
    this.lastClassState = targetNode.classList.contains(this.classToWatch);
    this.init();
  }

  /**
   * Initialize
   */
  init() {
    this.observer = new MutationObserver(this.mutationCallback);
    this.observe();
  }

  /**
   * Observe target
   */
  observe() {
    this.observer.observe(this.targetNode, { attributes: true });
  }

  /**
   * Disconnect observer
   */
  disconnect() {
    this.observer.disconnect();
  }

  /**
   * Callback when a target changes
   * @param {Array} mutationsList
   */
  mutationCallback = (mutationsList) => {
    // eslint-disable-next-line
    for(let mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        const currentClassState = mutation.target.classList.contains(this.classToWatch);
        if (this.lastClassState !== currentClassState) {
          this.lastClassState = currentClassState;
          if (currentClassState) {
            this.classAddedCallback();
          } else {
            this.classRemovedCallback();
          }
        }
      }
    }
  }
}

/**
 * Return a random number inside given range
 * @param {Number} min
 * @param {Number} max
 * @returns {Number}
 */
function randomNumberInRange(min, max) {
  return Math.random() * (max - min) + min;
}

/**
 * Format message that includes links and replace them with html-links
 * @param {String} message
 * @returns {String}
 */
function formatMessage(message) {
  if (!message) {
    return null;
  }
  return message.replace(/(((https?:\/\/)|(www\.))[^\s]+)/g, (url) => {
    let hyperlink = url;
    // eslint-disable-next-line
    if (!hyperlink.match('^https?:\/\/')) {
      hyperlink = `http://${hyperlink}`;
    }
    return `<a href="${hyperlink} target="_blank" rel="noopened noreferrer">${url}</a>`;
  });
}

/**
 * Get value from queryparameter
 * @param {String} param
 */
function getQueryParameterValue(param, url) {
  // eslint-disable-next-line
  const name = param.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  // eslint-disable-next-line
  const result = regex.exec(url || location.search);
  return result === null ? '' : decodeURIComponent(result[1].replace(/\+/g, ' '));
}

/**
 * Return the distance between two dates
 * @param {String} date
 * @param {Object} options
 * @returns {String}
 */
function distanceToNow(date, options) {
  return formatDistanceToNowStrict(date, options);
}

/**
 * Return a formated date
 * @param {String} date
 * @param {Object} formatStr
 * @returns {String}
 */
function formatDate(date, formatStr) {
  return format(date, formatStr);
}
/**
 * Truncate string
 * @param {String} str
 * @param {Number} num
 * @returns {String}
 */
function truncateString(str, num) {
  return str.length <= num ? str : str.slice(0, num);
}

/**
 * Purify text
 * @returns @String
 */
function purifyText(text = '') {
  return DOMPurify.sanitize(text, { ALLOWED_TAGS: [] });
}

/**
 * Parse and sanitize markdown
 * @param {String} text
 * @param {Number} max
 * @returns {String}
 */
function parseMarkdown(text, max) {
  let purified = purifyText(text);
  if (max) {
    purified = truncateString(purified, max);
  }
  return marked.parse(purified);
}

/**
 * Return boolean if the device is a touchdevice or not
 * @returns {Boolean}
 */
function isTouchDevice() {
  return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
function mergeObjectDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    // eslint-disable-next-line
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeObjectDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeObjectDeep(target, ...sources);
}

// eslint-disable-next-line
export {
  get,
  isAndroid,
  iOS,
  isSafari,
  addVideoJS,
  addFont,
  isMobile,
  ClassWatcher,
  randomNumberInRange,
  getHostname,
  formatMessage,
  getQueryParameterValue,
  distanceToNow,
  parseMarkdown,
  truncateString,
  isTouchDevice,
  formatDate,
  purifyText,
  emitEvent,
  mergeObjectDeep,
};
