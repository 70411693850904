// eslint-disable-next-line
import { Shopify } from '@/api/shopify';
import { get } from '@/utils';

const cartState = {
  items: [],
  show: false,
  checkout: false,
  cartEvent: null,
  cartUpdate: {
    id: '',
    success: false,
    reason: '',
    quantity: 0,
  },
};

async function productExternalEmit({
  event,
  id,
  variantId,
  quantity = 1,
}, callback) {
  const replaceStrings = {
    '%PRODUCTID%': id,
    '%VARIANTID%': variantId,
    '%QUANTITY%': quantity,
    '{PARENTID}': id,
    '{SKU}': variantId,
    '{QUANTITY}': quantity,
  };
  const url = event.url.replace(/{PARENTID}|{QUANTITY}|{SKU}|%PRODUCTID%|%VARIANTID%|%QUANTITY%/gi, (matched) => replaceStrings[matched]);

  const options = {
    method: event.method,
    iLikeItRaw: !event.returnsJSON,
  };

  if (event.type === 'json') {
    const payload = JSON.stringify(event.body).replace(/{PARENTID}|{QUANTITY}|{SKU}|%PRODUCTID%|%VARIANTID%|%QUANTITY%/gi, (matched) => replaceStrings[matched]);
    options.body = payload;
  }

  try {
    await get(url, options);
    callback({ success: true });
  } catch (e) {
    callback({ success: false });
  }
}

const cartMutations = {
  /**
   * Push item to cart
   * @param {Object} state
   * @param {String|Object} data
   */
  // eslint-disable-next-line
  addToCart(state, { id, skuId, lineId, selectedVariant }) {
    // Check if it exists first
    const index = state.items.findIndex((ci) => ci.id === id && ci.selectedVariant === selectedVariant);

    if (index >= 0) {
      state.items[index].quantity += 1;
    } else {
      state.items.push({
        id,
        skuId,
        selectedVariant,
        lineId,
        quantity: 1,
      });
    }
  },
  /**
   * Remove item from cart
   */
  removeFromCart(state, index) {
    state.items.splice(index, 1);

    if (state.items.length === 0) {
      state.show = false;
    }
  },
  updateQuantity(state, { index, quantity }) {
    state.items[index].quantity += quantity;
  },
  /**
   * Lower quantity
   */
  lowerQuantity(state, index) {
    if (state.items[index].quantity - 1 === 0) {
      this.items.splice(index, 1);
    } else {
      this.items[index].quantity -= 1;
    }
  },
  /**
   * Toggle cart state
   * @param {Object} state
   * @param {Boolean} bool
   */
  toggleCart(state, bool) {
    state.show = bool;
  },
  resetCart(state) {
    state.items = [];
  },
  checkoutEvent(state, bool) {
    state.checkout = bool;

    setTimeout(() => {
      state.checkout = false;
    }, 1000);
  },
  updateCartItem(state, {
    id = '',
    state: productState = '',
    reason = '',
    lineId = '',
    quantity = 0,
  }) {
    state.cartUpdate = {
      id,
      state: productState,
      lineId,
      reason,
      quantity,
    };
  },
};

const cartGetters = {
  /**
   * Return the number of items in store
   * @param {Object} state
   * @returns {Number}
   */
  numItems: (state) => state.items.reduce((comb, item) => comb + item.quantity, 0),
  show: (state) => state.show,
  items: (state) => state.items,
  checkoutEvent: (state) => state.checkout,
  cartUpdate: (state) => state.cartUpdate,
};

const cartActions = {
  resetCart: ({ commit }) => {
    commit('resetCart');
  },
  // eslint-disable-next-line
  refreshCart: async ({ commit }, broadcast) => {
    if (window.Shopify) {
      try {
        // eslint-disable-next-line
        const cart = await Shopify.refreshCart();
      } catch (e) { /* Do nothing */ }
    }
  },
  /**
   * Add item to cart
   * @param {Object} param0
   * @param {Function} param0.commit
   * @param {Object/String} data
   */
  addToCart: async ({
    commit,
    dispatch,
    state,
    rootGetters,
  }, { broadcastId, data }) => {
    const {
      urls: {
        product_add_to_cart = '',
      },
      events,
    } = rootGetters['LiveShopping/config'];

    // eslint-disable-next-line
    const index = state.items.findIndex((i) => i.id === data.id && i.selectedVariant === data.selectedVariant);
    // There is an item with this id already. Lets bump up the quantity with an update instead
    if (index >= 0 && events.indexOf('cart.update') >= 0) {
      dispatch('updateQuantity', { index, quantity: 1, data });
      return;
    }

    if (data !== null) {
      if (!data.url) {
        commit('updateCartItem', {
          id: data.id,
          state: 'adding',
        });
      }

      if (window.Shopify) {
        try {
          await Shopify.addToCart(data.selectedVariant);
          dispatch('addProductToCart', {
            data,
            success: true,
          });
        } catch (e) {
          dispatch('addProductToCart', {
            data,
            success: false,
            reason: e.data.description,
          });
        }
        dispatch('clearAddToCart');
        return;
      }
    }

    if (product_add_to_cart) {
      productExternalEmit({
        event: product_add_to_cart,
        id: data.skuId,
        variantId: data.selectedVariant,
        quantity: 1,
      }, ({ success }) => {
        dispatch('addProductToCart', {
          data,
          success,
        });
      });
      return;
    }

    dispatch('LiveShopping/event', {
      broadcastId,
      event: 'cart.add',
      data: {
        ...data,
        quantity: 1,
      },
    }, { root: true });
  },
  addProductToCart: async ({
    commit,
    dispatch,
  // eslint-disable-next-line
  }, { data, success, fake = false, reason, lineId }) => {
    if (!success) {
      commit('updateCartItem', {
        id: data.id,
        lineId,
        state: 'failed',
        reason,
      });

      dispatch('clearAddToCart');
      return;
    }

    if (!fake) {
      commit('addToCart', {
        lineId,
        ...data,
      });
    }
    commit('updateCartItem', {
      id: data.id,
      state: 'success',
    });
    dispatch('clearAddToCart');
  },
  clearAddToCart: ({ commit }) => {
    setTimeout(() => {
      commit('updateCartItem', {});
    }, 2000);
  },
  goToCheckout: ({ commit }) => {
    commit('checkoutEvent', true);
  },
  removeFromCart: async ({
    commit,
    dispatch,
    state,
    rootGetters,
  }, index) => {
    commit('updateCartItem', {
      id: state.items[index].id,
      state: 'remove',
    });
    if (window.Shopify) {
      try {
        await Shopify.updateQuantity(state.items[index].selectedVariant, 0);
        commit('removeFromCart', index);
        commit('updateCartItem', {});
      } catch (e) {
        commit('updateCartItem', {
          id: state.items[index].id,
          state: 'failed',
        });
        return;
      }
      return;
    }

    const {
      events = [],
      urls: {
        product_remove_from_cart = '',
      },
    } = rootGetters['LiveShopping/config'];
    if (product_remove_from_cart) {
      // productExternalEmit(product_remove_from_cart, state.items[index].selectedVariant);
      productExternalEmit({
        event: product_remove_from_cart,
        id: state.items[index].selectedVariant,
      }, ({ success }) => {
        dispatch('removeProductFromCart', {
          data: state.items[index],
          success,
        });
      });
    }

    if (events.indexOf('cart.remove') >= 0) {
      dispatch('Products/productEvent', { ...state.items[index], remove: true }, { root: true });
    }
  },
  removeProductFromCart: ({
    commit, dispatch, state,
  }, { data, success, reason }) => {
    // eslint-disable-next-line
    const index = state.items.findIndex((i) => i.id === data.id && i.selectedVariant === data.selectedVariant);
    if (!success) {
      commit('updateCartItem', {
        id: data.id,
        state: 'failed',
        reason,
      });
      dispatch('clearAddToCart');
      return;
    }
    commit('removeFromCart', index);
    commit('updateCartItem', {});
  },
  updateQuantity: async ({
    commit,
    state,
    dispatch,
    rootGetters,
  }, { index, quantity }) => {
    if (state.items[index].quantity + quantity === 0) {
      // Holey moley, this was the last of the quantum solace. Lets remove it instead.
      dispatch('removeFromCart', index);
      return;
    }
    commit('updateCartItem', {
      id: state.items[index].id,
      state: 'update',
      // quantity,
    });
    if (window.Shopify) {
      try {
        await Shopify.updateQuantity(state.items[index].selectedVariant, state.items[index].quantity + quantity);
        commit('updateQuantity', { index, quantity });
        commit('updateCartItem', {
          id: state.items[index].id,
          state: 'success',
        });
        dispatch('clearAddToCart');
      } catch (e) {
        commit('updateCartItem', {
          id: state.items[index].id,
          state: 'failed',
        });
        return;
      }
      return;
    }

    const {
      events = [],
      urls: {
        product_update_quantity = '',
      },
    } = rootGetters['LiveShopping/config'];
    if (product_update_quantity) {
      productExternalEmit({
        event: product_update_quantity,
        id: state.items[index].selectedVariant,
        quantity: state.items[index].quantity + quantity,
      }, ({ success }) => {
        dispatch('updateProductInCart', {
          data: state.items[index],
          success,
        });
      });
    }

    if (events.indexOf('cart.update') >= 0) {
      const sendData = state.items[index];
      // eslint-disable-next-line
      sendData.quantity = sendData.quantity + quantity;
      dispatch('Products/productEvent', { ...state.items[index], update: true }, { root: true });
    }
  },
  updateProductInCart: ({
    commit, dispatch, state, getters,
  }, { data, success, reason }) => {
    // eslint-disable-next-line
    const index = state.items.findIndex((i) => i.id === data.id && i.selectedVariant === data.selectedVariant);

    const lol = getters.cartUpdate;

    if (!success) {
      commit('updateCartItem', {
        id: data.id,
        state: 'failed',
        reason,
      });
      dispatch('clearAddToCart');
      return;
    }
    commit('updateQuantity', { index, quantity: lol.quantity });
    commit('updateCartItem', {
      id: data.id,
      state: 'success',
    });
    dispatch('clearAddToCart');
  },
  /**
   * Toggle cart
   * @param {Object} param0
   * @param {Function} param0.commit
   * @param {Boolean} bool
   */
  toggleCart: ({ commit }, bool) => {
    commit('toggleCart', bool);
  },
};

export default {
  namespaced: true,
  state: cartState,
  mutations: cartMutations,
  getters: cartGetters,
  actions: cartActions,
};
