<template>
  <div class="hadirajan" ref="hadirajan">
    <transition-group name="hadiraj" mode="out-in" tag="div" @after-enter="afterLeave">
      <div class="hadiraj" v-for="hadiraj in hadirajas" :data-key="hadiraj.message_id" :key="hadiraj.message_id" :style="`left: ${hadiraj.left}%`">{{ emoji }}</div>
    </transition-group>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    emoji() {
      return this.$store.getters['LiveShopping/config'].emojis[0];
    },
    /**
     * Return broadcast that matches ID
     * @returns {Object}
     */
    item() {
      return this.$store.getters['Broadcast/get'](this.id);
    },
    /**
     * Return the current time from VideoPlayer
     * @returns {Number}
     */
    videoTime() {
      return this.$store.getters['Player/videoCurrentTime'](this.id);
    },
    /**
     * Return a boolean if its a replay or not..
     * @returns {Boolean}
     */
    isReplay() {
      const { item: { data: { life_cycle_status } } } = this;
      return life_cycle_status === 'complete';
    },
    /**
     * Returns all the hadirajas in chat
     * @returns {Array}
     */
    hadiraja() {
      return this.$store.getters['Chat/reactions'](this.id);
    },
  },
  watch: {
    hadiraja(arr) {
      if (this.isReplay) {
        return;
      }
      this.consumeHadiraj(arr[arr.length - 1]);
    },
    videoTime(a, b) {
      if (!this.isReplay) {
        return;
      }

      let diff = 0;
      if (a > b) {
        diff = a - b > 2 ? 2 : a - b;
      }

      const { item: { data: { actual_start_time } } } = this;

      const start = new Date(actual_start_time);
      const end = new Date(actual_start_time);
      start.setSeconds(start.getSeconds() + a - 1);
      end.setSeconds(end.getSeconds() + a + diff + 1);

      const hadirajas = this.hadiraja.filter((h) => new Date(h.timestamp).getTime() > start.getTime() && new Date(h.timestamp).getTime() < end.getTime());
      hadirajas.forEach((h) => {
        this.consumeHadiraj(h);
      });
    },
  },
  methods: {
    afterLeave(el) {
      const { key } = el.dataset;
      const index = this.hadirajas.findIndex((hadiraj) => hadiraj.message_id === key);
      if (index >= 0) {
        this.hadirajas.splice(index, 1);
      }
    },
    consumeItDamnit(hadiraj, index, total) {
      const totalChildSupportBecauseOfHadirajan = this.$refs.hadirajan.querySelector('div').childElementCount;
      if (totalChildSupportBecauseOfHadirajan <= 50) {
        const i = this.hadirajas.findIndex((h) => h.message_id === `${hadiraj.message_id}-${index}`);
        if (i < 0) {
          this.hadirajas.push({
            ...hadiraj,
            message_id: `${hadiraj.message_id}-${index}`,
            left: this.getRandomInt(0, 85),
          });
        }
      }
      if (index + 1 < total) {
        const rand = this.getRandomInt(0, 20) * this.getRandomInt(20, 100);
        setTimeout(() => {
          this.consumeItDamnit(hadiraj, index + 1, total);
        }, rand);
      }
    },
    consumeHadiraj(hadiraj) {
      if (!hadiraj) {
        return;
      }

      if (this.pauseHadirajas) {
        return;
      }

      // We dont want to overload everything..
      if (!this.isReplay) {
        const currentDate = new Date();
        const hadirajaDate = new Date(hadiraj.timestamp);
        const diff = currentDate.getSeconds() - hadirajaDate.getSeconds();

        if (diff > -5 && diff < 5) {
          this.consumeItDamnit(hadiraj, 0, hadiraj.total);
        }
      } else {
        this.consumeItDamnit(hadiraj, 0, hadiraj.total);
      }
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    visibilityChange() {
      this.pauseHadirajas = document.visibilityState === 'hidden';
    },
  },
  data() {
    return {
      hadirajas: [],
      pauseHadirajas: false,
    };
  },
  mounted() {
    try {
      document.addEventListener('visibilitychange', this.visibilityChange, false);
    } catch (e) { /* Do nothing */ }
  },
  beforeDestroy() {
    try {
      document.removeEventListener('visibilitychange', this.visibilityChange, false);
    } catch (e) { /* Do nothing */ }
  },
};
</script>

<style lang="scss" scoped>
.hadirajan {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;

  .hadiraj {
    position: absolute;
    bottom: -10%;
    opacity: 1;
    font-size: 32px;

    &.hadiraj-enter-active {
      transition: bottom 5s, opacity 5s;
    }

    &.hadiraj-enter-to {
      bottom: 50%;
      opacity: 0;
    }
  }
}
</style>
