<template>
  <div :class="`product ${selectedProduct === id ? 'product--selected' : ''}`" @click="selectProduct()">
    <IconButton icon="close-x" @click.native.stop="unselectProduct()" class="fixed-small product-close" />
    <div class="list">
      <div class="image" :style="images.length > 0 ? `background-image: url('${images[0].includes('http') ? images[0] : `https://${item.data.fqdn}/images/h/90/${images[0]}`}');` : ''"></div>
      <div class="info">
        <h3>{{ product.name }}</h3>
        <p>{{ listPriceString }}</p>
      </div>
    </div>
    <div class="data">
      <div class="images">
        <img v-for="(image, index) in images" :key="index" :class="`img ${imageGalleryIndex === index ? 'img--active' : ''}`" :src="`${image.includes('http') ? image : `https://${item.data.fqdn}/images/h/375/${image}`}`" />
        <div class="dots" v-if="images.length > 1">
          <div v-for="(image, index) in images" :key="`dot-${index}`" @click="imageGalleryIndex = index" :class="`dot ${imageGalleryIndex === index ? 'active' : ''}`"></div>
        </div>
      </div>
      <div class="info">
        <h3>{{ product.name }}</h3>
        <p v-html="selectedSKU ? dataPriceString : listPriceString"></p>
      </div>
      <div :class="`description ${overflown ? 'overflown' : ''} ${product.description.length > 140 ? 'overflow' : ''}`" v-if="product.description">
        <p v-html="product.description"></p>
        <span class="read-more" v-if="product.description.length > 140" @click="overflown = !overflown">{{ overflown ? config.strings.en.read_less || 'Read less' : config.strings.en.read_more || 'Read more' }}</span>
      </div>
      <div class="selectors" v-if="product.sku && product.sku.length > 1">
        <div v-for="(selector, index) in attributeSelectors.filter((b) => b.items.length > 1)" :key="`vs-${index}`" class="row">
          <p>{{ selector.name.replace('_', ' ') }}</p>
          <Dropdown :items="selector.items" @input="selectAttribute(selector.name, $event.value)" :disabled="selector.disabled" />
        </div>
      </div>
      <div class="error" v-if="cartEvent && cartEvent.state === 'failed'">
        {{ printReason(cartEvent.reason) }}
      </div>
      <div class="buttons">
        <Button class="button--primary" :disabled="(!selectedSKU && product.sku !== null)" @click.native.stop="productClick()">
          <template v-if="handleProduct === 'external' || handleProduct === 'iframe'">
            {{ config.strings.en.go_to_product || 'Go to product' }}
          </template>
          <template v-else>
            <template v-if="cartEvent && (cartEvent.state === 'adding' || cartEvent.state === 'update')">
              <div class="spinner small"></div>
            </template>
            <template v-else>
              <template v-if="cartEvent && cartEvent.state === 'success'">
                {{ config.strings.en.added_to_cart || 'Added' }}
              </template>
              <template v-else>
                {{ config.strings.en.add_to_cart || 'Add to cart' }}
              </template>
            </template>
          </template>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>

import Button from '@/components/Buttons/Button.vue';
import IconButton from '@/components/Buttons/Icon.vue';
import Dropdown from '@/components/Form/Dropdown.vue';

import { getHostname } from '@/utils';

export default {
  components: {
    Button,
    IconButton,
    Dropdown,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    broadcast: {
      type: String,
      required: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dataPriceString() {
      const {
        selectedSKU,
        listPriceString,
      } = this;

      if (!selectedSKU) {
        return listPriceString;
      }

      return `${selectedSKU.metadata.price}${selectedSKU.metadata.originalPrice ? ` <s>${selectedSKU.metadata.originalPrice}</s>` : ''}`;
    },
    listPriceString() {
      const {
        product: {
          sku,
          price,
        },
      } = this;

      if (sku) {
        const prices = sku.map((s) => ({ parsed: parseInt(s.metadata.price.replace(' ', ''), 10), orig: s.metadata.price })).sort((a, b) => a.parsed - b.parsed);
        const pricesCount = new Set(prices.map((p) => (p.parsed))).size;

        return `${pricesCount > 1 ? 'From: ' : ''} ${prices[0].orig}`;
      }

      if (!price) {
        return '';
      }

      if (price[0].amount === '0') {
        return '';
      }

      return price[0].formated_amount;
    },
    /**
     * Get the latest event from cart
     * @returns {?Object}
     */
    cartEvent() {
      const event = this.$store.getters['Cart/cartUpdate'];
      if (event.id === '') {
        return null;
      }

      return event.id === this.id ? event : null;
    },
    /**
     * Return the config for liveshopping
     * @returns {Object}
     */
    config() {
      return this.$store.getters['LiveShopping/config'];
    },
    /**
     * Return the product from goodlist that matches the id
     * @returns {Object}
     */
    product() {
      return this.$store.getters['Products/product'](this.item.data.fqdn, this.id);
    },
    /**
     * Returns the information about when the product was added to the stream
     * @returns {Object}
     */
    streamProduct() {
      return this.$store.getters['Products/streamProducts'](this.broadcast).find((sp) => sp.id === this.id) || {};
    },
    /**
     * Get the selected product ID from store
     * @returns {?String}
     */
    selectedProduct() {
      return this.$store.getters['Products/selectedProduct'];
    },
    /**
     * Return broadcast or video item with supplied ID
     * @returns {Object}
     */
    item() {
      return this.$store.getters['Broadcast/get'](this.broadcast);
    },
    /**
     * Get the products all images
     * @returns {Array}
     */
    images() {
      const {
        product: {
          images,
          sku: skus,
        },
      } = this;

      return !skus ? images : [...new Set(skus.filter((sku) => sku.metadata.image).map((sku) => sku.metadata.image))];
    },
    /**
     * Return all selectors for this product
     * @returns {?Array}
     */
    attributeSelectors() {
      const {
        attributes,
        selectedAttributes,
        product: {
          attributes: sortedAttributes,
          sku,
        },
        // eslint-disable-next-line
        selectedAttributesKey,
      } = this;
      const sortAtts = sortedAttributes && sortedAttributes.length > 0 ? sortedAttributes : Object.keys(attributes);
      const returnData = sortAtts.map((key, index) => {
        const primaryIndex = index === 0;

        if (primaryIndex) {
          return {
            name: key,
            disabled: false,
            primary: true,
            items: attributes[key].map((i) => ({
              value: i,
              string: i,
              selected: i === selectedAttributes[key],
            })),
          };
        }

        return {
          name: key,
          disabled: !selectedAttributes[sortAtts[index - 1]] || selectedAttributes[sortAtts[index - 1]] === '',
          items: attributes[key].map((i) => ({
            value: i,
            string: i,
            selected: i === selectedAttributes[key],
            disabled: !sku.find((s) => {
              let notDisabled = true;

              for (let fi = 0; fi < index; fi += 1) {
                const statement = s.attributes[sortAtts[fi]] === selectedAttributes[sortAtts[fi]] && s.attributes[key] === i;
                if (!statement) {
                  notDisabled = false;
                }
              }

              return notDisabled;
            }),
          })),
        };
      });

      return returnData;
    },
    /**
     * Return all attributes available on this product
     * @returns {Array}
     */
    attributes() {
      const {
        product: {
          sku,
        },
      } = this;

      if (!sku) {
        return [];
      }

      const attributes = {};

      sku.forEach((s) => {
        Object.keys(s.attributes).forEach((key) => {
          if (!Object.keys(attributes).includes(key)) {
            attributes[key] = [];
          }

          if (!attributes[key].includes(s.attributes[key])) {
            attributes[key].push(s.attributes[key]);
          }
        });
      });

      return attributes;
    },
    /**
     * Return a string if the product should open in a new window, iframe or handle it internally
     * @returns {String}
     */
    handleProduct() {
      const {
        config: {
          domains,
          events,
          urls: {
            product_add_to_cart,
          },
        },
        persistent,
        product: {
          metadata: {
            external_url = '',
          },
        },
      } = this;

      // Get the hostname for both the product url and current domain
      // eslint-disable-next-line
      const productHostname = getHostname(external_url);
      const currentHostname = getHostname(window.location.href);

      if (external_url !== '') {
        if (!domains.includes(currentHostname) && productHostname !== currentHostname) {
          return 'external';
        }
      }

      if (!!product_add_to_cart === false && events.indexOf('cart.add') === -1 && !!window.Shopify === false && persistent) {
        return 'iframe';
      }

      if (!!product_add_to_cart === false && events.indexOf('cart.add') === -1 && !!window.Shopify === false) {
        return 'external';
      }

      if (window.Shopify && productHostname !== currentHostname) {
        return 'external';
      }

      return 'internal';
    },
  },
  methods: {
    /**
     * Print error reason
     * @param {String} reason
     * @returns {String}
     */
    printReason(reason) {
      const {
        config: {
          strings: {
            en: strings,
          },
        },
      } = this;

      switch (reason) {
        case 'out-of-stock':
          return strings[reason] || 'Product is out of stock';
        default:
          break;
      }

      if (reason !== '') {
        return reason;
      }

      return strings.could_not_add || 'Product could not be added to cart';
    },
    /**
     * Select an attribute for the product
     * @param {String} attribute
     * @param {String} value
     */
    selectAttribute(attribute, value) {
      const {
        product: {
          sku,
        },
      } = this;

      const index = this.attributeSelectors.findIndex((attr) => attr.name === attribute);

      this.selectedAttributes[attribute] = value;
      this.selectedAttributesKey += 1;

      const firstHit = sku.find((s) => s.attributes[this.attributeSelectors[0].name] === this.selectedAttributes[this.attributeSelectors[0].name]);
      this.imageGalleryIndex = this.images.findIndex((i) => i === firstHit.metadata.image);
      this.selectedSKU = sku.find((s) => Object.keys(this.selectedAttributes).every((key) => Object.keys(s.attributes).includes(key) && s.attributes[key] === this.selectedAttributes[key]));

      if (!this.selectedSKU) {
        // Now be need to find out which attribute is the crazy link.. or many?
        for (let i = 0; i < this.attributeSelectors.length; i += 1) {
          if (i !== index) {
            const wrongIndex = this.attributeSelectors[i].items.findIndex((asi) => asi.selected && asi.disabled);
            if (wrongIndex >= 0) {
              if (this.attributes[this.attributeSelectors[i].name].length > 1) {
                this.selectedAttributes[this.attributeSelectors[i].name] = '';
                this.selectedAttributesKey += 1;
              }
            }
          }
        }
      }
    },
    /**
     * Select product
     */
    async selectProduct() {
      const {
        selectedProduct,
        id,
        item: {
          data: {
            fqdn,
          },
        },
      } = this;

      if (selectedProduct === id) {
        return;
      }

      // Lets get the freshes data for this product
      await this.$store.dispatch('Products/getProduct', { fqdn, product: id });
      this.$store.dispatch('Products/selectProduct', { product: id, fqdn, type: 'good' });
    },
    unselectProduct() {
      const {
        item: {
          data: {
            fqdn,
          },
        },
      } = this;

      this.$store.dispatch('Products/selectProduct', { product: null, fqdn, type: 'good' });
    },
    /**
     * Check the SKUS and add attributes that only have 1 choice
     */
    checkSKUS() {
      const {
        attributes,
        product: {
          sku,
        },
      } = this;

      if (!sku) {
        return;
      }

      Object.keys(sku[0].attributes).forEach((attribute) => {
        this.selectedAttributes[attribute] = attributes[attribute].length === 1 ? sku[0].attributes[attribute] : '';
      });

      this.selectedAttributesKey += 1;

      if (sku.length === 1) {
        // eslint-disable-next-line
        this.selectedSKU = sku[0];
      }
    },
    scrollTo() {},
    async productClick() {
      const {
        handleProduct,
        item: {
          data: {
            id: broadcastId,
            fqdn,
            scheduled_start_time,
          },
        },
        product: {
          metadata: {
            external_url,
            external_product_id,
          },
        },
        id,
        selectedSKU,
      } = this;

      this.$store.dispatch('Analytics/event', {
        id: scheduled_start_time ? '0' : broadcastId,
        broadcast_id: scheduled_start_time ? broadcastId : '',
        account: fqdn.split('.')[0],
        event: 'add_to_cart',
        data: {
          product: id,
        },
      });

      if (handleProduct === 'external') {
        window.open(external_url, '_blank');
        return;
      }

      if (handleProduct === 'iframe') {
        this.$store.dispatch('LiveShopping/playerEvent', {
          event: 'iframe.src',
          src: external_url,
        });

        this.$nextTick(() => {
          this.$store.dispatch('LiveShopping/playerEvent', {
            event: 'player.minimize',
          });
        });
        return;
      }

      await this.$store.dispatch('Cart/addToCart', {
        broadcastId,
        data: {
          id,
          skuId: selectedSKU ? selectedSKU.id : external_product_id,
          selectedVariant: selectedSKU ? selectedSKU.metadata.external_variant_id : external_product_id,
        },
      });
    },
  },
  watch: {
    selectedProduct: {
      immediate: true,
      handler(id) {
        if (id === this.id) {
          // this.checkSKUS();
          this.$nextTick(() => {
            this.scrollTo();
          });
        }
      },
    },
  },
  data() {
    return {
      imageGalleryIndex: 0,
      selectedAttributes: {},
      selectedAttributesKey: 0,
      selectedSKU: null,
      overflown: false,
    };
  },
  mounted() {
    this.checkSKUS();
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.product {
  color: var(--sf-main-box-color);
  background: var(--sf-main-box-background);
  box-shadow: var(--sf-main-box-box-shadow);
  border-radius: var(--sf-main-box-border-radius);
  position: relative;

  .product-close {
    display: none;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1;
  }

  &.product--selected {
    .list {
      display: none;
    }

    .product-close {
      display: flex;
    }

    .data {
      display: block;
    }
  }
}

.list {
  align-items: center;
  display: flex;
  .image {
    background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      border-radius: calc(var(--sf-main-box-border-radius) - 3px) 0 0 calc(var(--sf-main-box-border-radius) - 3px);
      min-height: 80px;
      min-width: 80px;
      width: 80px;
      margin: 5px 0 5px 5px;
  }
  .info {
    margin: 0 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    h3 {
      font-size: 16px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      letter-spacing: var(--sf-product-letter-spacing);
      font-weight: var(--sf-product-weight);
      font-family: var(--sf-product-font-family);
      text-transform: var(--sf-product-text-transform);

      @media (min-width: 768px) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    p {
      align-items: center;
      display: flex;
      font-size: 14px;
      line-height: 24px;
      color: var(--sf-product-price-color);
      letter-spacing: var(--sf-product-letter-spacing);
      font-weight: var(--sf-product-weight);
      font-family: var(--sf-product-font-family);
      text-transform: uppercase;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.data {
  display: none;

  .images {
    position: relative;
    padding: 10px 10px 0;
    .image {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 50% 50%;
      border-radius: var(--sf-main-box-border-radius) var(--sf-main-box-border-radius) 0 0;
      display: none;
      padding-top: 80%;
      width: 90%;
      margin: 0 auto;

      &.image--active {
        display: block;
      }
    }

    .img {
      display: none;
      max-height: 200px;
      width: 100%;
      object-fit: contain;

      &.img--active {
        display: block;
      }
    }

    .dots {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 12px 0;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      .dot {
        border: 2px solid #ADAEAF;
        border-radius: 100%;
        width: 10px;
        height: 10px;
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          cursor: pointer;
        }
        &.active {
          background-color: black;
          border: 1px solid black;
          height: 12px;
          width: 12px;
          margin-right: 10px;

          &:last-child {
            margin-right: 0;
            margin-left: -2px;
          }
        }
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 20px 20px 10px;

    h3 {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: var(--sf-product-letter-spacing);
      font-weight: var(--sf-product-weight);
      font-family: var(--sf-product-font-family);
      text-transform: var(--sf-product-text-transform);

      @media (min-width: 768px) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    p {
      color: var(--sf-product-price-color);
      letter-spacing: var(--sf-product-letter-spacing);
      font-weight: var(--sf-product-weight);
      font-family: var(--sf-product-font-family);
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }

  .description {
    text-align: center;
    margin-top: 5px;
    white-space: normal;
    font-size: 14px;
    line-height: 24px;
    padding: 0 20px;

    .read-more {
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
    }

    &.overflow {
      p {
        max-height: 45px;
        overflow: hidden;
        mask-image: linear-gradient(to top, transparent 0%, black 50%);
        transition: max-height .5s;
      }
    }

    &.overflown {
      p {
        max-height: 1000px;
        mask-image: none;
      }
    }
  }

  .selectors {
    padding: 10px 20px 10px;

    p {
      font-size: var(--sf-title-size);
      color: var(--sf-title-color);
      text-transform: uppercase;
      font-weight: var(--sf-title-weight);
      text-align: center;
      width: 100%;
    }

    .row {
      align-items: center;
      display: flex;

      & > *:first-child {
        flex: .75;
      }
      & > *:last-child {
        flex: 1.25;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .error {
    color: #C11B1B;
    padding: 10px 20px 20px;
    text-align: center;
    font-size: .8rem;
  }

  .buttons {
    padding: 10px 20px 20px;

    & > * {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
