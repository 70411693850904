import { get } from '../utils';

const Products = {
  getList: async (fqdn, type) => {
    // const response = await get(`https://embed.streamify.io/play/products/${type}?fqdn=${fqdn}`).catch(() => false);
    const response = await get(`https://${fqdn}/play/products/${type}`).catch(() => false);
    if (!response) {
      throw new Error(`Could not fetch list of products with type: ${type}`);
    }

    return response.data;
  },
  getProduct: async (fqdn, id) => {
    // const response = await get(`https://embed.streamify.io/play/product/${id}?fqdn=${fqdn}`).catch(() => false);
    const response = await get(`https://${fqdn}/play/product/${id}`).catch(() => false);
    if (!response) {
      throw new Error(`Could not fetch product with id: ${id}`);
    }

    return response.data;
  },
};

// eslint-disable-next-line
export { Products };
