import Vue from 'vue';

// const MAX_CHAT_BACKLOG = 1000;

export default {
  namespaced: true,

  // Chat state
  state: {
    user: {
      nickname: '',
      timestamp: '',
      state: '',
    },
    chat: {},
    sessions: {},
    showChat: true,
    showChatInput: true,
  },

  // Chat getters
  getters: {
    /**
     * Return the current nickname
     * @param {Object} state
     * @returns {String}
     */
    nickname: (state) => state.user.nickname,
    /**
     * Return state of nickname
     * @param {Object} state
     * @returns {String}
     */
    nicknameState: (state) => state.user.state,
    /**
     * Return the current {sessionId} for broadcast with {broadcastId}
     * @param {Object} state
     * @param {String} broadcastId
     * @returns {String}
     */
    sessionId: (state) => (broadcastId) => (state.sessions[broadcastId] ? state.sessions[broadcastId].sessionId : null),
    /**
     * Return the current {userId} for broadcast with {broadcastId}
     * @param {Object} state
     * @param {String} broadcastId
     * @returns {String}
     */
    userId: (state) => (broadcastId) => (state.sessions[broadcastId] ? state.sessions[broadcastId].userId : null),
    /**
     * Return bool of the chat should be shown
     * @param {Object} state
     * @returns {Boolean}
     */
    showChat: (state) => state.showChat,
    /**
     * Return bool if the chat input should be shown
     * @param {Objext} state
     * @returns {Boolean}
     */
    showChatInput: (state) => state.showChatInput,
    /**
     * Return sorted array of messages for chat with {id}
     * @param {Object} state
     * @param {String} id
     * @returns {Array}
     */
    messages: (state) => (id) => (state.chat[id] ? state.chat[id].messages : []).sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)),
    /**
     * Return sorted array of reactions for chat with {id}
     * @param {Object} state
     * @param {String} id
     * @returns {Array}
     */
    reactions: (state) => (id) => (state.chat[id] ? state.chat[id].reactions : []).sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)),
  },

  // Mutations for chat
  mutations: {
    /**
     * Toggle chat
     * @param {Object} state
     * @param {Boolean} bool
     */
    toggleChat(state, bool) {
      state.showChat = bool;
    },
    /**
     * Toggle chat input
     * @param {Object} state
     * @param {Boolean} bool
     */
    toggleChatInput(state, bool) {
      state.showChatInput = bool;
    },
    /**
     * Set user nickname in store
     * @param {Object} state
     * @param {String} nickname
     */
    setUserNickname(state, nickname) {
      state.user = {
        nickname,
        timestamp: new Date(),
        state: '',
      };
    },
    /**
     * Set user nickname state
     * @param {Object} state
     * @param {String} nicknameState
     */
    setUserNicknameState(state, nicknameState) {
      state.user.state = nicknameState;
    },
    /**
     * Create a empty chat in state with {id}
     * @param {Object} state
     * @param {String} id
     */
    createChat(state, id) {
      if (!state.chat[id]) {
        Vue.set(state.chat, id, {
          messages: [],
          reactions: [],
          hasMessages: false,
          pinned: '',
          welcome: '',
        });
      }
    },
    /**
     * Remove a chat with {id} from state
     * @param {Object} state
     * @param {String} id
     */
    removeChat(state, id) {
      delete state.chat[id];
    },
    /**
     * Add {reactions} to chat with {id}
     * @param {Object} state
     * @param {Object} obj
     * @param {String} obj.id
     * @param {Array} obj.reactions
     */
    addReactions(state, { id, reactions }) {
      const currentReactions = state.chat[id].reactions.slice(0);
      currentReactions.push(...reactions);
      state.chat[id].reactions = reactions;
    },
    /**
     * Add {messages} to chat with {id}
     * If {clear} is true, remove old messages first
     * @param {Object} state
     * @param {Object} obj
     * @param {String} obj.id
     * @param {Array} obj.messages
     * @param {Boolean} obj.clear
     */
    addMessages(state, { id, messages, clear = false }) {
      const newMessagesArr = clear ? messages : state.chat[id].messages;

      if (!clear) {
        newMessagesArr.push(...messages);
      }
      // We only want a certain amount in the backlog
      state.chat[id].messages = newMessagesArr; // .slice(Math.max(newMessagesArr.length - MAX_CHAT_BACKLOG, 0));
    },
    /**
     * Removes a message with {messageId} in {chatId}
     * @param {Object} state
     * @param {Object} obj
     * @param {String} obj.chatId
     * @param {String} obj.messageId
     */
    removeMessage(state, { chatId, messageId }) {
      if (!state.chat[chatId]) {
        return;
      }

      // Lets get the userId, if its the current users ID - we want to pretend
      // that their message is still out there
      const { userId } = state.sessions[chatId];
      const messageIndex = state.chat[chatId].messages.findIndex((message) => message.message_id === messageId && message.user_id !== userId);
      if (messageIndex >= 0) {
        state.chat[chatId].messages.splice(messageIndex, 1);
      }
    },
    /**
     * Set {sessionId} and {userId} for {broadcastId} in state
     * @param {Object} state
     * @param {Object} obj
     * @param {String} obj.sessionId
     * @param {String} obj.broadcastId
     * @param {String} obj.userId
     */
    setSessionId(state, { sessionId, broadcastId, userId }) {
      state.sessions[broadcastId] = {
        sessionId,
        userId,
      };
    },
  },

  // Actions for chat
  actions: {
    /**
     * Toggle chat
     * @param {Object} obj
     * @param {Function} obj.commit
     * @param {Boolean} bool
     */
    toggleChat: ({ commit }, bool) => {
      commit('toggleChat', bool);
    },
    /**
     * Toggle chat input
     * @param {Object} obj
     * @param {Function} obj.commit
     * @param {Boolean} bool
     */
    toggleChatInput: ({ commit }, bool) => {
      commit('toggleChatInput', bool);
    },
    /**
     * Handle {events} for chat with {id}
     * @param {Object} obj1
     * @param {Function} obj1.commit
     * @param {Object} obj1.state
     * @param {Object} obj2
     * @param {String} obj2.id
     * @param {Array} obj2.events
     * @param {Boolean} obj2.replay
     */
    handleEvents: ({ commit, state }, { id, events, replay = false }) => {
      if (!state.chat[id]) {
        commit('createChat', id);
      }

      if (!replay) {
        const session = events.find((e) => e.event === 'chat.session');
        if (session) {
          commit('setSessionId', {
            broadcastId: id,
            sessionId: session.payload.session_id,
            userId: session.payload.user_id,
          });
        }

        const removeMessages = events.filter((e) => e.event === 'chat.message.deleted');
        if (removeMessages.length > 0) {
          removeMessages.forEach((message) => commit('removeMessage', { chatId: id, messageId: message.payload.id }));
        }

        const pinnedMessage = events.filter((e) => e.event === 'chat.pinned-message');
        if (pinnedMessage.length > 0) {
          pinnedMessage.forEach(({ payload: { type, message } }) => {
            if (type === 'pinned') {
              commit('Broadcast/setPinnedMessage', {
                id,
                message,
              }, { root: true });
            }
          });
        }
      }

      const reactions = events.filter((e) => e.event === 'chat.reaction');
      commit('addReactions', {
        id,
        reactions: reactions.map((reaction) => ({
          timestamp: reaction.timestamp,
          total: reaction.payload.count,
          message_id: reaction.message_id,
        })),
      });

      const chatMessages = events.filter((e) => e.event === 'chat.message' || e.event === 'chat.message.reply');
      commit('addMessages', {
        id,
        messages: chatMessages.map((message) => ({
          message: message.payload.message,
          name: message.payload.name,
          moderator: message.payload.moderator || false,
          session_id: message.payload.session_id,
          user_id: message.payload.user_id,
          timestamp: message.timestamp,
          message_id: message.message_id,
          products: message.payload.products || null,
          type: message.event === 'chat.message.reply' ? 'reply' : 'message',
          reply_to: message.payload.reply_to || null,
          reply_to_message_id: message.payload.reply_to_message_id || null,
        })),
      });
    },
    /**
     * Handle incoming nickname and commit it to store
     * @param {Object} obj
     * @param {Function} obj.commit
     * @param {String} nickname
     * @return {Boolean}
     */
    setUserNickname: ({ commit }, nickname) => {
      if (nickname.trim() === '') {
        return false;
      }

      commit('setUserNickname', nickname);
      return true;
    },
    /**
     * Handle state of nickname
     * @param {Object} obj
     * @param {Function} obj.commit
     * @param {String} state
     */
    setUserNicknameState: ({ commit }, state) => {
      commit('setUserNicknameState', state);
    },
  },
};
