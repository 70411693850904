<template>
  <div :class="`dropdown ${disabled ? 'disabled' : ''} dropdown--${direction}`">
    <div class="inner" @click="drop">
      <div :class="`selected ${multiple ? 'multiple' : ''}`">
        <template v-if="!multiple && selected">
          <span>{{ selected.string }}</span>
        </template>
        <template v-else-if="multiple && selected.length > 0">
          <span v-for="item in selected" :key="item.value">{{ item.string }}</span>
        </template>
        <template v-else>
          <span class="placeholder">{{ placeholder }}</span>
        </template>
      </div>
      <div class="down">
        <i :class="`sfplayicon-play ${dropped ? 'rotate-90' : ''}`"></i>
      </div>
      <ul class="dropper streamify-scrollbars" v-if="dropped" v-click-outside="clickedOutsideDropper">
        <li v-for="(item, index) in listItems" :key="index" :class="`${item.selected ? 'selected' : ''} ${item.disabled ? 'disabled' : ''}`" @click="!item.disabled ? emit(index, item.value) : null">{{ item.string }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Choose',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    listItems() {
      const { items, value } = this;
      if (!value || value === '' || value === null) {
        return items;
      }

      return items.map((item) => ({ ...item, selected: item.value === value }));
    },
    /**
     * Return the selected items in the items array
     * @return {?Object|Array}
     */
    selected() {
      const { listItems, multiple } = this;

      if (!listItems) {
        return null;
      }

      const selected = listItems.filter((item) => item.selected);
      if (selected.length > 0) {
        return multiple ? selected : selected[0];
      }

      return null;
    },
  },
  methods: {
    /**
     * Toggle the dropdown
     */
    drop() {
      if (this.disabled) {
        return;
      }

      const rect = this.$el.getBoundingClientRect();

      this.direction = window.innerHeight - rect.top > 300 ? 'down' : 'up';

      const dropped = !this.dropped;
      if (dropped) {
        // We need a little timeout so the clickedOutsideDropper-function
        // is not run immediatly
        setTimeout(() => {
          this.dropped = dropped;
        }, 50);
      }
    },
    /**
     * Hide the dropper and emit that we click outside
     */
    clickedOutsideDropper() {
      this.dropped = false;
      this.$emit('clickedOutside', true);
    },
    /**
     * Emit choosen one to parent
     * @param {Number} index
     * @param {String|Boolean} value
     */
    emit(index, value) {
      this.$emit('input', { index, value });
      setTimeout(() => {
        this.dropped = false;
      }, 100);
    },
  },
  data() {
    return {
      dropped: false,
      direction: 'down',
    };
  },
};
</script>

<style lang="scss" scoped>

.input-dropdown {
  .dropdown {
    border-radius: 0 4px 4px 0;
    border: 0;

    .inner {
      border-radius: 0 4px 4px 0;

      .down {
        border-left: 0;
      }
    }
  }
}

.dropdown-input {
  .dropdown {
    border: 0;
    border-radius: 4px 0 0 4px;

    .inner {
      border-radius: 4px 0px 0px 3px;

      .down {
        border-left: 0;
        border-radius: 0;
      }
    }
  }

  &.keep-all-rounded {
    .dropdown {
      border-radius: 4px;
      .inner {
        border-radius: 4px 4px 4px 3px;

        .down {
          border-radius: 0 4px 4px 0;
        }
      }
    }
  }
}

.dropdown {
  background-color: #E9EBED;
  border: 1px solid #B7BDC6;
  border-radius: 5px;
  padding-bottom: 2px;
  position: relative;

  &.disabled {
    .inner {
      opacity: .5;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  .inner {
    background-color: white;
    border-radius: 5px 5px 3px 3px;
    // display: flex;
    min-height: 37px;

    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;

    &:hover {
      cursor: pointer;
    }

    & > .selected {
      font-weight: 400;
      padding: 0 10px;
      //width: calc(100% - 30px);
      width: 100%;
      padding-right: 40px;
      line-height: 37px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12.8px;
    }

    .down {
      align-items: center;
      justify-content: center;
      border-left: 1px solid #E9EBED;
      display: flex;
      width: 30px;
      color: #0360FF;
      font-size: 24px;
      position: absolute;
      right: 0;
      bottom: 2px;
      top: 0;
      background-color: white;
      border-radius: 0 5px 5px 0;
    }
  }

  &.small {
    .selected {
      //min-width: 70px;
    }
  }

  &.medium {
    .selected {
      //min-width: 120px;
    }
  }

  .dropper {
    background-color: white;
    border: 1px solid #B7BDC6;
    border-radius: 5px;
    box-shadow: 2px 2px 0 #CED4DE;
    position: absolute;
    top: calc(100% + 1px);
    z-index: 100;
    min-width: 100%;
    overflow: auto;
    max-width: 180px;
    transform: translateX(-50%);
    left: 50%;
    max-height: 210px;

    li {
      padding: 10px;
      overflow: hidden;
      font-size: 11.2px;
      // white-space: nowrap;
      text-overflow: ellipsis;

      &.disabled {
        opacity: .5;

        &:hover {
          cursor: not-allowed;
        }
      }

      &:hover {
        background-color: #0360FF;
        cursor: pointer;
        color: white;
      }

      &.selected {
        background-color: #EFF6FF;
        color: black;
      }
    }
  }

  &.dropdown--up {
    .dropper {
      bottom: calc(100% + 3px);
      top: unset;
    }
  }
}
</style>
