<template>
<div :class="`streamify-products ${products.length > 0 ? '' : 'streamify-products--no-products'}`" :data-show="showProductsList || selectedProduct !== null" :data-product-selected="selectedProduct !== null">
  <div class="buttons">
    <IconButton icon="list" @click.native="openList" :class="`${selectedProduct === null && !showCart ? 'active' : ''}`" />
    <IconButton icon="shopping-bag" @click.native="openCart" :class="`${showCart ? 'active' : ''}`" v-if="cartItems > 0" />
    <IconButton icon="close-x" @click.native="closeList" />
  </div>
  <div class="streamify-products__list" v-if="products.length > 0">
    <Product :persistent="iframe" :broadcast="id" v-for="product in products" :key="product.id" :id="product.id" ref="product" @closeModal="$emit('closeModal', true)" />
  </div>
  <div class="streamify-products__products-not-ready" v-else>
    <i class="sfplayicon-productlist"></i>
    <p>No products right now.</p>
  </div>
</div>
</template>

<script>

import IconButton from '@/components/Buttons/Icon.vue';
import Product from './Product.vue';

export default {
  components: {
    Product,
    IconButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    iframe: {
      type: Boolean,
      default: false,
    },
    /*
    show: {
      type: Boolean,
      default: false,
    },
    */
  },
  computed: {
    /**
     * Return number of items in cart
     * @returns {Number}
     */
    cartItems() {
      return this.$store.getters['Cart/numItems'];
    },
    showProductsList() {
      return this.$store.getters['Products/showProductsList'];
    },
    /**
     * Return a boolean if the chat is a replay
     * @returns {Boolean}
     */
    isReplay() {
      const {
        item: {
          data: {
            life_cycle_status: status,
          },
        },
      } = this;
      return status === 'complete';
    },
    /**
     * Return a boolean if products are available to list
     * @returns {Boolean}
     */
    listAvailable() {
      const {
        item: {
          data: {
            life_cycle_status: status,
          },
        },
      } = this;

      return status === 'live' || status === 'liveStopping';
    },
    /**
     * Return broadcast or video item with supplied ID
     * @returns {Object}
     */
    item() {
      return this.$store.getters['Broadcast/get'](this.id);
    },
    /**
     * Return the current FQDN of the broadcast
     * @returns {String}
     */
    fqdn() {
      const {
        item: {
          data: {
            fqdn,
          },
        },
      } = this;

      return fqdn;
    },
    /**
     * Return a list of products connected to this broadcast
     * @returns {Array}
     */
    products() {
      if (this.item.loading || this.item.error) {
        return [];
      }

      const {
        item: {
          data: {
            event: {
              shopping: {
                products: enabledProducts,
              },
            },
          },
        },
      } = this;

      const list = this.$store.getters['Products/broadcastProducts'](this.fqdn);
      if (!list) {
        return [];
      }
      const newList = list.filter((p) => enabledProducts.includes(p.id));
      return newList;
    },
    /**
     * Get the selected product from the store
     * @returns {?String}
     */
    selectedProduct() {
      return this.$store.getters['Products/selectedProduct'];
    },
    /**
     * Return boolean if the cart is showing
     * @returns {Boolean}
     */
    showCart() {
      return this.$store.getters['Cart/show'];
    },
  },
  methods: {
    openCart() {
      this.$store.dispatch('Cart/toggleCart', !this.showCart);
      this.$store.dispatch('Products/toggleProductsList', false);
    },
    openList() {
      const {
        fqdn,
      } = this;
      this.$store.dispatch('Products/selectProduct', { product: null, fqdn, type: 'good' });
      this.$store.dispatch('Cart/toggleCart', false);
      this.$store.dispatch('Products/toggleProductsList', true);
    },
    closeList() {
      this.$store.dispatch('Products/toggleProductsList', false);
    },
    /**
     * Event back from parent site with confirmation if the
     * product was added in cart successfully or not
     * @param {Boolean} success
     */
    productEvent(success) {
      if (Array.isArray(this.$refs.product)) {
        const index = this.$refs.product.findIndex((item) => item.id === this.selectedProduct);
        this.$refs.product[index].productEvent(success);
      } else {
        this.$refs.product.productEvent(success);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/styles/Mixins';

.streamify-products {
  flex: 1;

  @include e('list') {
    & > * {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include e('products-not-ready') {
    border-radius: 8px;
    align-items: center;
    color: #a8a8a8;
    display: flex;
    height: 100%;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0 16px;

    i {
      font-size: 48px;
      margin-bottom: 16px;
    }

    p {
      font-size: 12.8px;
    }
  }
}
</style>
