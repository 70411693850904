import Vue from 'vue';
import { mergeObjectDeep, getQueryParameterValue } from '@/utils';

export default {
  namespaced: true,

  state: {
    config: {
      appearance: {},
      domains: [],
      emojis: ['👍'],
      events: [],
      fqdn: '',
      images: {},
      strings: {
        en: {},
      },
      urls: {},
    },
    event: null,
    ga: {
      use: false,
      data: {
        event: 'lvsp_event',
        broadcast: 'lvsp_broadcast',
        product: 'lvsp_product',
        videotime: 'lvsp_videotime',
      },
    },
    playerEvent: null,
  },

  getters: {
    /**
     * Return the current config
     * @param {Object} state
     * @returns {Object}
     */
    config: (state) => state.config,
    /**
     * Returns the current GA config
     * @param {Object} state
     * @returns {Object}
     */
    ga: (state) => state.ga,
    /**
     * Returns the current event in Liveshopping
     * @param {Object} state
     * @returns {?Object}
     */
    event: (state) => state.event,
    /**
     * Returns the current playerevent in Liveshopping
     * @param {Object} state
     * @returns {?Object}
     */
    playerEvent: (state) => state.playerEvent,
  },

  mutations: {
    /**
     * Merge and set the config
     * @param {Object} state
     * @param {Object} config
     */
    setConfig(state, config) {
      state.config = mergeObjectDeep(state.config, config);
    },
    /**
     * Set GA
     * @param {Object} state
     */
    setGA(state, bool) {
      state.ga.use = bool;
    },
    /**
     * Commit new event to store
     * @param {Object} state
     * @param {Object} event
     */
    event(state, event) {
      Vue.set(state, 'event', event);
    },
    /**
     * Commit new playerEvent to store
     * @param {Object} state
     * @param {Object} playerEvent
     */
    playerEvent(state, playerEvent) {
      Vue.set(state, 'playerEvent', playerEvent);
    },
  },

  actions: {
    /**
     * Get config from window and commit it
     * @param {Object} obj
     * @param {Function} obj.commit
     */
    setConfig: ({ commit, dispatch }) => {
      const config = typeof streamify_liveshoppingConfig !== 'undefined' ? streamify_liveshoppingConfig : null;
      if (config) {
        // In case some of the keys are null from the getgo..
        // Damn you Golang, Christofer and Paer
        config.domains = config.domains || [];
        config.events = config.events || [];
        config.urls = config.urls || {};
        config.emojis = config.emojis || ['👍'];
        config.strings = config.strings || { en: {} };
        config.appearance = config.appearance || {};

        commit('setConfig', config);

        dispatch('setColors');
      }
    },
    /**
     * Set all the colors of the rainbow
     * @param {Object} obj
     * @param {Object} obj.state
     */
    setColors: ({ state }) => {
      const liveshopping = document.querySelectorAll('streamify-liveshopping');
      const modal = document.querySelector('streamify-modal');
      const appearance = {};

      const iterateColors = (obj, prev = '') => {
        Object.keys(obj).forEach((key) => {
          if (typeof obj[key] === 'object') {
            iterateColors(obj[key], prev === '' ? key : `${prev}-${key}`);
          } else {
            appearance[`${prev}-${key}`] = obj[key];
          }
        });
      };

      iterateColors(state.config.appearance);

      Object.entries(appearance).forEach(([key, value]) => {
        liveshopping.forEach((p) => {
          p.style.setProperty(`--sf-${key}`, value);
        });
        if (modal) {
          modal.style.setProperty(`--sf-${key}`, value);
        }
      });
    },
    /**
     * Check and set GA
     * @param {Object} obj
     * @param {Function} obj.commit
     * @param {Object} obj.state
     */
    setGA: ({ commit, state }) => {
      if (state.gtag.use) {
        return;
      }
      const gtagScript = document.querySelector('script[src*="gtag"]');
      if (gtagScript && window.gtag) {
        const gtagId = getQueryParameterValue('id', gtagScript.getAttribute('src'));
        if (!gtagId || window[`ga-disable-${gtagId}`]) {
          // Gtag does not exist, or the user has opt out
          return;
        }
      } else {
        return;
      }

      commit('setGA', true);
    },
    /**
     * Commit a new event to store
     * @param {Object} obj
     * @param {Function} obj.commit
     * @param {Object} event
     */
    event: ({ commit }, event) => {
      commit('event', event);
    },
    /**
     * Commit a new playerEvent to store
     * @param {Object} obj
     * @param {Function} obj.commit
     * @param {Object} playerEvent
     */
    playerEvent: ({ commit }, playerEvent) => {
      commit('playerEvent', playerEvent);
    },
  },
};
