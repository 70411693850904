<template>
  <div class="button" @click="!disabled ? $emit('click', true) : null" role="button" :class="`${disabled ? 'disabled' : ''}`">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  align-items: center;
  background-color: white;
  border-radius: var(--sf-button-border-radius);
  cursor: pointer;
  color: black;
  display: flex;
  font-size: var(--sf-button-font-size);
  font-weight: var(--sf-button-font-weight);
  line-height: 1;
  padding: 14px 8px;
  // letter-spacing: 2px;
  justify-content: center;
  transition: all .3s;
  text-transform: var(--sf-button-text-transform);

  &.button--primary {
    background-color: var(--sf-primary-button-background);
    color: var(--sf-primary-button-color);

    .spinner:after {
      border-color: var(--sf-primary-button-color) transparent var(--sf-primary-button-color) transparent;
    }
  }

  &.button--secondary {
    background-color: var(--sf-secondary-button-background);
    color: var(--sf-secondary-button-color);
  }

  &.disabled {
    pointer-events: none;
    opacity: .7;
  }

  &:hover {
    filter: brightness(120%);
  }
}
</style>
