import { Products } from '@/api/products';
// import { Shopify } from '@/api/shopify';
// import { purifyText } from '@/utils';

import Vue from 'vue';

// eslint-disable-next-line
// let refreshTimeout = null;

const productsState = {
  product: null,
  products: {},
  streamProducts: {},
  productEvent: null,
  showProductsList: false, // This will only be for places where the list is hidden
};

const productsMutations = {
  /**
   * Create, set or update a list of products for a FQDN
   * @param {Object} state
   * @param {Object} param
   * @param {String} param.fqdn
   * @param {String} param.type
   * @param {Boolean} param.loading
   * @param {Boolean} param.error
   * @param {Object} param.data
   */
  // eslint-disable-next-line
  set(state, { fqdn, type, loading = false, error = false, data }) {
    if (state.products[fqdn]) {
      Vue.set(state.products[fqdn], type, { loading, error, data });
    } else {
      Vue.set(state.products, fqdn, {});
      if (type) {
        Vue.set(state.products[fqdn], type, { loading, error, data });
      }
    }
  },
  streamAdds(state, { id, products }) {
    if (!state.streamProducts[id]) {
      Vue.set(state.streamProducts, id, []);
    }

    Vue.set(state.streamProducts, id, [...state.streamProducts[id], ...products.map((p) => ({
      displayAt: p.timestamp,
      ...p.product,
    }))]);
  },
  streamHides(state, { id, products }) {
    if (!state.streamProducts[id]) {
      Vue.set(state.streamProducts, id, []);
    }

    Vue.set(state.streamProducts, id, state.streamProducts[id].map((p) => {
      const index = products.findIndex((a) => a.product.id === p.id);
      if (index >= 0) {
        return {
          ...p,
          hideAt: products[index].timestamp,
        };
      }

      return p;
    }));
  },
  /**
   * Create or set a product from event-stream
   * @param {Object} state
   * @param {Object} param
   * @param {String} param.broadcastId
   * @param {Object} param.product
   */
  streamAdd(state, { broadcastId, product, timestamp }) {
    if (!state.streamProducts[broadcastId]) {
      Vue.set(state.streamProducts, broadcastId, []);
    }
    // if (state.streamProducts[broadcastId].find((p)) {
    if (state.streamProducts[broadcastId].findIndex((p) => p.id === product.id) < 0) {
      // Vue.set(state.streamProducts, broadcastId, [...state.streamProducts[broadcastId], ...[{ showAt: timestamp, ...product }]]);
      Vue.set(state.streamProducts, broadcastId, [...state.streamProducts[broadcastId], ...[{ display: true, displayAt: timestamp, ...product }]]);
    }
  },
  /**
   * Update a product from event-stream with hideAt time, if it exists.
   * @param {Object} state
   * @param {Object} param
   * @param {String} param.broadcastId
   * @param {Object} param.product
   */
  streamRemove(state, { broadcastId, product, timestamp }) {
    if (!state.streamProducts[broadcastId]) {
      return;
    }
    const index = state.streamProducts[broadcastId].findIndex((p) => p.id === product.id);
    if (index >= 0) {
      // Vue.set(state.streamProducts[broadcastId], index, { ...state.streamProducts[broadcastId][index], ...{ hideAt: timestamp } });
      Vue.set(state.streamProducts[broadcastId], index, { ...state.streamProducts[broadcastId][index], ...{ display: false, hideAt: timestamp } });
    }
  },
  /**
   * Set a selected product by ID for FQDN and Type
   * @param {Object} state
   * @param {Object} param
   * @param {?String} param.product
   * @param {String} param.fqdn
   * @param {String} param.type
   */
  // eslint-disable-next-line
  selectProduct(state, { product, fqdn, type }) {
    state.product = product;
  },
  /**
   * List products for FQDN and Type with Bool
   * @param {Object} state
   * @param {Object} param
   * @param {String} param.fqdn
   * @param {String} param.type
   * @param {Boolean} param.bool
   */
  listProducts(state, { fqdn, type, bool }) {
    if (state.products[fqdn]) {
      if (state.products[fqdn][type]) {
        Vue.set(state.products[fqdn][type], 'list', bool);
      }
    }
  },
  updateProduct(state, { fqdn, id, product }) {
    if (state.products[fqdn]) {
      if (state.products[fqdn].good.data) {
        const index = state.products[fqdn].good.data.findIndex((p) => p.id === id);
        Vue.set(state.products[fqdn].good.data, index, { ...state.products[fqdn].good.data[index], ...product });
      }
    }
  },
  productEvent(state, data) {
    state.productEvent = data;
  },
  toggleProductsList(state, bool) {
    state.showProductsList = bool;
  },
  updateProductPrice(state, { fqdn, id, updates }) {
    const index = state.products[fqdn].good.data.findIndex((p) => p.id === id);
    if (index < 0) {
      return;
    }
    const skus = state.products[fqdn].good.data[index].sku.map((sku) => {
      const tempSku = sku;
      const skuIndex = updates.sku.findIndex((s) => s.id === sku.metadata.external_variant_id);

      tempSku.metadata.price = updates.sku[skuIndex].price;
      if (updates.sku[skuIndex].originalPrice) {
        tempSku.metadata.originalPrice = updates.sku[skuIndex].originalPrice;
      }

      return tempSku;
    });

    Vue.set(state.products[fqdn].good.data, index, {
      ...state.products[fqdn].good.data[index],
      sku: skus,
      dontUpdatePrices: true,
    });
  },
};

const productsGetters = {
  /**
   * Get a product by ID and FQDN
   * @param {Object} state
   * @param {String} productId
   * @param {String} fqdn
   * @returns {?Object}
   */
  product: (state) => (fqdn, id) => (state.products[fqdn] && state.products[fqdn].good ? state.products[fqdn].good.data.find((p) => p.id === id) : null),
  /**
   * Return a list of products for FQDN och Type
   * @param {Object} state
   * @param {String} fqdn
   * @param {String} type
   * @returns {?Array}
   */
  products: (state) => (fqdn, type) => state.products[fqdn][type].data || [],
  /**
   * Return selected product for FQDN and Type
   * @param {Object} state
   * @param {String} fqdn
   * @param {String} type
   * @returns {?Object}
   */
  selectedProduct: (state) => state.product,
  /**
   * Return boolean if we want to list products for fqdn and type
   */
  listProducts: (state) => (fqdn, type) => (state.products[fqdn] && state.products[fqdn][type] ? state.products[fqdn][type].list : false),
  /**
   * Return a productslist with all its states
   * @returns {?Object}
   */
  productList: (state) => (fqdn, type) => (state.products[fqdn] && state.products[fqdn][type] ? state.products[fqdn][type] : null),
  /**
   * Return a list of products for a liveshopping broadcast by broadcast ID
   * @param {Object} state
   * @param {String} id
   * @returns {?Array}
   */
  streamProducts: (state) => (id) => state.streamProducts[id] || [],
  /**
   * Return a list of products for a FQDN
   * @param {Object} state
   * @param {String} fqdn
   * @returns {?Array}
   */
  broadcastProducts: (state) => (fqdn) => (state.products[fqdn] && state.products[fqdn].good ? state.products[fqdn].good.data : []),
  productEvent: (state) => state.productEvent,
  showProductsList: (state) => state.showProductsList,
};

const productsActions = {
  toggleProductsList: ({ commit }, bool) => {
    commit('toggleProductsList', bool);
  },
  /**
   * Productevent
   */
  // eslint-disable-next-line
  productEvent: async ({ commit, dispatch }, data) => {
    commit('productEvent', data);
  },
  /**
   * Get a list of products with FQDN and Type from API
   * @param {Object} param0
   * @param {Function} param0.commit
   * @param {Object} param0.state
   * @param {Object} param1
   * @param {String} param1.fqdn
   * @param {String} param1.type
   * @param {Boolean} param1.force will force-refresh the list
   * @returns {Boolean}
   */
  getList: async ({ commit, state }, { fqdn, type, force = false }) => {
    if (!state.products[fqdn]) {
      commit('set', {
        fqdn,
      });
    }
    if (!state.products[fqdn][type] || force) {
      if (!force) {
        // eslint-disable-next-line
        commit('set', { fqdn, type, loading: true, error: false });
      }
      try {
        const products = await Products.getList(fqdn, type);
        // eslint-disable-next-line
        commit('set', { fqdn, type, loading: false, error: false, data: [...products.products] || [] });
      } catch (e) {
        if (!force) {
          // eslint-disable-next-line
          commit('set', { fqdn, type, loading: false, error: true });
        }
        return false;
      }
    }
    return true;
  },
  handleEvents: async ({ commit, state, dispatch }, { id, events, fqdn }) => {
    if (!state.products[fqdn].good.data) {
      return;
    }

    const productShow = events.filter((p) => p.event === 'product.show');
    const productHide = events.filter((p) => p.event === 'product.hide');
    const productRefresh = events.filter((p) => p.event === 'product.refresh');

    if (productShow.length > 0) {
      commit('streamAdds', {
        id,
        products: productShow.map((p) => {
          const product = state.products[fqdn].good.data.find((pr) => pr.id === p.payload.id);
          return {
            product,
            timestamp: p.timestamp,
          };
        }),
      });
    }
    if (productHide.length > 0) {
      commit('streamHides', {
        id,
        products: productHide.map((p) => {
          const product = state.products[fqdn].good.data.find((pr) => pr.id === p.payload.id);
          return {
            product,
            timestamp: p.timestamp,
          };
        }),
      });
    }

    for (let i = 0; i < productRefresh.length; i += 1) {
      // eslint-disable-next-line
      await dispatch('getList', { fqdn, type: 'good', force: true });
      dispatch('Broadcast/updateProductsList', { id, products: productRefresh[i].payload.id }, { root: true });
    }
  },
  // eslint-disable-next-line
  handleMessages: async ({ commit, dispatch, state }, { id, messages, fqdn, tries = 0 }) => {
    if (!state.products[fqdn].good.data) {
      return;
    }
    const productShow = messages.filter((p) => p.event === 'product.show');
    const productHide = messages.filter((p) => p.event === 'product.hide');
    commit('streamAdds', {
      id,
      products: productShow.map((p) => {
        const product = state.products[fqdn].good.data.find((pr) => pr.id === p.payload.id);
        return {
          product,
          timestamp: p.timestamp,
        };
      }),
    });
    commit('streamHides', {
      id,
      products: productHide.map((p) => {
        const product = state.products[fqdn].good.data.find((pr) => pr.id === p.payload.id);
        return {
          product,
          timestamp: p.timestamp,
        };
      }),
    });
  },
  /**
   * Handle a message from the eventstream
   * @param {Object} param0
   * @param {Function} param0.commit
   * @param {Function} param0.dispatch
   * @param {Object} param1
   * @param {String} param1.id
   * @param {Object} param1.message
   * @param {String} param1.fqdn
   */
  // eslint-disable-next-line
  handleMessage: async ({ commit, dispatch, state }, { id, message, fqdn, tries = 0 }) => {
    switch (message.event) {
      case 'product.show':
        // eslint-disable-next-line
        if (message.payload.id) {
          let product;
          // Lets check the internal list first
          // products.js?6b97:225 Uncaught (in promise) TypeError: Cannot read properties of undefined (reading 'find')
          if (!state.products[fqdn]?.good?.data) {
            if (tries < 4) {
              setTimeout(() => {
                dispatch('handleMessage', {
                  id,
                  message,
                  fqdn,
                  tries: tries + 1,
                });
              }, 200);
              return;
            }
          }
          product = state.products[fqdn].good.data.find((p) => p.id === message.payload.id);
          if (!product) {
            // It might have been added to the list after the user opened up the page
            // So lets try and get the standalone product
            product = await Products.getProduct(fqdn, message.payload.id);
          }
          if (product) {
            commit('streamAdd', { broadcastId: id, product, timestamp: message.timestamp });
          }
        }
        break;
      case 'product.hide':
        if (message.payload.id) {
          if (state.streamProducts[id]) {
            const product = state.streamProducts[id].find((p) => p.id === message.payload.id);
            if (product) {
              commit('streamRemove', { broadcastId: id, product, timestamp: message.timestamp });
            }
          }
        }
        break;
      case 'product.refresh':
        await dispatch('getList', { fqdn, type: 'good', force: true });
        dispatch('Broadcast/updateProductsList', { id, products: message.payload.id }, { root: true });
        // clearTimeout(refreshTimeout);
        // refreshTimeout = setTimeout(async () => {}, 100);
        break;
      default:
        break;
    }
  },
  /**
   * Select a product by ID for FQDN and Type
   * @param {Object} param0
   * @param {Function} param0.commit
   * @param {Object} param1
   * @param {?String} param1.product
   * @param {String} param1.fqdn
   * @param {String} param1.type
   */
  selectProduct: ({ commit }, { product, fqdn, type }) => {
    commit('selectProduct', { product, fqdn, type });
  },
  /**
   * List products by FQDN and Type with Bool
   * @param {Object} param0
   * @param {Function} param0.commit
   * @param {Object} param1
   * @param {?String} param1.product
   * @param {String} param1.fqdn
   * @param {String} param1.type
   */
  listProducts: ({ commit }, { fqdn, type, bool }) => {
    commit('listProducts', { fqdn, type, bool });
  },
  getProduct: async ({ commit, state }, { fqdn, product }) => {
    const prod = state.products[fqdn].good.data.find((p) => p.id === product);
    if (prod.sku) {
      return;
    }

    const fetchedProduct = await Products.getProduct(fqdn, product);
    commit('updateProduct', { fqdn, id: product.id, product: fetchedProduct });
  },
  updateProductPrice: async ({ commit }, { fqdn, product, updates }) => {
    commit('updateProductPrice', {
      fqdn,
      id: product,
      updates,
    });
  },
};

export default {
  namespaced: true,
  state: productsState,
  mutations: productsMutations,
  getters: productsGetters,
  actions: productsActions,
};
