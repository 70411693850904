<template>
  <div :class="`live ${live ? 'live--dot' : ''}`">
    {{ text }}
  </div>
</template>

<script>
import { distanceToNow } from '@/utils';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    /**
     * Return the broadcast with ID
     * @returns {?Object}
     */
    broadcast() {
      return this.$store.getters['Broadcast/get'](this.id);
    },
    /**
     * Returns a boolean if the broadcast is live or not
     * @returns {Boolean}
     */
    live() {
      const { broadcast: { data: { life_cycle_status } } } = this;
      return life_cycle_status === 'live' || life_cycle_status === 'liveStopping';
    },
    /**
     * Returns the text we want to show
     * @returns {String}
     */
    text() {
      const { broadcast: { data: { life_cycle_status, actual_end_time } } } = this;
      const now = new Date();
      const bcDate = new Date(actual_end_time);

      switch (life_cycle_status) {
        case 'abandoned':
          return 'Canceled';
        case 'created':
        case 'ready':
        case 'liveStarting':
          return 'Upcoming';
        case 'live':
        case 'liveStopping':
          return 'Live';
        default:
          if (now.toDateString() === bcDate.toDateString()) {
            return 'Recorded today';
          }
          return `${distanceToNow(new Date(actual_end_time))} ago`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.live {
  background-color: var(--sf-live-badge-background);
  color: var(--sf-live-badge-color);
  border-radius: 16px;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  line-height: 32px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  overflow: hidden;
  position: absolute;
  top: 10px;
  left: 10px;
  user-select: none;
  z-index: 1;

  &.live--dot {
    padding-left: 30px;
    &:before {
      border-radius: 6px;
      background-color: red;
      content: "";
      height: 12px;
      left: 10px;
      top: 10px;
      position: absolute;
      width: 12px;
    }
  }
}
</style>
