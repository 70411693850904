<template>
  <div class="hadiraja" @mousedown="pourHadiraja()" @mouseup="stopPouringHadiraja()" @touchstart.prevent="pourHadiraja()" @touchend="stopPouringHadiraja()" @mouseleave="stopPouringHadiraja()">
    <IconButton class="large-content fixed-large animate">{{ emoji }}</IconButton>
  </div>
</template>

<script>

import IconButton from '@/components/Buttons/Icon.vue';

export default {
  name: 'Hadiraja',
  components: {
    IconButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    emoji() {
      return this.$store.getters['LiveShopping/config'].emojis[0];
    },
  },
  methods: {
    addHadiraja() {
      this.hadirajas += 1;
    },
    pourHadiraja() {
      clearInterval(this.bajsInterval);
      this.addHadiraja();
      this.bajsInterval = setInterval(() => {
        this.addHadiraja();
      }, 250);
      navigator.vibrate(200);
      this.vibrateInterval = setInterval(() => {
        navigator.vibrate(200);
      }, 1000);
    },
    stopPouringHadiraja() {
      clearInterval(this.bajsInterval);
      clearInterval(this.vibrateInterval);
      navigator.vibrate(0);
    },
    hadiraja() {
      const { id, hadirajas } = this;
      if (hadirajas === 0) {
        return;
      }

      this.hadirajas = 0;

      this.$store.dispatch('Events/sendEvent', {
        id,
        payload: {
          event: 'chat.reaction',
          payload: {
            count: hadirajas,
          },
        },
      });
    },
  },
  data() {
    return {
      state: '',
      hadirajas: 0,
      hadirajaInterval: null,
      bajsInterval: null,
      vibrateInterval: null,
    };
  },
  mounted() {
    this.hadirajaInterval = setInterval(() => {
      this.hadiraja();
    }, 1500);
  },
  beforeDestroy() {
    clearInterval(this.hadirajaInterval);
    clearInterval(this.bajsInterval);
  },
};
</script>
