import Vue from 'vue';
import { formatDate } from '@/utils';

let initialized = false;

export default function () {
  if (initialized) {
    return;
  }

  Vue.filter('amount', (value, currency) => {
    if (Intl === undefined) {
      return (value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    let locale = 'sv-SE';
    if (navigator.languages !== undefined) {
      [locale] = navigator.languages;
    }

    return new Intl.NumberFormat(locale, { style: 'currency', currency, currencyDisplay: 'symbol' }).format(value);
  });

  Vue.filter('formatDate', (date, formatStr) => formatDate(new Date(date), formatStr));

  initialized = true;
}
