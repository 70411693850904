import { render, staticRenderFns } from "./Video2.vue?vue&type=template&id=ef8040ee&scoped=true&"
import script from "./Video2.vue?vue&type=script&lang=js&"
export * from "./Video2.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Video2.vue?vue&type=style&index=0&lang=scss&")
if (style0.__inject__) style0.__inject__(context)
var style1 = require("./Video2.vue?vue&type=style&index=1&id=ef8040ee&lang=scss&scoped=true&")
if (style1.__inject__) style1.__inject__(context)
var style2 = require("./Video2.vue?vue&type=style&index=2&lang=scss&")
if (style2.__inject__) style2.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "ef8040ee",
  null
  ,true
)

export default component.exports