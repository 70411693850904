import Vue from 'vue';

const qaState = {
  qa: {},
};

const qaMutations = {
  /**
   * Add a question (and answer) to qa questionslist
   * @param {Object} state
   * @param {Object} param1
   * @param {String} param1.id
   * @param {Object} param1.question
   */
  addQuestion(state, { id, question }) {
    if (!state.qa[id]) {
      Vue.set(state.qa, id, { questions: [] });
    }

    state.qa[id].questions.push(question);
  },
  /**
   * Remove a question from qa
   * @param {Object} state
   * @param {Object} param1
   * @param {String} param1.id
   * @param {String} param1.questionId
   */
  removeQuestion(state, { id, questionId }) {
    if (!state.qa[id]) {
      return;
    }

    const index = state.qa[id].questions.findIndex((q) => q.question_id === questionId);
    if (index >= 0) {
      state.qa[id].questions.splice(index, 1);
    }
  },
};

const qaGetters = {
  /**
   * Return list of questions (and answers) for broadcast with {ID}
   * @param {Object} state
   * @param {String} id
   * @returns {Array}
   */
  questions: (state) => (id) => (state.qa[id] ? state.qa[id].questions : []).sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)),
};

const qaActions = {
  /**
   * Handle incoming messages from Event-store
   * @param {Object} param0
   * @param {Function} param0.commit
   * @param {Object} param1
   * @param {String} param1.id
   * @param {Object} param1.message
   */
  handleMessage: ({ commit }, { id, message }) => {
    if (message.event === 'qa.message') {
      commit('addQuestion', {
        id,
        question: {
          ...message.payload,
          timestamp: message.timestamp,
        },
      });
    }

    if (message.event === 'qa.message.deleted') {
      commit('removeQuestion', { id, questionId: message.payload.question_id });
    }
  },
  // eslint-disable-next-line
  handleEvents: ({ commit }, { id, events }) => {
    /*
    Needs work..
    const qaAdd = events.filter((ev) => ev.event === 'qa.message');
    const qaRemove = events.filter((ev) => ev.event === 'product.hide');

    commit('addQuestions', {

    });
    */
  },
};

export default {
  namespaced: true,
  state: qaState,
  mutations: qaMutations,
  getters: qaGetters,
  actions: qaActions,
};
