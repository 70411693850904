import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import Broadcast from '@/store/broadcast';
import Cart from '@/store/cart';
import Chat from '@/store/chat';
import Events from '@/store/events';
import Player from '@/store/player';
import Products from '@/store/products';
import LiveShopping from '@/store/liveshopping';
import QA from '@/store/qa';
import Analytics from '@/store/analytics';

let initialized = false;

export default function () {
  if (initialized) {
    return;
  }
  Vue.use(Vuex);

  const vuexPersist = new VuexPersist({
    key: 'streamify',
    storage: window.localStorage,
    reducer: (state) => ({
      Chat: {
        user: state.Chat.user,
        // nickname: state.Chat.nickname,
        // nicknameTimestamp: state.Chat.nicknameTimestamp,
        sessions: state.Chat.sessions,
      },
      Analytics: {
        token: state.Analytics.token,
        timestamp: state.Analytics.timestamp,
      },
      Player: {
        volume: state.Player.volume,
      },
    }),
  });

  const store = new Vuex.Store({
    modules: {
      Analytics,
      Broadcast,
      Cart,
      Chat,
      Events,
      Player,
      Products,
      LiveShopping,
      QA,
    },
    plugins: [vuexPersist.plugin],
  });

  Vue.mixin({ store });

  initialized = true;
}
