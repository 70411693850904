<template>
<div class="streamify-cart" :data-show="show" @click="clickEvent($event)">
  <div class="buttons">
    <IconButton icon="list" @click.native="openList" :class="`${selectedProduct === null && !showCart ? 'active' : ''}`" />
    <IconButton icon="shopping-bag" @click.native="openCart" :class="`${showCart ? 'active' : ''}`" />
    <IconButton icon="close-x" @click.native="closeCart" />
  </div>
  <div class="streamify-cart__inner" v-if="!item.loading && !item.error">
    <h2 class="sidebar__title">{{ strings.shopping_cart || 'Shopping cart' }}</h2>
    <div class="streamify-cart__items" v-if="items.length > 0">
      <div class="streamify-cart__item" v-for="(cartItem, index) in items" :key="index">
        <div class="streamify-cart__item__image" :style="`background-image: url('${cartItem.image.includes('http') ? cartItem.image : `https://${item.data.fqdn}/images/h/40/${cartItem.image}`}');`"></div>
        <div class="streamify-cart__item__information">
          <p class="streamify-cart__item__name">{{ cartItem.name }} {{ cartItem.variant }}</p>
          <p class="streamify-cart__item__price">{{ cartItem.price }}</p>
        </div>
        <div class="streamify-cart__item__quantity" v-if="canChangeQuantity">
          <span class="streamify-cart__item__quantity__change" @click="quantity(index, -1)">-</span>
          <span class="streamify-cart__item__quantity__number">{{ cartItem.quantity }}</span>
          <span class="streamify-cart__item__quantity__change" @click="quantity(index, 1)">+</span>
        </div>
        <i class="sfplayicon-close-x streamify-cart__item__remove" @click="removeItem(index)" v-if="canRemoveItem"></i>
      </div>
    </div>
    <div v-else class="no-items">
      <p>No items in cart</p>
    </div>
    <small class="streamify-cart__checkout-notice" v-if="(!canChangeQuantity || !canRemoveItem) && strings.checkout_button_notice">{{ strings.checkout_button_notice }}</small>
    <button class="streamify-cart__checkout" @click="goToCheckout()" v-if="showCheckoutButton && items.length > 0">{{ strings.checkout_button || 'Go to checkout' }}</button>
  </div>
</div>
</template>

<script>

import { getHostname } from '@/utils';

import IconButton from '@/components/Buttons/Icon.vue';

export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    orientation: {
      type: String,
    },
    ditchTimeout: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IconButton,
  },
  computed: {
    /**
     * Get the selected product from the store
     * @returns {?String}
     */
    selectedProduct() {
      return this.$store.getters['Products/selectedProduct'];
    },
    /**
     * Return boolean if the cart is showing
     * @returns {Boolean}
     */
    showCart() {
      return this.$store.getters['Cart/show'];
    },
    canChangeQuantity() {
      const {
        config: {
          urls: {
            product_update_quantity,
          },
          events = [],
        },
      } = this;

      return product_update_quantity || events.indexOf('cart.update') >= 0 || window.Shopify;
    },
    canRemoveItem() {
      const {
        config: {
          urls: {
            product_remove_from_cart,
          },
          events = [],
        },
      } = this;
      return product_remove_from_cart || events.indexOf('cart.remove') >= 0 || window.Shopify;
    },
    strings() {
      return this.config.strings.en;
    },
    config() {
      return this.$store.getters['LiveShopping/config'];
    },
    show() {
      return this.$store.getters['Cart/show'];
    },
    /**
     * Return broadcast or video item with supplied ID
     * @returns {Object}
     */
    item() {
      return this.$store.getters['Broadcast/get'](this.id);
    },
    items() {
      const { products } = this;
      const items = this.$store.getters['Cart/items'];

      return items.map(({ id, selectedVariant, quantity }) => {
        const product = products.find((p) => p.id === id);
        if (selectedVariant) {
          if (product.sku) {
            const variant = product.sku.find((s) => (s.metadata.external_variant_id ? s.metadata.external_variant_id === selectedVariant : s.id === selectedVariant));
            return {
              name: product.name,
              variant: variant.metadata.external,
              image: variant.metadata.image,
              price: variant.metadata.price,
              quantity,
            };
          }
        }

        return {
          name: product.name,
          image: product.images[0],
          price: product.price ? product.price[0].formated_amount : '',
          quantity,
        };
      });
    },
    /**
     * Return a list of products connected to this broadcast
     * @returns {Array}
     */
    products() {
      const {
        item: {
          data: {
            fqdn,
            event: {
              shopping: {
                products: enabledProducts,
              },
            },
          },
        },
      } = this;

      const list = this.$store.getters['Products/broadcastProducts'](fqdn);
      const newList = list.filter((p) => enabledProducts.includes(p.id));
      return newList;
    },
    showCheckoutButton() {
      return this.config.urls.cart || this.config.urls.checkout || this.config.events.indexOf('cart.checkout') >= 0 || window.Shopify;
    },
  },
  methods: {
    openList() {
      this.$store.dispatch('Products/toggleProductsList', true);
      this.$store.dispatch('Cart/toggleCart', false);
    },
    closeCart() {
      this.$store.dispatch('Cart/toggleCart', false);
    },
    clickEvent(event) {
      if (event.target.classList.contains('streamify-cart') && event.target.getAttribute('data-show') === 'true') {
        // if (event.target.classList.contains('streamify-cart--show')) {
        this.$store.dispatch('Cart/toggleCart', false);
      }
    },
    removeItem(index) {
      this.$store.dispatch('Cart/removeFromCart', index);
    },
    quantity(index, quantity) {
      this.$store.dispatch('Cart/updateQuantity', { index, quantity });
    },
    goToCheckout() {
      if (window.Shopify) {
        const currentUrlDomain = getHostname(window.location.href);
        window.open(`https://${currentUrlDomain}/cart`, '_blank');
        return;
      }

      const {
        config: {
          urls: {
            checkout,
          },
          events,
        },
      } = this;

      if (checkout) {
        window.open(checkout.url, '_blank');
        return;
      }

      if (events.includes('cart.checkout') >= 0) {
        this.$store.dispatch('Cart/goToCheckout');
      }
    },
  },
  /*
  watch: {
    show(bool) {
      clearTimeout(this.showTimeout);
      if (this.orientation === 'landscape' && !bool && !this.ditchTimeout) {
        this.showTimeout = setTimeout(() => {
          this.showing = false;
        }, 500);
      } else {
        this.showing = bool;
      }
    },
  },
  */
  data() {
    return {
      // showing: false,
      // showTimeout: null,
    };
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/styles/Mixins';

.buttons {
  display: flex;
  padding: 15px;

  & > * {
    &:not(:last-child) {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: auto;
    }
  }
}

.streamify-cart {
  display: none;

  .no-items {
    text-align: center;
    padding: 16px;
    font-size: 14px;
    font-style: italic;
  }

  @include e('inner') {
    padding: 16px;
  }

  @include e('checkout') {
    align-items: center;
    background-color: #26CB6B;
    cursor: pointer;
    display: flex;
    border: 0;
    justify-content: center;
    padding: 20px 0;
    width: 100%;
    z-index: 4;
    transition: all .3s;
    height: 45px;
    user-select: none;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    letter-spacing: 2px;
    text-shadow: 0 1px 1px #09B450;
    text-transform: uppercase;
    transition: all .3s;
    margin-top: 20px;
  }

  @include e('checkout-notice') {
    color: rgba(0, 0, 0, .8);
    padding: 5px 20px;
    display: block;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    margin-top: 20px;
  }

  @include e('items') {
  }

  @include e('item') {
    align-items: center;
    display: flex;
    padding: 8px;

    &:not(:last-child) {
      border-bottom: 1px solid #E5E5E5;
    }

    @include e('image') {
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 16px;
      height: 50px;
      width: 50px;
      min-width: 50px;
    }

    @include e('information') {
      margin-right: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @include e('name') {
      font-size: 14px;
      color: #545454;
      line-height: 24px;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @include e('price') {
      font-size: 12px;
      color: var(--sf-product-price-color);
      letter-spacing: var(--sf-product-letter-spacing);
      font-weight: var(--sf-product-weight);
      font-family: var(--sf-product-font-family);
      text-transform: uppercase;
      line-height: 24px;
    }

    @include e('quantity') {
      align-items: center;
      display: flex;
      margin-left: auto;
      user-select: none;

      @include e('number') {
        font-size: 14px;
      }

      @include e('change') {
        cursor: pointer;
        font-weight: 500;
        padding: 5px 10px;
      }
    }

    @include e('remove') {
      cursor: pointer;
      margin-left: 16px;
    }
  }
}
</style>
