<template>
  <div class="icon-button">
    <template v-if="icon">
      <i :class="`sfplayicon-${icon}`"></i>
    </template>
    <template v-else>
      <slot></slot>
    </template>
    <template v-if="text">
      <span>{{ text }}</span>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.icon-group {
  align-items: center;
  display: flex;
}
</style>

<style lang="scss" scoped>
.icon-button {
  align-items: center;
  background-color: var(--sf-secondary-button-background);
  border-radius: 100%;
  border: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .20);
  cursor: pointer;
  color: var(--sf-secondary-button-color);
  display: flex;
  overflow: hidden;
  padding: 2px;
  height: 34px;
  justify-content: center;
  pointer-events: all;
  width: 34px;
  font-size: 12px;
  user-select: none;
  touch-action: manipulation;
  transition: background-color .3s, color .3s;
  -webkit-tap-highlight-color: transparent;

  &.hide {
    display: none;
  }

  &.active {
    background-color: var(--sf-secondary-button-color);
    color: var(--sf-secondary-button-background);
  }

  &.half-border-right {
    border-radius: 0 26px 26px 0;
  }
  &.half-border-left {
    border-radius: 26px 0 0 26px;
  }

  &.default {
    background-color: #FFFFFF;
    color: #000000;
  }

  &.fixed-medium {
    height: 40px;
    width: 40px;
  }

  &.fixed-large {
    height: 52px;
    width: 52px;
    font-size: 16px;
  }

  &.medium-content {
    font-size: 20px;
  }

  &.large-content {
    font-size: 24px;
  }

  &.animate {
    transition: font-size .2s;

    &:active:not(.large-content) {
      font-size: 22px !important;
    }

    &.large-content {
      &:active {
        font-size: 30px;
      }
    }
  }

  &:not(.fixed-small):not(.fixed-medium) {
    @media (min-width: 768px) {
      height: 52px;
      width: 52px;

      &:not(.large-content):not(.medium-content) {
        font-size: 16px;
      }
    }
  }
}
</style>
