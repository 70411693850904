<template>
  <div class="product-item-preview" @click="selectProduct()">
    <div class="product-item-preview__image" :style="`${image}`"></div>
    <div class="product-item-preview__info">
      <span class="product-item-preview__info__price">{{ item.price ? item.price[0].formated_amount : '' }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    fqdn: {
      type: String,
      default: '',
    },
  },
  computed: {
    /**
     * Get current selected product from store
     * @returns {?String}
     */
    selectedProduct() {
      return this.$store.getters['Products/selectedProduct'];
    },
    /**
     * Return the item matching the ID
     * @returns {Object}
     */
    item() {
      return this.$store.getters['Products/product'](this.fqdn, this.id);
    },
    image() {
      const {
        item: {
          images,
        },
        fqdn,
      } = this;

      if (!images || images.length === 0) {
        return null;
      }

      const [image] = images;
      if (image.includes('http')) {
        return `backgroundImage: url('${image}')`;
      }

      return `backgroundImage: url('https://${fqdn}/images/h/90/${image}')`;
    },
  },
  methods: {
    async selectProduct() {
      const {
        fqdn,
        id,
      } = this;

      await this.$store.dispatch('Products/getProduct', { fqdn, product: id });
      await this.$store.dispatch('Products/selectProduct', { product: id, fqdn, type: 'good' });
      await this.$store.dispatch('Products/listProducts', { fqdn, type: 'good', bool: false });
      this.$store.dispatch('Products/toggleProductsList', false);
      this.$store.dispatch('Cart/toggleCart', false);
    },
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/styles/Mixins';

.product-item-preview {
  background-color: var(--sf-main-box-background);
  border-radius: var(--sf-main-box-border-radius);
  box-shadow: var(--sf-main-box-box-shadow);
  padding: 5px;
  width: 96px;
  display: block;
  transition: all .3s;
  z-index: 10;
  pointer-events: all;

  @include e('image') {
    border-radius: var(--sf-main-box-border-radius);
    width: 76px;
    height: 76px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin: 5px 0 5px 5px;
  }

  @include e('info') {
    color: var(--sf-main-box-color);
    display: flex;
    max-width: 100%;
    padding: 5px 3px 0;
    justify-content: center;
    text-align: center;
    @include e('name') {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    @include e('price') {
      color: var(--sf-product-price-color);
      letter-spacing: var(--sf-product-letter-spacing);
      font-weight: var(--sf-product-weight);
      font-family: var(--sf-product-font-family);
      text-transform: uppercase;
      white-space: nowrap;
      font-size: 12px;
      // margin-left: auto;
      text-align: center;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
</style>
