<template>
  <div :class="`countdown ${background ? 'countdown--background' : ''} countdown--size-${size}`">
    <template v-if="distance >= 0">
      <div v-if="parseInt(this.days, 10) > 0">
        <p>{{ days }}</p>
      </div>
      <div v-if="parseInt(this.days, 10) > 0"> :</div>
      <div v-if="parseInt(this.days, 10) > 0 || parseInt(this.hours, 10) > 0">
        <p>{{ hours }}</p>
      </div>
      <div v-if="parseInt(this.days, 10) > 0 || parseInt(this.hours, 10) > 0"> :</div>
      <div v-if="parseInt(this.days, 10) > 0 || parseInt(this.hours, 10) > 0 || parseInt(this.minutes, 10) > 0">
        <p>{{ minutes }}</p>
      </div>
      <div v-if="parseInt(this.days, 10) > 0 || parseInt(this.hours, 10) > 0 || parseInt(this.minutes, 10) > 0"> :</div>
      <div v-if="parseInt(this.days, 10) > 0 || parseInt(this.hours, 10) > 0 || parseInt(this.minutes, 10) > 0 || parseInt(this.seconds, 10) > 0">
        <p>{{ seconds }}</p>
      </div>
    </template>
    <template v-else>
      <div class="streamify-loading" v-if="!noLoader"></div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      required: true,
    },
    background: {
      type: Boolean,
      default: false,
    },
    noLoader: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setup() {
      const origTo = new Date(this.to);
      origTo.setSeconds(origTo.getSeconds() + 10);
      const to = origTo.getTime();
      const from = new Date().getTime();
      const distance = to - from;

      if (distance > 0) {
        this.setValues(to);
        this.startCounter(to);
      }
    },
    setValues(to) {
      const now = new Date().getTime();
      const distance = to - now;
      this.distance = distance;
      if (distance > 0) {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        this.days = (days < 10) ? `0${days}` : days;
        this.hours = (hours < 10) ? `0${hours}` : hours;
        this.minutes = (minutes < 10) ? `0${minutes}` : minutes;
        this.seconds = (seconds < 10) ? `0${seconds}` : seconds;
      }
      return distance;
    },
    startCounter(to) {
      clearInterval(this.counterInterval);
      this.counterInterval = setInterval(() => {
        this.$emit('tick');
        if (this.setValues(to) <= 0) {
          clearInterval(this.counterInterval);
        }
      }, 1000);
    },
    resizeEvent() {
      const { firstSize } = this;
      if (this.$el.parentNode) {
        const parentWidth = this.$el.parentNode.offsetWidth;
        const countdownWidth = this.$el.offsetWidth;

        if (firstSize === 0) {
          this.firstSize = countdownWidth;
        }

        if (parentWidth > this.firstSize + 40) {
          this.size = 'normal';
        } else {
          this.size = 'small';
        }
      }
    },
  },
  watch: {
    to: {
      immediate: true,
      handler() {
        this.setup();
      },
    },
  },
  data() {
    return {
      counterInterval: null,
      distance: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      size: 'normal',
      firstSize: 0,
    };
  },
  mounted() {
    new ResizeObserver(() => this.resizeEvent()).observe(this.$el.parentNode);
  },
  beforeDestroy() {
    clearInterval(this.counterInterval);
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/styles/Mixins';

.countdown {
  color: white;
  display: flex;
  position: relative;
  font-size: 32px;
  text-shadow: var(--sf-countdown--text-shadow);
  user-select: none;
  line-height: 1.5;

  @include m('background') {
    background-color: rgba(0, 0, 0, .5);
    padding: 0 32px;
    border-radius: 60px;
  }

  @include m('size-small') {
    font-size: 20px;
    padding: 0 20px;
  }
}
</style>
