<template>
  <div class="liveshopping" :data-orientation="orientation" v-if="broadcast" @click="open()">
    <template v-if="state === 'loading'">
      <div class="loading">
        <div class="spinner dark"></div>
      </div>
    </template>
    <template v-else-if="state === 'error'">
      <div class="error">
        <p>Could not load broadcast</p>
        <p>Reload?</p>
      </div>
    </template>
    <template v-else>
      <picture class="poster" v-if="poster">
        <source media="(min-width: 500px) and (max-width: 767px)" :srcset="`https://${broadcast.data.fqdn}/images/w/768/${poster}`" />
        <source media="(min-width: 768px)" :srcset="`https://${broadcast.data.fqdn}/images/w/1024/${poster}`" />
        <img :src="`https://${broadcast.data.fqdn}/images/w/500/${poster}`" />
      </picture>
      <div class="information">
        <Live :id="id" />
        <Countdown :background="true" :to="broadcast.data.scheduled_start_time" :noLoader="true" />
        <Button class="button--primary button--full-width">{{ buttonText || broadcast.data.title }}</Button>
      </div>
    </template>
  </div>
</template>

<script>

import filters from '@/filters';
import store from '@/store';
import directives from '@/directives';

import Countdown from '@/components/Countdown.vue';
import Live from '@/components/Live.vue';
import Button from '@/components/Buttons/Button.vue';

import {
  addVideoJS,
  addFont,
  emitEvent,
} from '@/utils';

filters();
store();
directives();

export default {
  name: 'Liveshopping',
  props: {
    /**
     * Id of the broadcast/video.
     * Required.
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * If the widget also should render an iframe which the current page will be located.
     * Default is false.
     */
    persistent: {
      type: Boolean,
      default: false,
    },
    /**
     * The orientation of the banner. Landscape or Portrait.
     * Default is portrait.
     */
    orientation: {
      type: String,
      default: 'portrait',
      validator(orientation) {
        return ['portrait', 'landscape'].indexOf(orientation) !== -1;
      },
    },
    buttonText: {
      type: String,
      default: '',
    },
  },
  components: {
    Button,
    Countdown,
    Live,
  },
  computed: {
    /**
     * Return the current event in liveshopping
     * @returns {?Object}
     */
    event() {
      return this.$store.getters['LiveShopping/event'];
    },
    /**
     * Return the broadcast with ID
     * @returns {?Object}
     */
    broadcast() {
      return this.$store.getters['Broadcast/get'](this.id);
    },
    /**
     * Return the state of the broadcast
     * @returns {String}
     */
    state() {
      const { broadcast } = this;

      if (!broadcast || broadcast.loading) {
        return 'loading';
      }

      if (broadcast.error) {
        return 'error';
      }

      return '';
    },
    /**
     * Retur a poster for the broadcast
     * @return {?String}
     */
    poster() {
      const {
        broadcast: {
          data: {
            resources,
            images = null,
          },
        },
      } = this;

      if (images && images.length > 0) {
        return images[0];
      }

      const [poster] = resources.filter((item) => (typeof item.poster !== 'undefined' ? item : null));
      if (poster) {
        return poster.poster;
      }

      return null;
    },
    /**
     * Return a list of products connected to this broadcast
     * @returns {Array}
     */
    products() {
      if (!this.broadcast || this.broadcast.loading || this.broadcast.error) {
        return [];
      }

      const {
        broadcast: {
          data: {
            fqdn,
            event: {
              shopping: {
                products: enabledProducts,
              },
            },
          },
        },
      } = this;

      const list = this.$store.getters['Products/broadcastProducts'](fqdn);
      if (!list) {
        return [];
      }
      const newList = list.filter((p) => enabledProducts.includes(p.id));
      return newList;
    },
  },
  methods: {
    // Setup the banner
    async setup() {
      const {
        id,
      } = this;

      // Fetch broadcast for this banner
      await this.$store.dispatch('Broadcast/get', { id });
    },
    /**
     * Open the LVSP
     * If its inside an iframe, post a message to parent
     */
    open() {
      const {
        id,
        persistent,
        insideIframe,
      } = this;

      if (insideIframe) {
        window.parent.postMessage(JSON.stringify({
          action: 'liveshopping.open',
          id,
        }), '*');
        return;
      }

      let appendModal = false;
      let modal = document.querySelector('streamify-modal');
      if (!modal) {
        appendModal = true;
        modal = document.createElement('streamify-modal');
      }

      modal.setAttribute('id', id);
      if (persistent) {
        modal.setAttribute('persistent', true);
      }

      if (appendModal) {
        document.querySelector('body').appendChild(modal);
      }
    },
  },
  watch: {
    /**
     * Watch for event and emit
     * @param {Object} obj
     * @param {String} obj.broadcastId
     * @param {String} obj.event
     * @param {Object} obj.data
     */
    event({ broadcastId, event, data }) {
      if (data !== null && broadcastId === this.id) {
        emitEvent(this, { event, data });
      }
    },
    products(newArr, oldArr) {
      if (newArr.length === 0) {
        return;
      }

      const products = newArr.filter((obj1) => !oldArr.some((obj2) => obj1.id === obj2.id));
      emitEvent(this, {
        event: 'product.update',
        data: {
          products,
          fqdn: this.broadcast.data.fqdn,
        },
      });
    },
  },
  data() {
    return {
      insideIframe: false,
    };
  },
  async mounted() {
    addFont();
    addVideoJS();

    // Lets check if the widget is inside an iframe
    // Might need a further check on the parent and search for LVS-javascript etc?
    if (window.frameElement) {
      this.insideIframe = true;
    }

    this.$store.dispatch('LiveShopping/setConfig');

    this.setup();
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/Base';

.liveshopping {
  font-family: var(--sf-main-font-family);
  line-height: 1.5;
  max-width: 100%;
  position: relative;
  width: 100%;

  .poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:after {
      border: 6px solid black;
      border-color: black transparent black transparent;
    }
  }

  .error {
    background-color: gray;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    font-family: inherit;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .information {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    left: 0;
    padding: 5px;
    position: absolute;
    top: 0;
    padding-top: 40px;
    width: 100%;
    max-height: 100%;

    .countdown {
      position: absolute !important;
      top: 50%;
      transform: translateY(-50%);
    }

    .button {
      margin: 0 10px 10px;
      width: calc(100% - 20px);
    }

    &:hover {
      cursor: pointer;
      .button {
        filter: brightness(120%);
      }
    }
  }

  &[data-orientation="portrait"] {
    padding-top: calc((100% * 16) / 9);
  }

  &[data-orientation="landscape"] {
    padding-top: calc((100% * 9) / 16);
  }

  &:hover {
    cursor: pointer;
  }
}
</style>
