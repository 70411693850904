import Vue from 'vue';

let initialized = false;

export default function () {
  if (initialized) {
    return;
  }

  Vue.directive('click-outside', {
    bind: (elem, binding, vnode) => {
      const el = elem;
      el.event = (event) => {
        if (!(el === event.target || el.contains(event.target))) {
          vnode.context[binding.expression](event);
        }
      };
      document.body.addEventListener('click', el.event);
    },
    unbind: (el) => {
      document.body.removeEventListener('click', el.event);
    },
  });

  initialized = true;
}
