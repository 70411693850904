import { Analytics } from '@/api/analytics';
import { detect } from 'detect-browser';

const TOKEN_EXPIRE = 43200;

const statisticsState = {
  token: '',
  timestamp: '',
  events: [],
  interval: null,
};

const mutations = {
  /**
   * Set a token and a timestamp
   * @param {Object} state
   * @param {String} token
   */
  setToken(state, token) {
    state.token = token;
    state.timestamp = Date.now();
  },
  addEvent(state, event) {
    state.events.push(event);
  },
  clearEvents(state) {
    state.events = [];
  },
};

const statisticsGetters = {
  /**
   * Return the current token
   * @param {Object} state
   * @returns {String}
   */
  token: (state) => state.token,
  /**
   * Return the events array
   * @param {*} state
   * @returns {Array}
   */
  events: (state) => state.events,
  /**
   * Return the current timestamp of the token
   * @param {Object} state
   * @returns {Date}
   */
  tokenTimestamp: (state) => state.timestamp,
};

const actions = {
  sendEvents: async ({ state, getters, commit }) => {
    const { events } = state;

    if (events.length === 0) {
      return;
    }

    commit('clearEvents');

    const { tokenTimestamp, token: oldToken } = getters;
    const tokenDiff = Date.now() - tokenTimestamp;
    const token = Math.floor(tokenDiff / 1000 < TOKEN_EXPIRE) ? oldToken : '';
    return;
    // eslint-disable-next-line
    try {
      const tokenResult = await Analytics.sendEvent(events, token);
      if (tokenResult.length > 0) {
        commit('setToken', tokenResult);
      }
    } catch (e) { /* Do nothing */ }
  },
  /**
   *
   * @param {Object} param0
   * @param {Function} param0.getters
   * @param {Function} param0.rootGetters
   * @param {Function} param0.commit
   * @param {Object} eventData
   */
  // eslint-disable-next-line
  event: async ({ state, commit, dispatch, rootGetters }, eventData) => {
    // An event can contain data:
    // eventdata {
    //   id: VideoId
    //   broadcast_id: BroadcastId
    //   account: FQDN
    //   event: EventType (muted, close, pause, play, resume, seek, unmuted, ping, add_to_cart, jump_to_product, view_product, pageview)
    //   data: {
    //     product: ProductId
    //   }
    // }
    if (!eventData.broadcast_id) {
      return;
    }

    if (!state.interval) {
      state.interval = setInterval(() => {
        dispatch('sendEvents');
      }, 5000);
    }

    const data = eventData;

    const time = rootGetters['Player/videoCurrentTime'](eventData.broadcast_id !== '' ? eventData.broadcast_id : eventData.id);
    const broadcast = rootGetters['Broadcast/get'](eventData.broadcast_id);
    const gtagConfig = rootGetters['LiveShopping/ga'];

    if (!broadcast) {
      return;
    }

    if (!data.data) {
      data.data = {};
    }

    data.time = time ? Math.round(time) : 0;

    if (data.event === 'pageview') {
      const browser = detect();
      if (browser) {
        data.data = {
          ...data.data,
          ...{
            // eslint-disable-next-line
            screen: `${screen.width}x${screen.height}`,
            browser: `${document.documentElement.clientWidth}x${document.documentElement.clientHeight}`,
          },
        };
      }
    }

    if (broadcast.data) {
      switch (broadcast.data.life_cycle_status) {
        case 'created':
        case 'ready':
        case 'liveStarting':
          data.state = 'upcoming';
          break;
        case 'live':
        case 'liveStopping':
          data.state = 'live';
          break;
        case 'complete':
          data.state = 'recorded';
          break;
        default:
          break;
      }
    }

    commit('addEvent', data);

    if (gtagConfig.use) {
      const gtagObj = {};
      if (gtagConfig.data.event) {
        gtagObj[gtagConfig.data.event] = data.event;
      }
      if (gtagConfig.data.broadcast) {
        gtagObj[gtagConfig.data.broadcast] = data.broadcast_id || data.id;
      }
      if (gtagConfig.data.product && ['view_product', 'jump_to_product', 'add_to_cart'].includes(data.event)) {
        gtagObj[gtagConfig.data.product] = data.data.product;
      }
      if (gtagConfig.data.videotime) {
        gtagObj[gtagConfig.data.videotime] = data.time;
      }
      window.gtag('event', 'liveshopping', gtagObj);
    }
  },
};

export default {
  namespaced: true,
  state: statisticsState,
  getters: statisticsGetters,
  mutations,
  actions,
};
