<template>
  <div :class="`liveshopping-modal ${!persistent ? 'prevent' : ''}`" :data-minified="minified" @click="clickedOutside($event)" v-if="broadcast">
    <template v-if="orientation === 'portrait'">
      <div class="liveshopping-modal__inner" @click="minified ? open() : null" :data-video-orientation="orientation" :data-only-video="!showSides" :data-videocontrols="videoControlsActive" :data-status="broadcastState" v-if="show">
        <IconButton icon="close-x" class="liveshopping-modal__button default close fixed-small active" @click.native.stop="close()" />
        <IconButton icon="miniplayer" :data-hideme="orientation === 'landscape' && (showSides || rightMobileModal || showCart)" :class="`liveshopping-modal__button minify default medium-content ${showSides ? 'fixed-medium' : orientation !== 'landscape' ? 'fixed-small' : 'fixed-medium'}`" :style="`${showSides ? `right: ${videoWidth < 500 ? `-${videoWidth + 20}px` : '-520px'}` : ''}`" @click.native.stop="minifyElement()" />
        <div class="sidebar sidebar--left" ref="chat" :data-show="showSides" :style="`${showSides ? `width: ${videoWidth}px;  left: -${videoWidth < 500 ? videoWidth : '500'}px;` : ''}`" v-if="qaOrChat !== ''">
          <template v-if="qaOrChat === 'chat'">
            <h2 class="sidebar__title">{{ config.strings.en.chat_title || 'Chat' }}</h2>
            <Chat :id="id" />
          </template>
          <template v-else-if="qaOrChat === 'qa'">
            <h2 class="sidebar__title">{{ config.strings.en.qa_title || 'QA' }}</h2>
            <QA :id="id" />
          </template>
        </div>
        <VideoPlayer
          ref="videoplayer"
          :id="id"
          :options="videoData"
          :productModalOpen="rightMobileModal"
          :sides="showSides"
          @openModal="$event ? openRightModal() : closeRightModal()"
          v-if="videoData"
        />
        <div
          class="sidebar sidebar--right"
          ref="productssidebar"
          :data-product-selected="selectedProduct !== null"
          :data-cart-open="showingCart"
          :data-show="showSides || showProductsList || showCart || selectedProduct !== null"
          :style="`${showSides ? `width: ${videoWidth}px; right: -${videoWidth < 500 ? videoWidth : '500'}px;` : ''}`"
          @click="(showSides || rightMobileModal || showCart) && !showSides ? clickRightEvent($event) : null"
        >
          <div :class="`sidebar__inner ${cartItems > 0 ? 'sidebar--right__inner--has-cart' : ''}`">
            <h2 class="sidebar__title">{{ config.strings.en.products_title || 'Products' }}</h2>
            <Products :id="id" @closeModal="closeRightModal()" ref="products" :iframe="persistent" />
            <Cart :id="id" v-if="cartItems > 0" :orientation="orientation" :show="showCart" :ditchTimeout="showSides || rightMobileModal" />
          </div>
          <div :class="`cart-button ${showCart ? 'cart-button--active' : ''}`" @click="toggleCart()" v-if="cartItems > 0">
            <i class="sfplayicon-shopping-bag cart-button__icon"></i>
            <div class="cart-button__inner">
              <p class="cart-button__inner__text">{{ showCart ? config.strings.en.hide_cart || 'Hide cart' : config.strings.en.shopping_cart || 'Shopping cart' }}</p>
              <span class="cart-button__inner__num-items">{{ cartItems > 9 ? '9+' : cartItems }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <Portrait :id="id" @click.native="minified ? open() :null" @close="close()" @minify="minifyElement()" :persistent="persistent" v-else-if="orientation === 'portraitt'" />
    <Landscape :id="id" @click.native="minified ? open() : null" @close="close()" @minify="minifyElement()" :persistent="persistent" v-else-if="orientation === 'landscape'" />
    <template v-if="persistent">
      <iframe ref="iframe" :src="iframeSrc" :class="`${showIframe ? 'show' : ''} ${disableIframe ? 'disable' : ''}`"></iframe>
    </template>
  </div>
</template>

<script>

import filters from '@/filters';
import store from '@/store';
import directives from '@/directives';

import {
  emitEvent,
} from '@/utils';

import Chat from '@/components/Chat/Main.vue';
import Cart from '@/components/Products/Cart.vue';
import Products from '@/components/Products/List.vue';
import VideoPlayer from '@/components/Player/Video2.vue';
import IconButton from '@/components/Buttons/Icon.vue';

import Landscape from '@/widgets/Liveshopping/Orientation/Landscape.vue';
import Portrait from '@/widgets/Liveshopping/Orientation/Portrait.vue';

filters();
store();
directives();

export default {
  props: {
    /**
     * Id of the broadcast. Required and should be a string
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * If the widget shall be persistent and create an iframe for the page so the user can still move around
     * Default is false.
     */
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Cart,
    Chat,
    Products,
    VideoPlayer,
    IconButton,
    Landscape,
    Portrait,
  },
  computed: {
    showProductsList() {
      return this.$store.getters['Products/showProductsList'];
    },
    /**
     * Return a string if the broadcast is upcoming, live or complete
     * @returns {String}
     */
    broadcastState() {
      if (this.broadcast.loading || this.broadcast.error) {
        return '';
      }

      const {
        broadcast: {
          data: {
            life_cycle_status,
          },
        },
      } = this;

      if (['created', 'ready', 'liveStarting'].includes(life_cycle_status)) {
        return 'upcoming';
      }

      if (['live', 'liveStopping'].includes(life_cycle_status)) {
        return 'live';
      }

      if (['complete'].includes(life_cycle_status)) {
        return 'complete';
      }

      return life_cycle_status;
    },
    /**
     * Return boolean if the controls of the video are showing or not
     * @returns {Boolean}
     */
    videoControlsActive() {
      return this.$store.getters['Player/videoControlsActive'](this.id);
    },
    /**
     * Get the selected product fomr the store
     * @returns {?String}
     */
    selectedProduct() {
      return this.$store.getters['Products/selectedProduct'];
    },
    /**
     * Return a boolean if the cart should be shown
     * @returns {Boolean}
     */
    showCart() {
      return this.$store.getters['Cart/show'];
    },
    /**
     * Return number of items in cart
     * @returns {Number}
     */
    cartItems() {
      return this.$store.getters['Cart/numItems'];
    },
    /**
     * Return the current liveshopping event
     * @returns {?Object}
     */
    event() {
      return this.$store.getters['LiveShopping/event'];
    },
    /**
     * Return the current player event
     * @returns {?Object}
     */
    playerEvent() {
      return this.$store.getters['LiveShopping/playerEvent'];
    },
    /**
     * Return the broadcast with ID
     * @returns {?Object}
     */
    broadcast() {
      return this.$store.getters['Broadcast/get'](this.id);
    },
    /**
     * Return the orientation for the broadcast
     * @returns {String}
     */
    orientation() {
      return this.broadcast.data?.metadata?.orientation || 'landscape';
    },
    /**
     * Return config for lvsp
     * @returns {Object}
     */
    config() {
      return this.$store.getters['LiveShopping/config'];
    },
    /**
     * Build up some data for the video component
     * @returns {Object}
     */
    videoData() {
      if (this.broadcast.loading || this.broadcast.error) {
        return null;
      }

      const {
        broadcast: {
          data: {
            fqdn,
            resources,
          },
        },
        orientation,
      } = this;

      const [formatP] = resources.filter((resource) => Object.keys(resource)[0].search(/^video.*p$/) >= 0 && Object.keys(resource)[0].replace(/\D/g, '') <= 720).sort((a, b) => Object.keys(b)[0].replace(/\D/g, '') - Object.keys(a)[0].replace(/\D/g, ''));
      const [hls] = resources.filter((resource) => (Object.keys(resource)[0] === 'hls' ? resource : null));
      const [formatunp] = resources.filter((resource) => Object.keys(resource)[0].search(/^video.*[^p]$/) >= 0 && Object.keys(resource)[0].replace(/\D/g, '') <= 720).sort((a, b) => Object.keys(b)[0].replace(/\D/g, '') - Object.keys(a)[0].replace(/\D/g, ''));
      const source = { src: '', type: '' };

      if (formatP) {
        source.src = Object.values(formatP)[0].substring(0, 4) === 'http' ? Object.values(formatP)[0] : `https://${fqdn}/resources/${Object.values(formatP)[0]}`;
        source.type = 'video/mp4';
      } else if (hls) {
        source.src = hls.hls.substring(0, 4) === 'http' ? hls.hls.replace('/live/', '/resources/') : `https://${fqdn}${hls.hls.replace('/live/', '/resources/')}`;
        source.type = 'application/x-mpegURL';
      } else if (formatunp) {
        source.src = Object.values(formatunp)[0].substring(0, 4) === 'http' ? Object.values(formatunp)[0] : `https://${fqdn}/resources/${Object.values(formatunp)[0]}`;
        source.type = 'video/mp4';
      }

      return {
        autoplay: true,
        controls: true,
        fluid: true,
        liveui: true,
        orientation,
        controlBar: {
          fullscreenToggle: false,
        },
        sources: [source],
      };
    },
    /**
     * Returns if QA or Chat should be shown
     * @returns {String}
     */
    qaOrChat() {
      const qaEnabled = this.broadcast?.data?.event?.questions?.enable;
      const chatEnabled = this.broadcast?.data?.event?.chat?.enable;

      if (qaEnabled) {
        return 'qa';
      }

      if (chatEnabled) {
        return 'chat';
      }

      return '';
    },
  },
  methods: {
    /**
     * When sidebars are not showable and user clicks on the background of opened sidebar
     * @param {Object} event
     */
    clickRightEvent(event) {
      if (event.target.classList.contains('sidebar--right')) {
        this.closeRightModal();
      }
    },
    toggleCart(forceOpen = false) {
      const {
        showCart,
      } = this;
      this.$store.dispatch('Cart/toggleCart', forceOpen || !showCart);
    },
    backToProductList() {
      const {
        broadcast: {
          data: {
            fqdn,
          },
        },
      } = this;
      this.$store.dispatch('Products/selectProduct', { product: null, fqdn, type: 'good' });
      this.rightMobileModal = true;
      this.$store.dispatch('Cart/toggleCart', false);
    },
    openRightModal() {
      this.rightMobileModal = true;
      this.$store.dispatch('Cart/toggleCart', false);
    },
    /**
     * Close the right modal on small devices
     */
    closeRightModal() {
      const {
        broadcast: {
          data: {
            fqdn,
          },
        },
      } = this;
      this.rightMobileModal = false;
      this.$store.dispatch('Cart/toggleCart', false);
      this.$store.dispatch('Products/selectProduct', { product: null, fqdn, type: 'good' });
    },
    open() {
      this.minified = false;
    },
    close() {
      if (this.persistent) {
        window.location.reload();
      }

      this.$store.dispatch('Products/selectProduct', { product: null, fqdn: this.broadcast.data.fqdn, type: 'good' });
      this.minified = null;
      this.show = false;
      try {
        this.$el.parentNode.host.setAttribute('id', '');
      } catch (e) { /* Do nothing */ }
    },
    resize() {
      const {
        minified,
        $refs: {
          videoplayer,
        },
        minVideoWidth,
      } = this;

      if (minified) {
        return;
      }

      if (!videoplayer) {
        return;
      }

      const {
        innerWidth,
      } = window;
      this.$nextTick(() => {
        const videoOffsetWidth = videoplayer.$el.offsetWidth;
        const videoWidth = videoOffsetWidth >= minVideoWidth ? videoOffsetWidth : minVideoWidth;
        const currentSpace = ((innerWidth - videoWidth) / 2) - 40;

        if (currentSpace >= 350) {
          this.videoWidth = videoWidth <= 400 ? videoWidth : currentSpace;
          this.showSides = true;
        } else {
          this.showSides = false;
        }
      });
    },
    clickedOutside(event) {
      if (event.target.classList.contains('liveshopping-modal')) {
        this.minifyElement();
      }
    },
    minifyElement() {
      this.minified = true;

      const {
        broadcast: {
          data: {
            id,
            scheduled_start_time,
            fqdn,
          },
        },
      } = this;
      this.$store.dispatch('Analytics/event', {
        id: scheduled_start_time ? '0' : id,
        broadcast_id: scheduled_start_time ? id : '',
        fqdn: fqdn.split('.')[0],
        event: 'minimize',
      });
    },
    toggleBody(before, hidden) {
      if (before) {
        this.overflowBefore = document.querySelector('body').style.overflow;
        this.pointerEventsBefore = document.querySelector('body').style.pointerEvents;
      }

      const {
        overflowBefore,
        pointerEventsBefore,
      } = this;

      document.querySelector('body').style.overflow = hidden ? 'hidden' : overflowBefore;
      document.querySelector('body').style.pointerEvents = hidden ? 'none' : pointerEventsBefore;
    },
    setupIframe() {
      this.$store.dispatch('LiveShopping/iframe', this.$refs.iframe);
      this.setupFrameListeners();

      window.addEventListener('message', async (e) => {
        try {
          const data = JSON.parse(e.data);
          switch (data.action) {
            case 'liveshopping.open':
              if (this.id === data.id) {
                this.minified = false;
              } else {
                await this.$store.dispatch('Broadcast/get', {
                  id: data.id,
                });
                this.$nextTick(() => {
                  this.$el.parentNode.host.setAttribute('id', data.id);
                });
              }
              break;
            default:
              break;
          }
        // eslint-disable-next-line
        } catch (e) { /* Do nothing */ }
      });
    },

    dispatchIframeChange() {
      const {
        $refs: {
          iframe,
        },
      } = this;
      setTimeout(() => {
        document.title = iframe.contentDocument.title;
        window.history.replaceState({
          pageUrl: iframe.contentWindow.location.href,
          pageTitle: iframe.contentDocument.title,
        }, iframe.contentDocument.title, iframe.contentWindow.location.href);
      }, 100);
    },
    iframeUnloadHandler() {
      setTimeout(this.dispatchIframeChange, 0);
    },
    attachIframeUnload() {
      const {
        $refs: {
          iframe,
        },
      } = this;
      iframe.contentWindow.removeEventListener('unload', this.iframeUnloadHandler);
      iframe.contentWindow.addEventListener('unload', this.iframeUnloadHandler);
    },
    setupFrameListeners() {
      const {
        $refs: {
          iframe,
        },
      } = this;
      iframe.addEventListener('load', this.attachIframeUnload);
    },
    removeFrameListeners() {
      const {
        $refs: {
          iframe,
        },
      } = this;
      iframe.removeEventListener('load', this.attachIframeUnload);
      iframe.contentWindow.removeEventListener('unload', this.iframeUnloadHandler);
    },
    addMeta() {
      const metatag = document.querySelector('meta[name="viewport"]');
      if (metatag) {
        if (!metatag.getAttribute('content').includes('maximum-scale=1.0')) {
          this.viewport.addedScale = true;
          metatag.setAttribute('content', `${metatag.getAttribute('content')}, maximum-scale=1.0`);
        }
        if (!metatag.getAttribute('content').includes('user-scalable=0')) {
          this.viewport.addedScalable = true;
          metatag.setAttribute('content', `${metatag.getAttribute('content')}, user-scalable=0`);
        }
        if (!metatag.getAttribute('content').includes('viewport-fit=cover')) {
          this.viewport.addedFit = true;
          metatag.setAttribute('content', `${metatag.getAttribute('content')}, viewport-fit=cover`);
        }
      }
    },
  },
  watch: {
    showCart(bool) {
      if (!bool && !this.rightMobileModal) {
        this.showCartTimeout = setTimeout(() => {
          this.showingCart = false;
        }, 500);
      } else {
        clearTimeout(this.showCartTimeout);
        this.showingCart = bool;
      }
    },
    id: {
      immediate: true,
      handler(a, b) {
        if (a === null || a === '' || typeof a === 'undefined') {
          return;
        }

        if (a && typeof b === 'undefined') {
          this.minified = false;
        }

        if (a !== b && typeof b !== 'undefined') {
          this.show = false;
          this.$nextTick(() => {
            this.show = true;
            this.minified = false;
          });
        }
        this.$store.dispatch('LiveShopping/event', {
          broadcastId: a,
          event: 'player.open',
          data: {},
        });
      },
    },
    minified: {
      immediate: true,
      handler(a, b) {
        if (a === null) {
          return;
        }

        this.addMeta();

        this.toggleBody(b, !a);
        if (a) {
          this.$store.dispatch('LiveShopping/event', {
            broadcastId: this.id,
            event: 'player.minimize',
            data: {},
          });
        }
        this.$nextTick(() => {
          if (!a) {
            setTimeout(() => {
              this.resize();
            }, 200);
          }
        });

        if (this.persistent && a) {
          this.showIframe = true;
          this.disableIframe = false;
        }

        if (this.persistent && !a) {
          this.disableIframe = true;
        }
      },
    },
    showIframe(a) {
      if (a) {
        const hideElements = document.querySelectorAll('body > *');
        hideElements.forEach((el) => {
          if ((el.nodeName.toLowerCase() === 'streamify-modal')) {
            return;
          }

          el.style.setProperty('display', 'none', 'important');
        });
      }
    },
    event({ broadcastId, event, data }) {
      if (data !== null && broadcastId === this.id) {
        emitEvent(this, { event, data });
      }
    },
    /**
     * Watch over new player events
     * @param {Object} event
     * {
     *   event: "player.event",
     *   src: "http...",
     *   ..
     * }
     */
    playerEvent(event) {
      switch (event.event) {
        case 'player.minimize':
          this.minifyElement();
          break;
        case 'iframe.src':
          if (this.$refs.iframe) {
            this.$refs.iframe.src = event.src;
          }
          break;
        case 'iframe.reload':
          if (this.$refs.iframe) {
            this.$refs.iframe.contentWindow.location.reload();
          }
          break;
        default:
          break;
      }
    },
  },
  data() {
    return {
      showSides: false,
      videoWidth: 0,
      minVideoWidth: 300,
      minified: null,
      overflowBefore: '',
      pointerEventsBefore: '',
      show: true,
      rightMobileModal: false,
      showingCart: false,
      showIframe: false,
      disableIframe: true,
      iframeSrc: window.location.href,
      viewport: {
        addedScale: false,
        addedScalable: false,
        addedFit: false,
      },
      videoplayerInterval: null,
    };
  },
  mounted() {
    new ResizeObserver(() => this.resize()).observe(document.body);

    if (this.id) {
      this.minified = false;
    }

    if (this.persistent) {
      this.setupIframe();
    }

    this.videoplayerInterval = setInterval(() => {
      if (this.$refs.videoplayer) {
        clearInterval(this.videoplayerInterval);
        new ResizeObserver(() => this.resize()).observe(this.$refs.videoplayer.$el);
      }
    }, 250);

    this.$store.dispatch('LiveShopping/setColors');
  },
};
</script>

<style lang="scss">

@import '@/assets/styles/Mixins';

.sidebar {
  display: none;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  width: 0px;
  z-index: 1;

  @include e('inner') {
    position: relative;
    overflow: auto;
    padding: 8px 16px 16px;
  }

  @include e('title') {
    display: none;
    font-size: var(--sf-title-size);
    color: var(--sf-title-color);
    line-height: var(--sf-title-height);
    text-transform: uppercase;
    font-weight: var(--sf-title-weight);
    margin: 0 0 8px;
    text-align: center;
    width: 100%;
  }

  &[data-show="true"] {
    display: flex;
    pointer-events: all;
  }
}
.sidebar--left {
  @include e('buttons') {
    flex-direction: column;

    & > * {
      align-items: center;
      justify-content: center;
      display: flex;
      background-color: #FFFFFF;
      border-radius: 100%;
      border: 0;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20);
      color: #000000;
      height: 52px;
      width: 52px;
      margin-bottom: 8px;
      pointer-events: all;
      -webkit-tap-highlight-color: transparent;

      &:hover {
        cursor: pointer;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>

<style lang="scss">

@import '~video.js/dist/video-js.css';
@import '@/assets/styles/Base';
@import '@/assets/styles/Mixins';

.liveshopping-modal {
  background-color: var(--sf-main-backdrop);
  bottom: var(--sf-modal-bottom);
  display: flex;
  position: fixed;
  left: var(--sf-modal-left);
  right: var(--sf-modal-right);
  top: var(--sf-modal-top);
  z-index: 2147483647;
  outline: none;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  font-family: var(--sf-main-font-family);
  line-height: 1.5;

  iframe {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    height: 100vh;
    width: 100vw;
    border: 0;

    &.show {
      opacity: 1;
      z-index: 1;
    }

    &.disable {
      pointer-events: none;
    }
  }

  @include e('inner') {
    box-shadow: 0 8px 40px 0 rgba(0,0,0,0.10);
    height: 100%;
    position: relative;
    width: 100%;
    border-radius: 0;
    z-index: 2;

    &[data-only-video="true"] {
      .streamify-chat-pinned-message {
        border-radius: 12px;
        margin: 2px 0 0;
        order: 1;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-self: flex-start;
        padding: 3px 20px 3px 30px;
        i {
          font-size: 16px;
        }
      }

      .streamify-chat-message {
        line-height: 18px;
        margin-bottom: 2px;
        width: auto;

        &:first-child {
          margin-top: 50px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .streamify-chat-message__inner {
          background-color: rgba(var(--sf-chat-background), .6);
          border-radius: 12px;
          color: var(--sf-chat-text-color);
          padding: 1px 8px;
          font-size: 12px;
        }

        .streamify-chat-message__product {
          border-radius: 12px;
          display: inline-block;

          .streamify-chat-message__product__image {
            border-radius: 12px 0 0 12px;
          }
        }

        .streamify-chat-message__user {
          background-color: transparent;
          margin: 0;
          padding: 0;
        }

        .streamify-chat-message__reply-to {
          border-radius: 12px;
          margin: 0 0 -10px 0;
          font-size: 10px;
          padding: 0 5px;
          line-height: 16px;
        }

        &.streamify-chat-message--moderator {
          .streamify-chat-message__inner {
            // background-color: var(--sf-chat-moderator-background);
            background-color: rgba(var(--sf-chat-moderator-background), .6);
            color: var(--sf-chat-moderator-color);
          }
        }

        &.streamify-chat-message--welcome {
          border-radius: 12px;
          padding: 3px 8px;
        }
      }
    }
  }

  .liveshopping-modal__button.icon-button {
    display: none;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 12;
  }

  .streamify-player__list-products {
    display: flex;
  }

  .sidebar {
    display: none;
  }

  &:not([data-minified="true"]) {
    .liveshopping-modal__button.minify {
      display: flex;

      &[data-hideme="true"] {
        display: none;
      }
    }

    .liveshopping-modal__inner {
      &[data-only-video="true"] {
        &:not([data-videocontrols="true"]) {
          &[data-status="live"], &[data-status="liveStopping"], &[data-status="complete"] {
            .liveshopping-modal__button.minify {
              opacity: 0;
              pointer-events: none;
            }
          }
        }
      }
    }
  }

  &[data-minified="true"] {
    background-color: transparent;
    background-image: none;
    // pointer-events: none;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0 30px 30px 0;

    &.prevent {
      pointer-events: none;
    }

    .liveshopping-modal__button.close {
      display: flex;
    }

    video {
      border-radius: 10px;
      height: 100%;
      max-height: 100%;
      width: 100%;
    }

    .streamify-player__logo {
      top: auto;
      right: 16px;
      bottom: 16px;

      img {
        max-height: 20px;
      }
    }

    .liveshopping-modal__inner {
      border-radius: 18px;
      cursor: pointer;
      overflow: hidden;
      pointer-events: all;

      &[data-status="live"], &[data-status="liveStopping"], &[data-status="complete"] {
        .streamify-player {
          background-image: none !important;
        }
      }

      .sidebar, .streamify-player__list-products, .product-item-preview, .streamify-player__player.vjs-fluid .vjs-control-bar, .streamify-player__close-products, .hadirajan {
        display: none !important;
      }

      video {
        object-fit: initial;
        max-height: 100% !important;
        max-width: 100%;
        pointer-events: none;
      }

      .streamify-player > .buttons {
        display: none;
      }

      &[data-video-orientation="portrait"] {
        height: 250px;
        max-width: 140px;
        min-width: 140px;

        .streamify-player__countdown {
          height: 250px;
          width: 140px;
          .countdown {
            transform: scale(.75);
          }
        }

        @media (min-width: 600px) {
          max-width: 140px;
          .streamify-player__countdown {
            height: 285px;
            width: 140px;
          }
        }
      }

      &[data-video-orientation="landscape"] {
        height: calc(225px * 0.5626);
        max-width: 225px;

        @media (min-width: 600px) {
          height: calc(350px * 0.5626);
          max-width: 350px;
        }

        @media (max-height: 500px), (max-width: 767px) {
          height: calc(225px * 0.5626);
          max-width: 225px;
        }

        .streamify-player__countdown {
          .countdown {
            transform: scale(.75);
          }
        }
      }
    }
  }

  .product-previews {
    transition: opacity .5s, bottom .5s;
  }
}
.liveshopping-modal__inner[data-video-orientation="landscape"] {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  transition: all .2s;

  .streamify-chat__chat-not-ready, .streamify-products__products-not-ready {
    background-color: var(--sf-main-background);
    color: var(--sf-title-color);
    justify-content: flex-end;
    flex: none;
    padding: 16px;
    border-radius: 12px;
  }

  @media (min-width: 1024px) {
    .hadirajan {
      left: 25%;
      right: 25%;
    }
  }

  @media (max-width: 1023px) {
    .streamify-player.streamify-player--playing {
      background-color: black;
    }
  }

  @media (min-width: 1024px) {
    height: calc(56.25vw - 56.25px);
    width: calc(100vw - 100px);
    // max-width: calc(177.78vh - 100px);
    // max-height: calc(100vh - 56.25px);
    max-width: calc((177.78vh - 100px) - (var(--sf-modal-left) + var(--sf-modal-right)));
    max-height: calc((100vh - 56.25px) - (var(--sf-modal-top) + var(--sf-modal-bottom)));
    border-radius: var(--sf-main-border-radius);

    .streamify-player {
      border-radius: var(--sf-main-border-radius);
    }

    .sidebar--right {
      border-radius: 0 var(--sf-main-border-radius) var(--sf-main-border-radius) 0;
    }

    .liveshopping-modal__button.minify {
      top: -20px;
      right: -20px;
    }

    .vjs-control-bar::before {
      border-radius: 0 0 var(--sf-main-border-radius) var(--sf-main-border-radius);
    }

    video {
      border-radius: var(--sf-main-border-radius);
    }
  }

  .product-previews {
    top: auto;
    left: auto;
    right: 32px;
    bottom: 32px;
    opacity: 1;

    &[data-hide="true"] {
      opacity: 0;
      pointer-events: none;
    }
  }

  /**
    * "Right" sidebar
    */
  .sidebar--right {
    display: flex;
    max-width: 400px;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    transition: background-color .5s;
    // padding-right: 80px;

    &[data-cart-open="true"] {
      .streamify-products {
        display: none;
      }

      .streamify-cart {
        overflow: hidden;
        color: var(--sf-main-box-color);
        background: var(--sf-main-box-background);
        box-shadow: var(--sf-main-box-box-shadow);
        border-radius: var(--sf-main-box-border-radius);
      }
    }

    .cart-button {
      display: none;
    }

    .sidebar--right__buttons {
      display: none;
    }

    .streamify-products__list > * {
      &:last-child {
        margin-bottom: 48px;
      }
    }

    .sidebar__inner {
      flex: 1;
      transform: translateX(calc(100% + 80px));
      transition: transform .5s;
      padding: 16px;
      padding-bottom: 70px;
      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    &[data-show="true"] {
      background-color: var(--sf-main-box-background)1a;
      backdrop-filter: blur(30px);
      .sidebar__inner {
        transform: translateX(0%);
      }
    }
  }

  @media (max-height: 500px), (max-width: 767px) {
      .streamify-player__buttons {
        right: 16px;
        bottom: 16px;
      }

      .sidebar--right {
        max-width: calc(280px + env(safe-area-inset-right, 0));
        .sidebar__inner {
          padding: 16px max(16px, env(safe-area-inset-right)) 40px 16px;
        }
      }

      // .streamify-product-item__item-info__variants__row {
      .product .data .selectors .row {
        margin-bottom: 5px;
        p {
          font-size: 12px;
        }
      }

      // .streamify-product-item__list-info__image {
      .product .list .image {
        min-height: 50px;
        min-width: 50px;
        width: 50px;
      }

      // .streamify-product-item__list-info__text__title {
      .product .list .info h3 {
        font-size: 16px;
        line-height: 20px;
      }

      // .streamify-product-item__item-info__variants {
      .product .data .selectors {
        padding-bottom: 5px;
      }

      // .streamify-product-item__item-info__purchase__buttons {
      .product .data .buttons {
        margin-top: 5px;

        .button {
          padding: 10px 4px;
        }
      }

      .dropdown .inner {
        min-height: 26px;

        .selected {
          line-height: 29px;
        }
      }

      // .streamify-product-item__list-info__text__price, .streamify-product-item__item-info__product-info__price {
      .product .list p, .product .data .info p {
        font-size: 14px;
        line-height: 20px;
      }

      // .streamify-product-item__item-info__product-info__title {
      .product .data .info h3 {
        font-size: 16px;
        line-height: 20px;
      }

      // .streamify-product-item__item-info__product-info {
      .product .data .info {
        padding: 10px 10px 5px;
      }

      // .streamify-product-item__item-info__purchase {
      .product .data .buttons {
        padding: 5px 10px 10px;
      }

      // .streamify-product-item__item-info__images__image {
      .product .data .images .image {
        padding-top: 50%;
      }
    }

  .product-previews {
    bottom: 100px;
    right: 32px;

    & > div {
      align-items: center;
      display: flex;

      & > * {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  /**
    * "Left sidebar"
    */

  &[data-only-video="true"] {
    .sidebar--left {
      transition: height .5s;

      .streamify-chat {
        transition: padding .5s;
        height: calc(100% - 84px);
      }

      .streamify-chat-bottom {
        border-radius: 26px;

        &.streamify-chat-bottom--morph {
          border-radius: 12px;
        }
      }
    }
    &[data-videocontrols="true"] {
      .sidebar--left {
        height: calc(100% - 48px);
      }

      .streamify-chat {
        padding-bottom: 0px;
      }

      .streamify-player__buttons {
        bottom: 48px;
      }

      .product-previews {
        bottom: 116px;
      }
    }
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: 1024px) {
    video {
      object-fit: cover;
      width: 100%;
      object-position: center;
      max-width: 1080px;
    }

    &[data-only-video="true"] {
      .sidebar--left {
        .streamify-chat {
          height: 100%;

          .streamify-chat-messages {
            max-height: none;
          }
        }
      }
    }
  }

  .hadiraja {
    margin-left: 5px;
  }

  .sidebar--left {
    bottom: 0;
    display: flex;
    left: 0;
    top: 0;
    width: 30%;
    flex-direction: row;
  }

  .sidebar--left__buttons {
    flex-direction: row;
    margin-top: auto;
    padding: 0 0 12px 8px;

    .sidebar--left__buttons__cart {
      display: none;
    }
  }

  .streamify-chat {
    justify-content: flex-end;
    margin-top: auto;
    padding: 0 0 32px 32px;
    height: calc(100% - 84px);

    .streamify-chat-messages {
      order: 0;
    }

    .streamify-chat-bottom-outer {
      margin-top: 5px;
      order: 2;
    }

    .streamify-chat-bottom {
      pointer-events: all;
      max-width: 350px;
    }

    .streamify-chat-messages {
      flex: none;
      display: flex;
      flex-direction: column;
      max-height: 40%;
      pointer-events: all;

      .streamify-chat-messages__inner {
        margin-top: auto;
      }

      &.streamify-chat-messages--mobile-extra {
        flex: 1;
        mask-image: linear-gradient(to bottom, transparent 0%, black 20%)
      }

      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      scrollbar-width: none;

      div[mode="out-in"] {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
      }
    }
  }

  @media (max-height: 500px), (max-width: 767px) {
    .sidebar--left__buttons {
      padding: 0 0 16px 16px;
    }

    .sidebar--left {
      width: 50%;
      @media (max-width: 500px) {
        width: 80%;
      }
    }

    .streamify-chat {
      padding: 0 0 6px 16px;
    }
  }

}

.liveshopping-modal__inner[data-video-orientation="portrait"] {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  transition: all .2s;

  .streamify-cart {
    .streamify-cart__inner {
      width: 100%;
    }

    & > .buttons {
      display: none;
    }
  }

  .streamify-products {
    & > .buttons {
      // display: none;
    }
  }

  .streamify-chat__chat-not-ready {
    justify-content: flex-end;
  }
  &:not([data-only-video="true"]) {
    .streamify-chat-bottom {
      box-shadow: unset;
    }

    .streamify-chat-message {
      &.streamify-chat-message--moderator {
        .streamify-chat-message__user {
          // background-color: var(--sf-chat-moderator-background);
          background-color: rgba(var(--sf-chat-moderator-background), 1);
          color: var(--sf-chat-moderator-color);
        }
      }
    }
  }

  &[data-only-video="true"] {
    .streamify-chat__chat-not-ready {
      // background-color: rgba(0, 0, 0, .5);
      // color: white;
      background-color: var(--sf-main-background);
      color: var(--sf-title-color);
      flex: none;
      padding: 16px;
      border-radius: 12px;
    }

    .streamify-chat__send-message__input {
      border-radius: 26px;
    }

    .streamify-chat-bottom-outer:not([data-show="true"]) {
      display: none;
    }

    .streamify-chat:not([data-show="true"]) {
      display: none;
    }

    .streamify-player .buttons {
      align-items: flex-end;
      bottom: 2px;
      right: 10px;
      flex-direction: column;
      & > * {
        margin-left: 0;
        margin-bottom: 10px;
      }

      .chat-group {
        flex-direction: column;

        .half-border-left {
          border-radius: 0 0 26px 26px;
          order: 2;
        }
        .half-border-right {
          border-radius: 26px 26px 0 0;
          order: 1;
        }
      }
    }
  }

  &:not(&[data-only-video="true"]) {
    .streamify-player .buttons {
      display: none;
    }

    .streamify-chat__send-message__button__close {
      display: none !important;
    }
  }

  .product-previews {
    .product-item-preview {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (orientation: portrait) and (hover: none) and (pointer: coarse) and (max-width: 550px) {
    video {
      object-fit: cover;
      width: 100%;
      object-position: top;
    }
  }

  @media (max-width: 767px) {
    .streamify-player.streamify-player--playing {
      background-color: black;
    }
  }

  @media (min-width: 768px) {
    height: 90vh;
    width: auto;
    max-width: 90vw;
    max-height: 90vh;
    border-radius: var(--sf-main-border-radius);
    min-width: 300px;

    .streamify-player.streamify-player--playing .video-js video {
      width: auto;
    }

    .liveshopping-modal__button.minify {
      top: -17px;
    }

    &[data-only-video="true"] {
      .liveshopping-modal__button.minify {
        right: -17px;
      }
    }

    .streamify-player__countdown {
      width: 50vh;
      max-width: 80vw;

      @media (min-width: 1024px) {
        width: 50vh;
        max-width: 60vw;
      }
    }

    .sidebar {
      bottom: 0;
      max-width: 500px;
      top: 0;
      width: 100%;
      display: none;
    }

    .streamify-cart {
      &[data-show="true"] {
        opacity: 1;
        pointer-events: all;
        transform: translateY(0%);
      }
    }

    &:not([data-only-video="true"]) {
      .sidebar--right {
        transform: translateX(0);
      }

      .streamify-products > .buttons {
        display: none;
      }

      .streamify-products--no-products {
        height: calc(100% - 30px);
      }
      .streamify-chat__chat-not-ready {
        justify-content: center;
      }
      .streamify-cart {
        background-color: rgba(0, 0, 0, .5);
        border-radius: 0 0 var(--sf-main-box-border-radius) 0;
        position: fixed;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        bottom: 0;
        top: 0;
        opacity: 0;
        left: 0;
        right: 0;
        padding-bottom: 58px;
        transition: opacity .3s;
        pointer-events: none;
        overflow: hidden;
        z-index: 3;

        @include e('inner') {
          background-color: var(--sf-main-box-background);
          transition: transform .3s;
          transform: translateY(100%);
        }

        & > .buttons {
          display: none;
        }

        &[data-show="true"] {
          pointer-events: all;
          opacity: 1;

          .streamify-cart__inner {
            transform: translateY(0%);
            max-height: 50%;
            overflow: auto;
          }
        }
      }
      .sidebar--right__inner--has-cart {
        position: relative;
        margin-bottom: 58px;
      }

      .sidebar {
        max-width: 500px;
        &[data-show="true"] {
          background-color: var(--sf-main-background);
          display: flex;
        }
        &.sidebar--right {
          border-radius: 0 var(--sf-main-border-radius) var(--sf-main-border-radius) 0;
          right: 0;

          .sidebar__inner {
            flex: 1;
          }
        }

        &.sidebar--left {
          border-radius: var(--sf-main-border-radius) 0 0 var(--sf-main-border-radius);
          left: 0;

          &[data-show="true"] {
            padding: 8px 16px 16px;

            .sidebar--left__buttons {
              display: none;
            }
            .streamify-chat {
              .streamify-chat-bottom {
                display: block;
              }
            }

            .streamify-chat {
              flex: 1;
              height: calc(100% - 52px);
              &:not(.streamify-chat--not-ready) {
                background-color: var(--sf-main-box-background);
                box-shadow: var(--sf-main-box-box-shadow);
              }
              border-radius: var(--sf-main-box-border-radius);
              padding: 0;

              .streamify-chat-messages {
                flex: 1;
                max-height: none;
                padding: 16px;

                &.streamify-chat-messages--mobile-extra {
                  mask-image: none;
                }
              }

              .streamify-chat__send-message__button__close {
                display: none !important;
              }

              .streamify-chat__send-message__button__send {
                display: block;
              }

              .streamify-chat__send-message__button:not(.streamify-chat__send-message__button--writing) {
                // display: none;
              }

              .streamify-chat-nickname > .set-first-nickname {
                padding: 0;
                .button {
                  height: 52px;
                  padding: 0 16px;
                }
              }

              .set-nickname__input {
                border-radius: 0;
              }

              .streamify-chat-bottom--morph {
                border-radius: 0 0 var(--sf-main-box-border-radius) var(--sf-main-box-border-radius);
              }

              .streamify-chat-message__user:after {
                content: ":";
              }

              .streamify-chat__send-message__input {
                padding-right: 120px;
              }

              .streamify-chat__send-message__button {
                right: 50px;
              }

              .streamify-chat__send-message > .buttons {
                right: 48px;
              }

              .streamify-chat-bottom-outer {
                margin-top: 0;
              }

              .streamify-chat-bottom-outer--changingNickname {
                .hadiraja {
                  top: 50%;
                  transform: translateY(-50%);
                }
              }

              .hadiraja {
                display: flex;
                position: absolute;
                right: 8px;
                top: 7px;
                margin: 0;
                width: 34px;
                min-width: 34px;
                height: 34px;
                min-height: 34px;
                margin-left: 0;

                .icon-button {
                  font-size: 16px;
                  height: 34px;
                  width: 34px;

                  &.animate {
                    &:active {
                      font-size: 22px !important
                    }
                  }
                }
              }

              .streamify-chat-bottom {
                max-width: 100% !important;
                max-height: 100%;
                height: auto;
                // border-radius: 0;
                width: 100%;
                transition: none;

                .streamify-chat-bottom__toggle {
                  display: none;
                }

                & > *:not(.streamify-chat-bottom__toggle) {
                  display: flex;
                }
              }
            }
          }
        }
      }

      .sidebar__title {
        display: block;
      }
    }
  }

  &[data-only-video="true"] {
    .sidebar--left {
      transition: height .5s;

      .streamify-chat {
        padding: 0 16px 12px;
        height: calc(55% - 84px);
        width: calc(100% - 60px);
      }

      .streamify-chat-messages {
        order: 0;
      }

      .streamify-chat-bottom-outer {
        order: 2;
      }

      .streamify-chat-bottom {
        box-shadow: none;
        border-radius: unset;
        width: auto;

        .streamify-chat-nickname {
          .set-nickname {
            width: auto;
            form {
              width: auto;
              .set-nickname__input {
                width: auto;
              }
            }
          }
          & > .set-first-nickname {
            padding: 0;
            .button {
              height: 52px;
              padding: 0 16px;
              border-radius: 26px;
              width: auto;
            }
          }
        }

        &.streamify-chat-bottom--morph {
          border-radius: 12px;
        }
      }
    }

    .streamify-cart {
      &[data-show="true"] {
        display: flex;
      }
    }

    .streamify-cart--show {
      overflow: auto;
    }

    &[data-videocontrols="true"] {
      .sidebar--left {
        height: calc(100% - 48px);
      }

      .streamify-player > .buttons {
        bottom: 48px;
      }
    }
  }

  .hadiraja {
    margin-left: 5px;
  }

  .sidebar--left {
    display: flex;
    width: 100%;
    max-width: 100%;

    &:not([data-show="true"]) {
      .streamify-chat-messages {
        &::-webkit-scrollbar {
          display: none;
        }

        -ms-overflow-style: none;
        scrollbar-width: none;
      }
    }
  }

  .sidebar--left__buttons {
    flex-direction: row;
    padding: 0;
    position: absolute;
    right: 8px;
    bottom: 12px;
  }

  .streamify-chat {
    justify-content: flex-end;
    margin-top: auto;
    padding: 0 16px 6px;
    height: calc(100% - 84px);

    .streamify-chat-bottom-outer {
      margin-top: 5px;
      position: relative;
    }

    .streamify-chat-bottom {
      pointer-events: all;
      max-width: 350px;
    }

    .streamify-chat-messages {
      flex: none;
      display: flex;
      flex-direction: column;
      max-height: 400px;
      pointer-events: all;

      .streamify-chat-messages__inner {
        margin-top: auto;
      }

      &.streamify-chat-messages--mobile-extra {
        flex: 1;
        mask-image: linear-gradient(to bottom, transparent 0%, black 20%)
      }

      div[mode="out-in"] {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
      }
    }
  }

  .sidebar--right {
    .sidebar--right__buttons {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      padding: 20px 20px;
      pointer-events: none;
      z-index: 2;
      & > * {
        &:not(.closeModal):not(:first-child) {
          margin-left: 5px;
        }
      }
    }
  }

  &[data-only-video="true"] {
    .streamify-player {
      @media (min-width: 768px) {
        border-radius: var(--sf-main-border-radius);
        transform: translate3d(0, 0, 0);
      }
    }

    .sidebar--right__buttons {
      display: flex;
    }

    .streamify-player__buttons {
      right: 12px;
      bottom: 12px;
      flex-direction: column;

      & > * {
        &:not(:first-child) {
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }

    .sidebar--right {
      background-color: rgba(0, 0, 0, .5);
      height: 100%;
      width: 100%;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
      pointer-events: none;
      max-width: none;
      bottom: 0;
      top: 0;
      opacity: 0;
      z-index: 11;

      @media (min-width: 768px) {
        border-radius: var(--sf-main-border-radius);
      }

      .sidebar__inner {
        background-color: var(--sf-main-background);
        display: flex;
        flex-direction: column;
        max-height: 90%;
        overflow: hidden;
        padding: 0;
        width: 100%;
        transform: translateY(100%);
        transition: transform .3s;
        // max-width: 500px;
      }

      &[data-show="true"] {
        opacity: 1;
        pointer-events: all;
        .sidebar__inner {
          transform: translateY(0%);
        }
      }

      .cart-button {
        display: none;
      }

      .streamify-products {
        overflow: auto;
        box-shadow: none;
        padding: 0;
        -ms-overflow-style: none;
        scrollbar-width: none;

        & > .buttons {
          padding: 12px 0;
          display: flex;
          & > * {
            height: 32px;
            width: 32px;
            font-size: 12px !important;
            margin-left: 8px;

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-left: auto;
            }
          }
        }

        &[data-product-selected="true"] {
          & > .buttons {
            display: none;
          }
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &:not(&[data-product-selected="true"]) {
        .sidebar__inner {
          padding: 10px 0 0;
        }

        .streamify-products {
          padding: 0 16px 16px;
        }
      }

      &[data-cart-open="true"] {

        .streamify-cart {
          flex-direction: column;

          & > .buttons {
            display: flex;
          }
        }

        .sidebar__inner {
          padding: 72px 0 0;
        }

        .streamify-products {
          display: none;
        }

        .streamify-cart__inner {
          padding: 0 16px 16px;

          .sidebar__title {
            display: block;
            position: absolute;
            top: 30px;
            pointer-events: none;
          }
        }
      }

      &[data-product-selected="true"] {
        .closeModal {
          display: none;
        }
        // .streamify-product-item__item-info__images__image {
        .product .data .images .image {
          border-radius: 0;
        }

        // .streamify-product-item:not(.streamify-product-item--selected) {
        .product:not(.product--selected) {
          display: none;
        }

        // .streamify-product-item.streamify-product-item--selected {
        .product.product--selected {
          border-radius: 0;
          margin-bottom: 0;
        }

        .icon-button.fixed-small{
          // &:not(.streamify-product-item__close) {
          &:not(.product-close) {
            display: none;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/styles/Mixins';

.cart-button {
  align-items: center;
  background-color: #26CB6B;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.10), inset 0 -2px 0 0 #09B450;
  border-radius: 0 0 var(--sf-main-border-radius) 0;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  transition: all .3s;
  height: 58px;
  user-select: none;

  &:hover:not(.cart-button--active) {
    cursor: pointer;
    background-color: darken(#26CB6B, 2%);
  }

  @include e('icon') {
    color: white;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  @include e('inner') {
    display: flex;
    align-items: center;
    justify-content: center;
    @include e('text') {
      font-weight: 500;
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 2px;
      text-shadow: 0 1px 1px #09B450;
      text-transform: uppercase;
      transition: all .3s;
    }

    @include e('num-items') {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;
      box-shadow: 0 1px 1px 0 #09B450;
      font-family: AvenirNext-DemiBold;
      font-size: 12px;
      color: #26CB6B;
      margin-left: 5px;
      letter-spacing: 0;
      text-align: center;
      border-radius: 100%;
      width: 22px;
      height: 22px;
    }
  }

  @include m('active') {
    background-color: #FFFFFF;
    box-shadow: 0 -1px 0 0 #E5E5E5;
    border-radius: 0 0 var(--sf-main-box-border-radius) 0;
    font-size: 16px;

    .cart-button__inner__text {
      color: #545454;
      text-shadow: none;
    }

    .cart-button__icon {
      display: none;
    }

    .cart-button__inner__num-items {
      display: none;
    }

    &:hover {
      background-color: darken(#FFFFFF, 5%);
      cursor: pointer;
    }
  }
}
</style>
