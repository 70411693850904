<template>
<div :class="`streamify-chat-messages ${mobileExtra ? 'streamify-chat-messages--mobile-extra' : ''} streamify-scrollbars`" ref="messages">
  <div class="streamify-chat-messages__inner" ref="messagesList">
    <div :class="`streamify-chat-message ${message.type === 'reply' ? 'streamify-chat-message--reply' : ''} ${message.type === 'welcome' ? 'streamify-chat-message--welcome' : ''} ${message.moderator ? 'streamify-chat-message--moderator' : ''}`" v-for="(message, index) in messages" :key="message.message_id || `cm-${index}`">
      <template v-if="message.type === 'message' || message.type === 'reply'">
        <div class="streamify-chat-message__reply-to" v-if="message.type === 'reply'">In reply to @{{ message.reply_to.name.substring(0, 20) }}</div>
        <div class="streamify-chat-message__inner">
          <div class="streamify-chat-message__user">{{ message.name.substring(0, 20) }}</div>
          <template v-if="message.moderator">
            <span v-for="(word, index) in message.message.split(' ')" :key="index" v-html="formatMessage(word, message.moderator)"></span>
          </template>
          <template v-else>
            <span v-for="(word, index) in message.message.split(' ')" :key="index">{{ word }}</span>
          </template>
        </div>
        <div class="streamify-chat-message__product" v-for="(product, index) in message.products" :key="`${message.message_id}-product-${index}`">
          <a :href="product.url" target="_blank">
            <img class="streamify-chat-message__product__image" :src="product.image" v-if="product.image" />
            <div>
              <h2 class="streamify-chat-message__product__title">{{ product.title }}</h2>
              <p class="streamify-chat-message__product__price" v-if="product.price && product.currency">{{ product.price}} {{ product.currency.toUpperCase() }}</p>
            </div>
          </a>
        </div>
      </template>
      <template v-if="message.type === 'welcome'">
        <span v-for="(word, index) in message.message.split(' ')" :key="index">{{ word }}</span>
      </template>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    chattable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    showChatInput() {
      return this.$store.getters['Chat/showChatInput'];
    },
    /**
     * Return boolean if the controls of the video are showing or not
     * @returns {Boolean}
     */
    videoControlsActive() {
      return this.$store.getters['Player/videoControlsActive'](this.id);
    },
    /**
     * Return broadcast that matches ID
     * @returns {Object}
     */
    item() {
      return this.$store.getters['Broadcast/get'](this.id);
    },
    /**
     * Return the current time from VideoPlayer
     * @returns {Number}
     */
    videoTime() {
      return this.$store.getters['Player/videoCurrentTime'](this.id);
    },
    /**
     * Return a boolean if its a replay or not..
     * @returns {Boolean}
     */
    isReplay() {
      const { item: { data: { life_cycle_status } } } = this;
      return life_cycle_status === 'complete';
    },
    /**
     * Get and return the latest messages
     * @returns {Array}
     */
    messages() {
      const {
        isReplay,
        chattable,
      } = this;

      const chatMessages = this.$store.getters['Chat/messages'](this.id);
      if (isReplay && !chattable) {
        const {
          videoTime,
          item: {
            data: {
              actual_start_time,
            },
          },
        } = this;
        const currentTime = new Date(actual_start_time);
        currentTime.setSeconds(currentTime.getSeconds() + videoTime);
        const messages = chatMessages.filter((message) => new Date(message.timestamp) <= currentTime);
        return messages.slice(Math.max(messages.length - 200, 0));
      }

      return chatMessages;
    },
  },
  methods: {
    /**
     * Scroll down event
     */
    scrollDown() {
      if (!this.$refs.messagesList || !this.$refs.messages) {
        return;
      }

      if (this.$refs.messagesList.offsetHeight > 300) {
        this.mobileExtra = true;
      }
      clearTimeout(this.preventScrollLockTimer);

      this.preventScrollLock = true;

      if (this.scrollLock) {
        return;
      }

      this.$nextTick(() => {
        this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight;

        this.preventScrollLockTimer = setTimeout(() => {
          this.preventScrollLock = false;
        }, 50);
      });
    },
    orientationScroll() {
      if (!this.scrollLock) {
        setTimeout(() => {
          this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight;
        }, 200);
      }
    },
    heightCheck() {
    },
    /**
     * User scroll event
     */
    scrollEvent() {
      if (this.preventScrollLock) {
        this.scrollLock = false;
        return;
      }

      if (this.$refs.messages.offsetHeight + this.$refs.messages.scrollTop >= this.$refs.messages.scrollHeight) {
        this.scrollLock = false;
        return;
      }
      this.scrollLock = true;
    },
    /**
     * Formats messages that has links etc
     * @param {String} message
     * @returns {String}
     */
    formatMessage(message, isModerator) {
      if (!message) {
        return null;
      }
      if (!isModerator) {
        return message;
      }

      return message.replace(/(((https?:\/\/)|(www\.))[^\s]+)/g, (url) => {
        let hyperlink = url;
        // eslint-disable-next-line
        if (!hyperlink.match('^https?:\/\/')) {
          hyperlink = `http://${hyperlink}`;
        }
        return `<a href="${hyperlink}" target="_blank" rel="noopened noreferrer">${url}</a>`;
      });
    },
  },
  watch: {
    videoControlsActive() {
      setTimeout(() => {
        this.scrollDown();
      }, 500);
    },
    showChatInput() {
      this.scrollDown();
    },
  },
  data() {
    return {
      loadedReplay: false,
      isReallyReplay: false,
      scrollLock: false,
      preventScrollLock: false,
      preventScrollLockTimer: null,
      mobileExtra: false,
    };
  },
  mounted() {
    new ResizeObserver(() => this.scrollDown()).observe(this.$refs.messagesList);
    new ResizeObserver(() => this.heightCheck()).observe(this.$refs.messages);
    this.$refs.messages.addEventListener('scroll', this.scrollEvent);
    window.addEventListener('orientationchange', this.orientationScroll);
  },
  beforeDestroy() {
    this.$refs.messages.removeEventListener('scroll', this.scrollEvent);
    window.removeEventListener('orientationchange', this.orientationScroll);
  },
};
</script>

<style lang="scss">

@import '@/assets/styles/Mixins';

.streamify-chat-messages {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  @include e('inner') {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.streamify-chat-message {
  color: var(--sf-chat-text-color);
  font-size: 14px;
  line-height: 18px;
  width: 100%;

  @include e('inner') {
    display: flex;
    flex-wrap: wrap;
  }

  @include e('text') {
    display: inline-block;
    margin-left: 5px;
    a {
      color: inherit;
    }
  }

  @include e('user') {
    color: var(--sf-chat-user-color);
    display: inline-block;
    font-weight: 600;
  }

  @include e('product') {
    background: #FFFFFF;
    box-shadow: 0 0 1px 0 rgba(0,0,0,0.30), 0 2px 6px 0 rgba(0,0,0,0.15);
    display: block;
    margin-top: 2px;

    a {
      display: flex;
      color: inherit;
      text-decoration: none;

      & > div {
        padding: 12px;
      }
    }

    @include e('image') {
      max-width: 80px;
      object-fit: cover;
    }

    @include e('title') {
      font-size: 18px;
      line-height: 22px;
      font-weight: 500;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
    }

    @include e('price') {
      font-size: 16px;
      line-height: 24px;
      color: var(--sf-product-price-color);
      letter-spacing: var(--sf-product-letter-spacing);
      font-weight: var(--sf-product-weight);
      font-family: var(--sf-product-font-family);
      text-transform: uppercase;
    }

    @include e('description') {
      font-size: 14px;
      padding: 5px;
    }
  }

  span {
    word-break: break-all;

    a {
      color: inherit;
    }

    &:after {
      content: "\00a0";
    }

    &:first-of-type {
      &:before {
        content: "\00a0";
      }
    }
  }

  @include e('reply-to') {
    background: var(--sf-chat-reply-to-background);
    border-radius: 4px;
    display: inline-block;
    width: auto;
    color: var(--sf-chat-reply-to-color);
    padding: 0px 4px;
    font-size: 12px;
    margin-left: -2px;
    margin-bottom: 2px;
    font-weight: 500;
    font-style: italic;
  }

  @include m('moderator') {
    color: var(--sf-chat-text-color);

    .streamify-chat-message__user {
      background-color: var(--sf-chat-moderator-background);
      color: var(--sf-chat-moderator-color);
      border-radius: 4px;
      padding: 0px 4px;
      margin-left: -2px;
    }
  }

  @include m('welcome') {
    background-color: var(--sf-main-background);
    color: var(--sf-title-color);
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
  }

  &:not(:last-child) {
    margin-bottom: 5px;
  }
}
</style>
