import { get } from '../utils';

const Shopify = {
  /**
   * Add item to Shopify cart
   * @param {Number} id
   * @returns {Object}
   */
  addToCart: async (id) => {
    const response = await get('/cart/add.js', {
      method: 'POST',
      body: JSON.stringify({
        items: [
          {
            id,
            quantity: 1,
          },
        ],
      }),
    });

    return response;
  },
  updateQuantity: async (id, quantity) => {
    const response = await get('/cart/change.js', {
      method: 'POST',
      body: JSON.stringify({
        id: `${id}`,
        quantity,
      }),
    });

    return response;
  },
  /**
   * Get product from Shopify by loveHandle
   * @param {String} loveHandle
   * @returns {Object}
   */
  getProduct: async (loveHandle) => {
    const response = await get(`/products/${loveHandle}.js`);
    return response;
  },
  /**
   * Refresh Shopify Cart
   * @returns {Object}
   */
  refreshCart: async () => {
    const response = await get('/cart.js');

    return response;
  },
};

// eslint-disable-next-line
export { Shopify }