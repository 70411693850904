<template>
  <div class="landscape" :data-video-controls="videoControls" :data-show-products-list="showProductsList || selectedProduct !== null" :data-show-cart="showCart">
    <IconButton icon="miniplayer" class="minify default medium-content fixed-small" :data-show="videoControls" @click.native.stop="$emit('minify', true)" />
    <IconButton icon="close-x" class="default close fixed-small active" @click.native.stop="$emit('close', true)" />
    <div class="inner">
      <Video
        ref="videoplayer"
        :id="id"
        :options="videoData"
        :persistent="persistent"
        v-if="videoData"
      />
      <Chat :id="id" v-if="videoData" ref="chat" />
      <Products :id="id" ref="products" @close="toggleProductsList()" />
      <Cart :id="id" ref="cart" />
    </div>
  </div>
</template>

<script>

import Cart from '@/components/Products/Cart.vue';
import Chat from '@/components/Chat/Main.vue';
import Products from '@/components/Products/List.vue';
import Video from '@/components/Player/Video2.vue';
import IconButton from '@/components/Buttons/Icon.vue';

import { iOS } from '@/utils';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Cart,
    Chat,
    IconButton,
    Products,
    Video,
  },
  computed: {
    showProductsList() {
      return this.$store.getters['Products/showProductsList'];
    },
    /**
     * Return boolean if the controls of the video are showing or not
     * @returns {Boolean}
     */
    videoControls() {
      if (!this.broadcast.data) {
        return null;
      }
      if (['live', 'liveStopping', 'complete'].indexOf(this.broadcast.data.life_cycle_status) < 0) {
        return null;
      }
      return this.$store.getters['Player/videoControlsActive'](this.id);
    },
    /**
     * Return the current selected product
     * @returns {?Object}
     */
    selectedProduct() {
      return this.$store.getters['Products/selectedProduct'];
    },
    /**
     * Return the broadcast with ID
     * @returns {?Object}
     */
    broadcast() {
      return this.$store.getters['Broadcast/get'](this.id);
    },
    /**
     * Build up some data for the videoplayer
     * @returns {?Object}
     */
    videoData() {
      const {
        broadcast: {
          loading,
          error,
          data,
        },
      } = this;

      if (loading || error) {
        return null;
      }

      const {
        fqdn,
        resources,
      } = data;

      const [formatP] = resources.filter((resource) => Object.keys(resource)[0].search(/^video.*p$/) >= 0 && Object.keys(resource)[0].replace(/\D/g, '') <= 720).sort((a, b) => Object.keys(b)[0].replace(/\D/g, '') - Object.keys(a)[0].replace(/\D/g, ''));
      const [hls] = resources.filter((resource) => (Object.keys(resource)[0] === 'hls' ? resource : null));
      const [formatunp] = resources.filter((resource) => Object.keys(resource)[0].search(/^video.*[^p]$/) >= 0 && Object.keys(resource)[0].replace(/\D/g, '') <= 720).sort((a, b) => Object.keys(b)[0].replace(/\D/g, '') - Object.keys(a)[0].replace(/\D/g, ''));
      const source = { src: '', type: '' };

      if (formatP) {
        source.src = Object.values(formatP)[0].substring(0, 4) === 'http' ? Object.values(formatP)[0] : `https://${fqdn}/resources/${Object.values(formatP)[0]}`;
        source.type = 'video/mp4';
      } else if (hls) {
        source.src = hls.hls.substring(0, 4) === 'http' ? hls.hls.replace('/live/', '/resources/') : `https://${fqdn}${hls.hls.replace('/live/', '/resources/')}`;
        source.type = 'application/x-mpegURL';
      } else if (formatunp) {
        source.src = Object.values(formatunp)[0].substring(0, 4) === 'http' ? Object.values(formatunp)[0] : `https://${fqdn}/resources/${Object.values(formatunp)[0]}`;
        source.type = 'video/mp4';
      }

      return {
        autoplay: true,
        controls: true,
        fluid: true,
        liveui: true,
        controlBar: {
          fullscreenToggle: false,
        },
        sources: [source],
      };
    },
    /**
     * Return boolean if the cart is showing
     * @returns {Boolean}
     */
    showCart() {
      return this.$store.getters['Cart/show'];
    },
  },
  methods: {
    /**
     * Do stuff when orientationchange happens
     */
    orientationChange() {
      document.documentElement.style.height = 'initial';
      setTimeout(() => {
        if (window.innerHeight < window.innerWidth) {
          document.documentElement.style.height = '200%';
          setTimeout(() => {
            window.scrollTo(0, 1000);
          }, 500);
        } else {
          window.scrollTo(0, 1000);
        }
      }, 500);
    },
    toggleCart(bool) {
      this.resize();

      this.$store.dispatch('Cart/toggleCart', bool);
    },
    /**
     * Toggle productslist
     * @param {Boolean} bool
     */
    toggleProductsList(bool) {
      const {
        broadcast: {
          data: {
            fqdn,
          },
        },
      } = this;

      this.resize();

      this.$store.dispatch('Products/toggleProductsList', bool);
      if (!bool) {
        this.$store.dispatch('Products/selectProduct', { product: null, fqdn, type: 'good' });
      }
      this.$store.dispatch('Cart/toggleCart', false);
    },
    resize() {
      let theHeight = 0;
      try {
        const { height } = this.$refs.videoplayer.$el.querySelector('.streamify-player__player').getBoundingClientRect();
        theHeight = height;
      } catch (e) { /* Do nothing */ }
      try {
        const { height } = this.$refs.videoplayer.$el.querySelector('.streamify-player__countdown').getBoundingClientRect();
        theHeight = height;
      } catch (e) { /* Do nothing */ }

      const { height } = this.$el.getBoundingClientRect();

      try {
        this.$refs.products.$el.style.height = `${Math.ceil(height - theHeight)}px`;
        this.$refs.cart.$el.style.height = `${Math.ceil(height - theHeight)}px`;
      } catch (e) { /* Do nothing */ }
    },
  },
  watch: {
    showCart() {
      this.$nextTick(() => {
        this.resize();
      });
    },
    showProductsList() {
      this.$nextTick(() => {
        this.resize();
      });
    },
  },
  mounted() {
    new ResizeObserver(() => this.resize()).observe(this.$el);
    this.resize();

    if (iOS()) {
      window.addEventListener('orientationchange', this.orientationChange);
      this.orientationChange();
    }
  },
  beforeDestroy() {
    if (iOS()) {
      window.removeEventListener('orientationchange', this.orientationChange);
    }
  },
};
</script>

<style lang="scss">
.liveshopping-modal {
  .landscape {
    position: relative;

    .close {
      display: none;
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 12;
    }

    .minify {
      position: absolute;
      z-index: 12;
      top: -20px;
      right: -20px;
      opacity: 0;
      pointer-events: none;
      transition: opacity .3s;

      &[data-show="true"] {
        opacity: 1;
        pointer-events: all;
      }
    }

    & > .inner {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
      overflow: hidden;
      position: relative;
      border-radius: var(--sf-main-border-radius);
      transform: translate3d(0, 0, 0);
    }

    .streamify-chat-message {
      .streamify-chat-message__product {
        border-radius: 12px;
        display: inline-block;

        .streamify-chat-message__product__image {
          border-radius: 12px 0 0 12px;
        }
        .streamify-chat-message__product__title {
          word-break: break-all;
        }
      }
    }

    @media (orientation: portrait) {
      display: flex;
      flex-direction: column;

      .streamify-products, .streamify-cart {
        background-color: var(--sf-main-background);
        height: 100%;
        width: 100%;
        z-index: 3;
        display: flex;
        flex-direction: column;
        pointer-events: none;
        max-width: none;
        max-height: 90%;
        bottom: 0;
        z-index: 10;
        transform: translateY(100%);
        position: absolute;
        transition: transform .3s;
        overflow: hidden;

        &[data-show="true"] {
          transform: translateY(0%);
          pointer-events: all;
        }
      }

      .streamify-products {
        .streamify-products__list {
          overflow: auto;
          padding: 15px;
          scrollbar-width: none;
        }

        & > .buttons {
          display: flex;
          padding: 15px;

          & > * {
            &:not(:last-child) {
              margin-right: 10px;
            }

            &:last-child {
              margin-left: auto;
            }
          }
        }

        &[data-product-selected="true"] {
          .streamify-products__list {
            padding: 0;
            background-color: var(--sf-main-box-background);
            flex: 1;
          }

          .product:not(.product--selected) {
            display: none;
          }

          .product.product--selected {
            border-radius: 0;
            box-shadow: none;
            margin-bottom: 0;

            .product-close {
              position: fixed;
            }
          }

          & > .buttons {
            display: none;
          }
        }
      }

      .streamify-player {
        height: auto;

        .streamify-player__countdown {
          height: 56.25vw;
        }

        .streamify-player__player.vjs-fluid {
          height: auto;
        }

        .product-previews {
          position: relative;
          background-color: white;
          bottom: 0 !important;
          top: 0;
          right: 0 !important;

          &:not([data-no-products="true"]) {
            padding: 12px;
          }

          .product-item-preview {
            width: 76px;

            .product-item-preview__image {
              width: 56px;
              height: 56px;
            }
          }

          &[data-hide="true"] {
            // display: none;
          }
        }

        video {
          height: auto;
        }

        .buttons {
          bottom: 58px;
          right: 10px;
          flex-direction: column;

          .hadiraja {
            order: 1;
          }

          .chat-group {
            display: none;
          }

          .chat {
            display: none;
          }

          &.buttons--not-live {
            bottom: 20px;
          }

          & > * {
            margin-left: 0;
            margin-bottom: 8px;
          }
        }
      }

      .streamify-chat {
        display: flex;
        flex: 1;
        overflow: auto;
        background-color: var(--sf-main-background);
        width: 100%;

        .streamify-chat-pinned-message {
          margin: 0;
        }

        .streamify-chat-messages {
          padding: 12px;
        }

        .streamify-chat-bottom {
          box-shadow: 0 0px 10px 0 rgb(0 0 0 / 20%);
          border-radius: 0;

          .streamify-chat__send-message__input {
            border-radius: 0;
          }
        }
        .hadiraja {
          position: absolute;
          right: 10px;
          bottom: 85px;
        }

        .streamify-chat__send-message__button {
          .streamify-chat__send-message__button__close {
            display: none;
          }
          .streamify-chat__send-message__button__send {
            display: block;
          }
        }
      }

      @media (max-width: 599px) {
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        width: 100%;

        .inner {
          border-radius: 0;
        }

        .minify {
          right: 16px;
          top: 16px;
        }
      }

      @media (min-width: 600px) {
        height: 90vh;
        max-height: 90vh;
        width: 90vw;
        max-width: 90vw;
      }
    }

    @media (orientation: landscape) {
      height: calc(56.25vw - 56.25px);
      width: calc(100vw - 100px);
      max-width: calc((177.78vh - 100px) - (var(--sf-modal-left) + var(--sf-modal-right)));
      max-height: calc((100vh - 56.25px) - (var(--sf-modal-top) + var(--sf-modal-bottom)));

      .streamify-products, .streamify-cart {
        background-color: rgba(255, 255, 255, 0.2);
        height: 100% !important;
        width: 100%;
        z-index: 3;
        display: flex;
        flex-direction: column;
        pointer-events: none;
        max-width: 400px;
        bottom: 0;
        right: 0;
        z-index: 9;
        transform: translateX(100%);
        position: absolute;
        transition: transform .3s;
        overflow: hidden;
        // padding: 16px;

        &[data-show="true"] {
          transform: translateX(0%);
          pointer-events: all;
        }

        & > .buttons {
          display: none;
        }
      }

      .streamify-cart__inner {
        color: var(--sf-main-box-color);
        background: var(--sf-main-box-background);
        padding: 0;
      }

      .streamify-cart {
        padding: 15px max(15px, env(safe-area-inset-right)) 70px 15px;
      }

      .streamify-products {
        .streamify-products__list {
          overflow: auto;
          padding: 15px max(15px, env(safe-area-inset-right)) 70px 15px;
          scrollbar-width: none;

          & > .product {
            &:last-child {
              margin-bottom: 40px;
            }

            .list {
              .image {
                min-height: 50px;
                min-width: 50px;
                width: 50px;
              }

              .info {
                h3 {
                  font-size: 14px;
                  line-height: 18px;
                }

                p {
                  font-size: 14px;
                  line-height: 18px;
                }
              }
            }

            .data {
              .image {
                padding-top: 70%;
              }
            }
          }
        }
      }

      .streamify-chat {
        z-index: 13;
        position: absolute;
        left: max(32px, env(safe-area-inset-right));
        bottom: max(32px, env(safe-area-inset-bottom));
        height: min(350px, max(250px, 50vh));
        width: 40%;
        transition: bottom .35s;
        mask-image: linear-gradient(to bottom, transparent 0px, black 35px);
        justify-content: flex-end;
        display: none;

        .set-first-nickname {
          padding: 0;

          .button {
            height: 52px;
            border-radius: 26px;
            width: auto;
            padding: 0 16px;
          }
        }

        .set-nickname {
          form {
            display: inline-block;
          }
        }

        &[data-show="true"] {
          display: flex;
        }

        .streamify-chat-messages {
          flex: unset;
          scrollbar-width: none;
          -ms-overflow-style: none;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        .streamify-chat-pinned-message {
          order: 2;
          margin: 0;
          border-radius: 12px;
          font-size: 12px;
          padding: 1px 6px;
          line-height: 14px;

          i {
            font-size: 16px;
          }
        }

        .streamify-chat-bottom-outer {
          display: none;
          order: 3;
          margin-top: 2px;

          &[data-show="true"] {
            display: flex;
          }
        }

        .streamify-chat-message {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          margin-bottom: 2px;

          .streamify-chat-message__inner {
            background-color: rgba(var(--sf-chat-background), .6);
            border-radius: 12px;
            color: var(--sf-chat-text-color);
            padding: 1px 6px;
            font-size: 12px;
          }

          &.streamify-chat-message--moderator {
            .streamify-chat-message__inner {
              background-color: rgba(var(--sf-chat-moderator-background), .6);
              color: var(--sf-chat-moderator-color);
            }
          }
        }

        .hadiraja {
          margin-left: 10px;
        }

        @media (max-width: 1023px) {
          .streamify-chat-bottom-outer {
            margin-top: 5px;
          }

          .streamify-chat-bottom {
            border-radius: 26px;
          }

          .streamify-chat-nickname-change {
            display: none;
          }
        }

        @media (min-width: 1024px) {
          width: 30%;
        }
      }

      .streamify-chat-bottom {
        width: auto;
      }

      .streamify-chat__send-message__input {
        border-radius: 26px;
        padding-left: 18px;
        width: auto;
        max-width: 100%;
      }

      &[data-video-controls="true"] {
        .streamify-player {
          .product-previews {
            bottom: 120px;
          }
        }

        .streamify-chat {
          bottom: 50px;
        }

        .streamify-player .buttons {
          bottom: 50px;
        }
      }

      &[data-show-products-list="true"] {
        .streamify-player {
          & > .buttons {
            .chat-group, .hadiraja {
              display: none;
            }
          }
        }
      }

      &[data-show-cart="true"] {
        .streamify-player {
          & > .buttons {
            .chat-group, .hadiraja {
              display: none;
            }
          }
        }
      }

      @media (max-height: 600px) {
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        width: 100%;

        .inner {
          border-radius: 0;
        }

        .minify {
          right: 16px;
          top: 16px;
        }

        .streamify-chat {
          height: min(155px, max(250px, 50vh));
          left: max(20px, env(safe-area-inset-right));
          bottom: max(20px, env(safe-area-inset-bottom));
        }

        .streamify-chat-message {
          .streamify-chat-message__product {
            a > div {
              padding: 6px
            }
            .streamify-chat-message__product__image {
              max-width: 60px;
            }
            .streamify-chat-message__product__title {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }

        .streamify-products, .streamify-cart {
          max-width: 300px;
        }

        .streamify-player {
          .product-item-preview {
            width: 76px;

            .product-item-preview__image {
              width: 56px;
              height: 56px;
            }
          }
          .buttons {
            right: max(20px, env(safe-area-inset-right));
            bottom: max(20px, env(safe-area-inset-bottom));
          }
        }
      }

      .product-previews {
        &[data-hide="true"] {
          display: none;
        }
      }
    }

    .streamify-player {
      .product-previews {
        bottom: 100px;
        right: 32px;
        top: unset;
        left: unset;

        & > div {
          align-items: center;
          display: flex;

          & > * {
            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  &[data-minified="true"] {
    .landscape {
      height: calc(225px * 0.5626);
      max-width: 225px;
      pointer-events: all;

      .minify {
        display: none;
      }

      &:hover {
        cursor: pointer;

        .close {
          display: flex;
        }
      }

      @media (min-width: 600px) {
        height: calc(350px * 0.5626);
        max-width: 350px;

        .streamify-player__countdown {
          height: calc(350px * 0.5626);
        }
      }

      @media (max-height: 500px), (max-width: 767px) {
        height: calc(225px * 0.5626);
        max-width: 225px;

        .streamify-player__countdown {
          height: calc(225px * 0.5626);
        }
      }

      .streamify-player__countdown {
        .countdown {
          transform: scale(.75);
        }
      }

      .streamify-player {
        border-radius: 18px;
        pointer-events: none;

        .product-previews {
          // make them small instead
          display: none;
        }

        & > .buttons {
          display: none;
        }

        .vjs-control-bar {
          opacity: 0 !important;
        }
      }

      .streamify-chat, .streamify-products, .streamify-cart {
        display: none;
      }
    }
  }
}
</style>
