import { get } from '../utils';

const Events = {
  getBacklog: async (fqdn = 'embed', id) => {
    // const response = await get(`https://embed.streamify.io/play/event_stream/${id}?fqdn=${fqdn}`);
    const response = await get(`https://${fqdn}/play/event_stream/${id}`);
    if (!response) {
      throw new Error(`Could not fetch broadcast with id: ${id}`);
    }

    return response.data;
  },
};

// eslint-disable-next-line
export { Events };
